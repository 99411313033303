import {Injectable} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SearchCarForm} from 'top-car-interfaces';
import {Navigate} from '@ngxs/router-plugin';
import {Store} from '@ngxs/store';
import {UKPostcodePattern} from 'top-car-utils';

@Injectable({
  providedIn: 'root'
})
export class CarSearchService {
  private readonly defaultValue: SearchCarForm = {
    video: false,
    onlinePayment: false,
    paymentType: 'Price'
  }

  public formGroup = new FormGroup({
    typeId: new FormControl(),
    paymentType: new FormControl('Price'),
    longitude: new FormControl(),
    latitude: new FormControl(),
    contains: new FormControl(),
    brandId: new FormControl([]),
    modelId: new FormControl([]),
    modelVariantId: new FormControl([]),
    owners: new FormControl(),
    sort: new FormControl('Relevance'),
    engine: new FormGroup({
      min: new FormControl(),
      max: new FormControl()
    }),
    year: new FormGroup({
      min: new FormControl(),
      max: new FormControl()
    }),
    price: new FormGroup({
      min: new FormControl(),
      max: new FormControl()
    }),
    finance: new FormGroup({
      min: new FormControl(),
      max: new FormControl()
    }),
    mileage: new FormGroup({
      min: new FormControl(),
      max: new FormControl()
    }),
    acceleration: new FormGroup({
      min: new FormControl(),
      max: new FormControl()
    }),
    delivery: new FormControl(),
    postcode: new FormControl(null, [Validators.pattern(UKPostcodePattern)]),
    radiusMeters: new FormControl(),
    video: new FormControl(false),
    onlinePayment: new FormControl(false),
    gearBoxId: new FormControl(),
    dealerGuarantee: new FormControl(),
    fuelTypeId: new FormControl([]),
    bodyTypeId: new FormControl([]),
    colourId: new FormControl(),
    manufacturerApproved: new FormControl(),
    transmissionId: new FormControl(),
    dealerType: new FormControl(),
    exchange: new FormControl(),
    insuranceWriteOff: new FormControl(),
    pageSize: new FormControl(20),
    pageNumber: new FormControl(1)
  });
  public stopRedirect = true;

  public scroll = {};
  public opennedChildren = {};

  constructor(
    private store: Store,

  ) {}

  async reset(redirectAfter = false): Promise<void> {
    if (redirectAfter) {
      this.stopRedirect = true;
    } else {
      this.store.dispatch(new Navigate(['/car', 'search', '1']));
    }

    this.formGroup.reset(this.defaultValue);
  }
}
