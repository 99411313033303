import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {Unsubscribable} from 'rxjs/internal/types';
import {CombineSubscriptions, DestroySubscribers} from 'top-car-decorators';
import {ReverseBuyCarSearchService} from '@services/reverse-buy/reverse-buy-car-search.service';
import {NotificationHubState} from 'top-car-ngxs';
import {CarSearchService} from '@services/car/car-search.service';
import {UserType} from 'top-car-interfaces';
import {SearchCars} from 'top-car-ngxs';
import {Navigate} from '@ngxs/router-plugin';
import {UserInfo} from 'top-car-interfaces';
import {NgxAuthService} from 'ngx-auth';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@DestroySubscribers()
export class HeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() type: string;

  @Select(NotificationHubState.totalSavedCars) totalSavedCars$: Observable<number>;
  @Select(NotificationHubState.totalNotifications) totalNotifications$: Observable<number>;

  public readonly isAuthenticated$: Observable<boolean> = this.auth.isAuthenticated$.asObservable();
  public readonly userData$: Observable<UserInfo> = this.auth.userData$.asObservable();
  public readonly domain = environment.API;

  hideMobile = true;

  userTypes = UserType;

  public rightButtonRoute: string;

  @CombineSubscriptions()
  private subscriber: Unsubscribable;

  constructor(
    public carService: CarSearchService,
    public reverseService: ReverseBuyCarSearchService,
    private changeDetector: ChangeDetectorRef,
    private store: Store,
    private auth: NgxAuthService
  ) { }

  ngOnInit(): void {
    if (!this.auth.userData) this.auth.getUserData().subscribe();
  }

  ngOnChanges(): void {}

  openRightButton() {
    this.store.dispatch(new Navigate(this.checkType()))
  }

  ngOnDestroy(): void {}

  logoClicked(): void {
    this.store.dispatch(new SearchCars.Reset(true));
    this.reverseService.reset(true);
  }

  private checkType = () => {
    if (this.type === 'reverse-buy') {
      return ['/reverse-buy', 'car', 'add', 1];
    } else {
      return ['/car', 'sell', 1];
    }
  }
}
