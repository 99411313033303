import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LayoutsEnum} from './layouts/layouts.enum';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'cars/home',
    pathMatch: 'full'
  },
  {
    path: 'cars',
    data: { layout: LayoutsEnum.Main },
    loadChildren: () => import('./layouts/car-filter-layout/car-filter-layout.module').then(m => m.CarFilterLayoutModule),
  },
  {
    path: 'profile',
    data: { layout: LayoutsEnum.Main },
    loadChildren: () => import('./layouts/account-layout/account-layout.module').then(m => m.AccountLayoutModule),
  },
  {
    path: 'vehicle-payment',
    data: { layout: LayoutsEnum.Main },
    loadChildren: () => import('./layouts/vehicle-purchase-layout/vehicle-purchase-layout.module').then(m => m.VehiclePurchaseLayoutModule),
  },
  {
    path: 'reports',
    data: { layout: LayoutsEnum.Main },
    children: [
      {
        path: 'car/generate',
        loadChildren: () => import('./pages/report/car-generate-report-form/car-generate-report-form-page.module').then(m => m.CarGenerateReportFormPageModule)
      },
      {
        path: 'car/generate/:plate/:type',
        loadChildren: () => import('./pages/report/car-test-report/car-test-report-page.module').then(m => m.CarTestReportPageModule)
      },
      {
        path: 'car/sample',
        loadChildren: () => import('./pages/report/car-sample-report/car-sample-report-page.module').then(m => m.CarSampleReportPageModule)
      },
      {
        path: 'car/:id',
        loadChildren: () => import('./pages/report/car-report/car-report-page.module').then(m => m.CarReportPageModule),
      },
    ]
  },
  {
    path: 'seller/dealers',
    data: { layout: LayoutsEnum.Main },
    loadChildren: () => import('./pages/seller/all-dealers/all-dealers-page.module').then(m => m.AllDealersPageModule)
  },
  {
    path: 'seller',
    data: { layout: LayoutsEnum.Main },
    loadChildren: () => import('./layouts/seller-profile-layout/seller-profile-layout.module').then(m => m.SellerProfileLayoutModule),
  },
  {
    path: 'trade-in/car/:id',
    data: { layout: LayoutsEnum.Main },
    loadChildren: () => import('./pages/trade-in/trade-in-request/trade-in-request-page.module').then(m => m.TradeInRequestPageModule)
  },
  {
    path: 'car',
    data: { layout: LayoutsEnum.Main },
    children: [
      {
        path: 'sell',
        loadChildren: () => import('./pages/car/add-advert-page/add-advert-page.module').then(m => m.AddAdvertPageModule),
      },
      {
        path: 'edit/:id',
        loadChildren: () => import('./pages/car/edit-advert-page/edit-advert-page.module').then(m => m.EditAdvertPageModule),
      },
      {
        path: ':id',
        loadChildren: () => import('./pages/car/advert/advert-page.module').then(m => m.AdvertPageModule),
      },
      {
        path: ':brand/:model/:id',
        loadChildren: () => import('./pages/car/advert/advert-page.module').then(m => m.AdvertPageModule),
      },
    ]
  },
  {
    path: 'finance',
    data: { layout: LayoutsEnum.Main },
    children: [
      {
        path: 'car/:id',
        loadChildren: () => import('./pages/finance/car-finance-page/car-finance-page.module').then(m => m.CarFinancePageModule)
      },
      {
        path: 'car/:id/finance-request',
        loadChildren: () => import('./pages/finance/finance-requeste-page/finance-requeste-page.module').then(m => m.FinanceRequestePageModule)
      }
    ]
  },
  {
    path: 'auth',
    loadChildren: () => import('ngx-auth').then(m => m.NgxAuthModule),
    data: { layout: LayoutsEnum.FullScreen },
  },
  {
    path: 'error',
    loadChildren: () => import('top-car-api').then(m => m.TopCarApiModule),
    data: { layout: LayoutsEnum.FullScreen },
  },
  {
    path: 'comming-soon',
    loadChildren: () => import('./pages/comming-soon/comming-soon.module').then(m => m.CommingSoonModule),
    data: { layout: LayoutsEnum.Main },
  },
  {
    path: 'compare',
    loadChildren: () => import('./pages/compare/compare.module').then(m => m.CompareModule),
    data: { layout: LayoutsEnum.Main },
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/privacy/privacy.module').then(m => m.PrivacyModule),
    data: { layout: LayoutsEnum.Main },
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsModule),
    data: { layout: LayoutsEnum.Main },
  },
  {
    path: 'support',
    loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule),
    data: { layout: LayoutsEnum.Main },
  },
  {
    path: 'report',
    data: { layout: LayoutsEnum.Main },
    children: [
      {
        path: 'car/generate',
        loadChildren: () => import('./pages/report/car-generate-report-form/car-generate-report-form-page.module').then(m => m.CarGenerateReportFormPageModule)
      },
      {
        path: 'car/generate/:plate/:type',
        loadChildren: () => import('./pages/report/car-test-report/car-test-report-page.module').then(m => m.CarTestReportPageModule)
      },
      {
        path: 'car/sample',
        loadChildren: () => import('./pages/report/car-sample-report/car-sample-report-page.module').then(m => m.CarSampleReportPageModule)
      },
      {
        path: 'car/:id',
        loadChildren: () => import('./pages/report/car-report/car-report-page.module').then(m => m.CarReportPageModule)
      },
    ]
  },
  {
    path: 'not-found',
    data: { layout: LayoutsEnum.Main },
    loadChildren: () => import('./pages/not-found/not-found.module').then(m => m.NotFoundModule),
  },
  {
    path: '**',
    redirectTo: 'not-found',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'disabled', initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
