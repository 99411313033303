import { Component, OnInit } from '@angular/core';
import {NgxAuthService} from 'ngx-auth';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
  public readonly isAuthenticated$ = this.auth.isAuthenticated$.asObservable();

  constructor(private auth: NgxAuthService) { }

  ngOnInit(): void {
  }

}
