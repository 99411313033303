import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {CommonUtilService} from 'top-car-utils';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ],
})
export class InputComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() label?: string;
  @Input() placeholder: string;
  @Input() suffixIcon?: string;
  @Input() suffixTooltip?: string;
  @Input() suffixColor?: string;

  @ViewChild(FormControlDirective, {static: true})
  formControlDirective: FormControlDirective;
  @Input() type = 'text';
  @Input() formControlName: string;
  @Input() formControl: AbstractControl;
  @Input() readonly = false;
  @Input() disable = false;

  @Output() suffixClicked = new EventEmitter();

  constructor(private controlContainer: ControlContainer, private utils: CommonUtilService) {}

  get control(): FormControl {
    return this.controlContainer.control.get(this.formControlName) as FormControl;
  }

  ngOnInit(): void {
    this.utils.changeFormControlDisabledState(this.disable, this.control);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disable?.previousValue !== undefined && changes.disable.previousValue !== changes.disable?.currentValue) {
      this.utils.changeFormControlDisabledState(changes.disable.currentValue, this.control);
    }
  }

  clearInput(): void {
    this.control.setValue('');
  }

  registerOnTouched(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnTouched(fn);
  }

  registerOnChange(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnChange(fn);
  }

  writeValue(obj: any): void {
    this.formControlDirective.valueAccessor.writeValue(obj);
  }

  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
  }
}
