import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appPostCodeInput]'
})
export class PostCodeInputDirective {

  @Input() enablePostCode = false;

  lastValue: string;

  constructor(public ref: ElementRef) { }

  @HostListener('input', ['$event']) onInput($event: any): void {
    if (this.enablePostCode) {
      // console.log($event);

      let start = $event.target.selectionStart;
      let end = $event.target.selectionEnd;
      const previousHasSpace = $event.target.value.indexOf(' ') >= 0;
      $event.target.value = $event.target.value.replace(' ', '').replace(/^(.*)(\d)/, '$1 $2');
      $event.target.value = $event.target.value.slice(0, 8);
      const currentHasSpace = $event.target.value.indexOf(' ') >= 0;

      if (!previousHasSpace && currentHasSpace && $event.target.value.indexOf(' ') >= end - 1) {
        start++;
        end++;
      }

      if (previousHasSpace && !currentHasSpace && $event.target.value.indexOf(' ') < end - 1) {
        start--;
        end--;
      }

      $event.target.setSelectionRange(start, end);
      $event.preventDefault();

      if (!this.lastValue || (this.lastValue && $event.target.value.length > 0 && this.lastValue !== $event.target.value)) {
        this.lastValue = this.ref.nativeElement.value = $event.target.value;
        // Propagation
        const evt = document.createEvent('HTMLEvents');
        evt.initEvent('input', false, true);
        $event.target.dispatchEvent(evt);
      }
    }
  }
}
