<mat-form-field class="u-width-100 send-message-form" appearance="outline">
  <input autocomplete="off" matInput type="text" [formControl]="control" [placeholder]="placeholder">

  <div matSuffix>
    <button type="submit" mat-icon-button  (click)="suffixClicked.emit(true)">
      <mat-icon>arrow_upward</mat-icon>
    </button>
  </div>

</mat-form-field>
