import {ChangeDetectionStrategy, Component, forwardRef, Input} from '@angular/core';
import {AbstractControl, ControlContainer, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-button-toggle',
  templateUrl: './button-toggle.component.html',
  styleUrls: ['./button-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ButtonToggleComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonToggleComponent implements ControlValueAccessor {
  @Input() options = [];
  @Input()
  formControl: FormControl;
  @Input()
  formControlName: string;

  constructor(private controlContainer: ControlContainer) {}

  get control(): AbstractControl {
    return this.controlContainer.control.get(this.formControlName);
  }

  clearInput(): void {
    this.control.setValue('');
  }

  registerOnTouched(fn: any): void {
  }

  registerOnChange(fn: any): void {
  }

  writeValue(obj: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }
}
