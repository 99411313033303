import {Twilio} from 'top-car-ngxs';
import {Component, Input, OnInit} from '@angular/core';
import {UserInfo} from 'top-car-interfaces';
import {Select, Store} from '@ngxs/store';
import {TwilioState} from 'top-car-ngxs';
import {Observable} from 'rxjs';
import {
  AppointmentSecondaryActions,
  CarSecondaryActions,
  PaymentSecondaryActions,
  TwilioActions,
  UserSecondaryActions
} from 'top-car-interfaces';
import {NgxAuthService} from 'ngx-auth';

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss']
})
export class PhoneNumberComponent implements OnInit {
  @Select(TwilioState.active) phoneNumber$: Observable<string>;
  @Input() user: UserInfo;
  @Input() action: string;
  @Input() userType: 'seller' | 'buyer' | 'branch';
  @Input() targetId: number;
  @Input() invert = false;

  public userData$ = this.auth.userData$.asObservable();

  constructor(
    private store: Store,
    private auth: NgxAuthService
  ) { }

  ngOnInit(): void {
    this.store.dispatch(new Twilio.Get());
  }

  secondaryAction(
    number = 1
  ) {
    switch(this.action) {
      case TwilioActions.CAR_ADVERT:
        return number === 3 ? CarSecondaryActions.BRANCH_PHONE_NUMBER : number === 1 ?  CarSecondaryActions.PHONE_NUMBER : CarSecondaryActions.SECONDARY_PHONE_NUMBER;
      case TwilioActions.USER:
        return number === 3 ? UserSecondaryActions.BRANCH_PHONE_NUMBER : number === 1 ? UserSecondaryActions.PHONE_NUMBER : UserSecondaryActions.SECONDARY_PHONE_NUMBER
      case TwilioActions.PAYMENT_REQUEST:
        if (this.userType === 'buyer') {
          return number === 1 ? PaymentSecondaryActions.BUYER_PHONE_NUMBER : PaymentSecondaryActions.BUYER_SECONDARY_PHONE_NUMBER;
        } else {
          return number === 3 ? PaymentSecondaryActions.BRANCH_PHONE_NUMBER : number === 1 ? PaymentSecondaryActions.SELLER_PHONE_NUMBER : PaymentSecondaryActions.SELLER_SECONDARY_PHONE_NUMBER;
        }
      case TwilioActions.APPOINTMENT:
        if (this.userType === 'buyer') {
          return number === 1 ? AppointmentSecondaryActions.BUYER_PHONE_NUMBER : AppointmentSecondaryActions.BUYER_SECONDARY_PHONE_NUMBER;
        } else {
          return number === 3 ? AppointmentSecondaryActions.BRANCH_PHONE_NUMBER : number === 1 ? AppointmentSecondaryActions.SELLER_PHONE_NUMBER : AppointmentSecondaryActions.SELLER_SECONDARY_PHONE_NUMBER;
        }
    }
  }

}
