import {Wallet} from 'top-car-ngxs';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Store} from '@ngxs/store';
import {PaymentMethod} from 'top-car-interfaces';
import {TopCarApiService} from 'top-car-api';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentMethodComponent implements OnInit {
  @Input() card: PaymentMethod;
  @Input() flipable = false;
  @Input() deletable = false;

  @Output() deletePaymentMethod = new EventEmitter();

  constructor(private api: TopCarApiService, private store: Store) {}

  ngOnInit(): void {
  }

  deleteCard(): void {
    this.store.dispatch(new Wallet.ConfirmRemovingCard(this.card.id));
  }
}
