import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {MainLayoutModule} from './main-layout/main-layout.module';
import {FullScreenLayoutModule} from './full-screen-layout/full-screen-layout.module';

@NgModule({
  imports: [
    CommonModule,
    MainLayoutModule,
    FullScreenLayoutModule,
    RouterModule
  ],
  exports: [
    MainLayoutModule,
    FullScreenLayoutModule,
  ],
  declarations: [],
})
export class LayoutsModule {}
