<div class="extra-page-layout font-secondary">
  <div class="extra-page-layout__top">
    <div class="wrapper">
      <h1 class="font-primary u-text-extra-bold extra-page-layout__top-title">{{title}}</h1>
    </div>
  </div>
  <div class="extra-page-layout__bottom">
    <div class="wrapper">
      <ng-content></ng-content>
    </div>
  </div>
</div>
