<app-modal-wrapper [loading]="loading" [completed]="completed" [dialogRef]="dialogRef" title="Change password">
  <form [formGroup]="formGroup" (ngSubmit)="sendRequest()">
    <app-input-alternative label="Old password" formControlName="oldPassword" type="password"></app-input-alternative>
    <app-input-alternative class="u-margin-top-16 u-block" label="New password" formControlName="newPassword" type="password"></app-input-alternative>
    <lib-password-validator-block class="u-margin-top-16 u-block" [control]="formGroup.controls.newPassword"></lib-password-validator-block>
    <div class="u-text-center modal__submit-container">
      <button mat-flat-button color="primary" [disabled]="formGroup.invalid">Change password</button>
    </div>
  </form>
</app-modal-wrapper>
