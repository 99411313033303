<div class="u-text-right">
  <button mat-icon-button class="modal__close" (click)="close(false)"><mat-icon>close</mat-icon></button>
</div>
<mat-dialog-content class="cookies-modal__content font-secondary">

  <div class="u-text-center u-width-75 u-margin-x-auto">
    <h1 class="u-text-bold modal__title font-secondary">{{data.message}}</h1>
  </div>

  <div class="u-text-center u-flex u-flex-align-center u-flex-justify-center">
    <button mat-flat-button color="primary" class="u-margin-right-24" *ngIf="data.message === 'Additional information required for verification'" (click)="verifyForPayment()">Request link</button>

    <button mat-flat-button color="primary" (click)="close(true)">OK</button>
  </div>
</mat-dialog-content>
