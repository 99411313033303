import {ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output, ViewChild} from '@angular/core';
import {AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MatSlideToggle, MatSlideToggleChange} from '@angular/material/slide-toggle';

@Component({
  selector: 'app-toggler',
  templateUrl: './toggler.component.html',
  styleUrls: ['./toggler.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TogglerComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TogglerComponent implements ControlValueAccessor {
  @ViewChild(MatSlideToggle) matSlideToggle: MatSlideToggle;
  @Input() title: string;
  @Input() formControlName: string;
  @Input() labelPosition: 'after' | 'before' = 'after';
  @Input() deletable = false;
  @Input() fullWidth = false;
  @Output() delete = new EventEmitter();

  get control(): AbstractControl {
    return this.controlContainer.control.get(this.formControlName);
  }

  constructor(private controlContainer: ControlContainer) { }

  selectionChanged(event: MatSlideToggleChange): void {
    // this.selectionChange.emit(new MatSelectChange(this.matSelect, event.value));
    // this.valueChange.emit(event.value);
    this.onChange(event.checked);
    this.onTouched();
  }

  // ControlValueAccessor Implementation
  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    // this.value = value;
  }

  setDisabledState(isDisabled: boolean): void {
    // this.disabled = isDisabled;
  }

}
