import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {StripeCardElementOptions, StripeElementsOptions} from '@stripe/stripe-js';
import {StripeCardComponent, StripeService} from 'ngx-stripe';

@Component({
  selector: 'app-get-card-token',
  templateUrl: './get-card-token.component.html',
  styleUrls: ['./get-card-token.component.scss']
})
export class GetCardTokenComponent implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  @Input() buttonText = 'Add card';

  @Output() tokenResponse = new EventEmitter();

  readonly cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: 'black',
        color: '#31325F',
        fontWeight: '400',
        fontFamily: '"Roboto", Helvetica, sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: 'black',
        },
      },
    },
  };

  readonly elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  readonly formGroup = new FormGroup({
    name: new FormControl(null, [Validators.required]),
  });

  public loading = false;
  public completed = false;
  public error: any;

  constructor(
    private stripeService: StripeService
  ) {}

  ngOnInit(): void {}

  reset(): void {
    this.formGroup.reset({emitEvent: false});
    this.card.element.clear();
  }


  createToken(): void {
    this.loading = true;
    const name = this.formGroup.get('name').value;

    this.stripeService
    .createToken(this.card.element, { name })
    .subscribe((result) => {
      if (result.token) {
        // Use the token
        this.loading = false;
        this.tokenResponse.emit(result.token.id);
      } else if (result.error) {
        // Error creating the token
        console.log(result.error.message);
        this.error = result.error.message;
        this.loading = false;
      }
    });


  }

}
