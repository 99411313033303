import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {CombineSubscriptions, DestroySubscribers} from 'top-car-decorators';
import {fromEvent, Unsubscribable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {debounceTime} from 'rxjs/operators';
import {NgxAuthService} from 'ngx-auth';

@Component({
  selector: 'app-scroll-content',
  templateUrl: './scroll-content.component.html',
  styleUrls: ['./scroll-content.component.scss']
})
@DestroySubscribers()
export class ScrollContentComponent implements OnInit, OnDestroy {
  @ViewChild('iframe', {static: true}) private iframe: ElementRef;
  @ViewChild('scrollContent', {static: true}) private scrollContent: ElementRef;

  @Input() url: string;

  @Output() read = new EventEmitter();

  @CombineSubscriptions() subscriber: Unsubscribable;

  constructor(
    private http: HttpClient,
    private auth: NgxAuthService
  ) { }

  ngOnInit(): void {
    this.http.get(this.url, {
      responseType: 'text'
    }).subscribe((response: string) => {
      const doc =  this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow;
      // Open the doc and add html in it
      doc.open();
      const userData = this.auth.userData;
      doc.write(response.replace(':offerAmount', Number(userData?.offer || 1.5).toString()));
      doc.write(response.replace(':payoutSchedule', Number(userData?.payoutSchedule || 14).toString()));
      doc.close();
      this.onFrameLoad();
    }, error => {
      console.log(error);
    });
  }

  ngOnDestroy() {}

  onFrameLoad(): void {
    this.iframe.nativeElement.style.height = this.iframe.nativeElement.contentWindow.document.documentElement.scrollHeight + 'px';

    this.subscriber = fromEvent(this.scrollContent.nativeElement, 'scroll', {passive: true}).pipe(
      debounceTime(200)
    ).subscribe(response => {
      this.handleScroll(response);
    });
  }

  handleScroll(response): void {
    const contentHeight = this.iframe.nativeElement.clientHeight;
    if (this.scrollContent.nativeElement.scrollTop > (contentHeight - 300)) {
      this.read.emit(true);
    }
  }

}
