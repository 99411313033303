<div class="u-text-right">
  <button mat-icon-button class="modal__close" (click)="close(false)"><mat-icon>close</mat-icon></button>
</div>
<mat-dialog-content class="modal__content font-secondary">

  <h1 class="u-text-bold modal__title font-secondary">Welcome, to seller verification!</h1>

  <p>To enable online transactions through TopCar, please review and agree to our terms and conditions for the supply of services.
  </p>

  <app-scroll-content [url]="url" (read)="handleRead()"></app-scroll-content>

  <div class="u-flex u-flex-align-center u-flex-justify-between u-margin-y-24">
    <form [formGroup]="formGroup" class="">
      <app-checkbox label="You agree with our <a href='https://topcar.co.uk/terms/online-payment' target='_blank'>terms and conditions</a>" formControlName="check"></app-checkbox>
    </form>
    <div class="u-flex u-flex-align-center">
      <button mat-flat-button color="primary" (click)="verifyForPayment()" [disabled]="formGroup.invalid || formGroup.disabled">Continue</button>
      <button mat-button (click)="close(true)">Close</button>
    </div>
  </div>


</mat-dialog-content>
