import {ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {CombineSubscriptions, DestroySubscribers} from 'top-car-decorators';
import {FormHelperService} from 'top-car-formly';
import {SelectOptionsListComponent} from '@kit/forms-kit/select-options-list/select-options-list.component';
import {OptionGroup, Option} from 'top-car-interfaces';
import {MaterialOverlayService, OverlayParams, WidthStrategy} from 'top-car-formly';
import {BehaviorSubject, Unsubscribable} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {SelectOptionsType} from '@kit/forms-kit/select-options-list/select-options-list.enum';
import {SelectOptionsListParams} from '@kit/forms-kit/select-options-list/select-options-list.interface';

@Component({
  selector: 'app-double-select-alternative',
  templateUrl: './double-select-alternative.component.html',
  styleUrls: ['./double-select-alternative.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@DestroySubscribers({
  destroyFunc: 'destroy'
})
export class DoubleSelectAlternativeComponent implements OnInit, OnDestroy {
  @CombineSubscriptions() subscriber: Unsubscribable;
  @ViewChild('minSide') minSide: ElementRef;
  @ViewChild('maxSide') maxSide: ElementRef;

  @Input() public label?: string;

  @Input() multiple: boolean = false;
  @Input() ngxsFormPropertyPath?: string;
  @Input() ngxsFormPath?: string;
  @Input() resetText?: string = 'Any';
  @Input() formGroupParam?: FormGroup;
  @Input() options: Option[];
  @Input() groups: OptionGroup<Option>[];

  searchFormGroup = new FormGroup({
    min: new FormControl(),
    max: new FormControl(),
  });

  opened = {
    min: false,
    min$: new BehaviorSubject(false),
    max: false,
    max$: new BehaviorSubject(false)
  };

  focused = {
    min: false,
    min$: new BehaviorSubject(false),
    max: false,
    max$: new BehaviorSubject(false)
  };

  selectedValues = {
    min: null,
    min$: new BehaviorSubject(''),
    max: null,
    max$: new BehaviorSubject('')
  };

  constructor(
    private overlay: MaterialOverlayService,
    public formHelper: FormHelperService
  ) { }

  ngOnInit(): void {

    this.handleMin();
    this.handleMax();
  }

  private handleMin() {
    this.setValue('min');
    this.setFocused('min');

    this.subscriber = this.formGroupParam.get('min').valueChanges.pipe(
      distinctUntilChanged()
    ).subscribe(value => {
      this.checkValues('min', value);
      this.setValue('min');
      this.setFocused('min');
    });
  }

  private setValue(type: 'min' | 'max') {
    this.selectedValues[type] = this.formHelper.showSelectValue(
      this.groups,
      this.options,
      this.formGroupParam.get(type).value,
      type === 'min' ? (this.label + ' min') : 'Max'
    );
    this.selectedValues[type + '$'].next(this.selectedValues[type]);
  }

  private setFocused(type: 'min' | 'max') {
    this.focused[type] = type === 'min' ? this.selectedValues[type] !== (this.label + ' min') : this.selectedValues[type] !== 'Max';
    this.focused[type + '$'].next(this.focused[type]);
  }

  private handleMax() {
    this.setValue('max');
    this.setFocused('max');

    this.subscriber = this.formGroupParam.get('max').valueChanges.pipe(
      distinctUntilChanged()
    ).subscribe(value => {
      this.checkValues('max', value);
      this.setValue('max');
      this.setFocused('max');
    });
  }

  private checkValues(type: 'min' | 'max', newValue: number) {
    const {min, max} = this.formGroupParam.value;
    if (type === 'min' && newValue > max) {
      this.formGroupParam.get('max').patchValue(null);
      this.selectedValues.max = 'Max';
    } else if (type === 'max' && newValue < min) {
      this.formGroupParam.get('min').patchValue(null);
      this.selectedValues.min = this.label + ' min';
    }
  }

  ngOnDestroy(): void {
    this.overlay.closeOverlay(this.params('min'));
    this.overlay.closeOverlay(this.params('max'));

    this.destroy();
  }

  destroy(): void {}

  params(type: 'min' | 'max'): OverlayParams {
    return {
      elementRef: type === 'min' ? this.minSide : this.maxSide,
      // backdropClass: 'search-floating-overlay',
      // panelClass: 'search-floating',
      disposeOnNavigation: true,
      saveKey: 'double-select-' + this.label.split(' ').join( '_') + type,
      type: WidthStrategy.FREE
    }
  }

  paramsPortal(type: 'min' | 'max'): SelectOptionsListParams {
    return {
      savedKey: this.params(type).saveKey,
      multiple: this.multiple,
      ngxsFormPropertyPath: this.ngxsFormPropertyPath,
      ngxsFormPath: this.ngxsFormPath,
      formControl: this.formGroupParam.get(type),
      options: this.options,
      groups: this.groups,
      type: SelectOptionsType.STANDARD,
      resetText: this.resetText,
      label: this.label.replace(' ', '_') + type
    }
  }

  toggleOverlay(type: 'min' | 'max') {
    this.opened[type] = !this.opened[type];
    this.opened[type + '$'].next(this.opened[type]);

    this.overlay.toggleOverlay(
      this.params(type),
      this.paramsPortal(type),
      SelectOptionsListComponent,
      () => {
        this.opened[type] = false;
        this.opened[type + '$'].next(false);
      }
    );
  }
}
