import {ChangeDetectionStrategy, Component, ElementRef, Inject, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UpdateFormValue} from '@ngxs/form-plugin';
import {Store} from '@ngxs/store';
import {BehaviorSubject, Unsubscribable} from 'rxjs';
import {Brand, Option} from 'top-car-interfaces';
import {CombineSubscriptions, DestroySubscribers} from 'top-car-decorators';
import {EventHandlerService} from 'top-car-formly';
import {CONTAINER_DATA, MaterialOverlayService} from 'top-car-formly';
import {FormHelperService} from 'top-car-formly';
import {SelectOptionsListParams} from '@kit/forms-kit/select-options-list/select-options-list.interface';
import {environment} from '../../../../environments/environment';

export interface FloatingSearchPassedParams {savedKey: string; params: any;}

@Component({
  selector: 'app-floating-search-options',
  templateUrl: './floating-search-options.component.html',
  styleUrls: ['./floating-search-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@DestroySubscribers()
export class FloatingSearchOptionsComponent implements OnInit, OnDestroy {
  @CombineSubscriptions() subscriber: Unsubscribable;
  @ViewChild('scrollContainer', {static: true}) scrollContainerElement: ElementRef;
  trackBy = (index: number, item: Option) => {
    return item?.id || index;
  }

  domain = environment.API;

  value$ = new BehaviorSubject('');

  hovered = false;

  constructor(
    @Inject(CONTAINER_DATA) public params: SelectOptionsListParams,
    private store: Store,
    private overlay: MaterialOverlayService,
    private eventHandler: EventHandlerService,
    private formHelper: FormHelperService,
    private ngZone: NgZone,
  ) { }

  ngOnInit(): void {
    this.value$.next(this.params.formControl.value);
    this.subscriber = this.params.formControl.valueChanges.subscribe(value => {
      this.value$.next(value);
    });
    this.ngZone.runOutsideAngular(() => {
      this.subscriber = this.formHelper.keyDown(() => this.close());
    });
  }

  ngOnDestroy(): void {}

  public close(): void {
    this.ngZone.run(() => {
      this.overlay.detachOverlay(this.params.savedKey);
    });
  }

  public select(brand: Brand): void {
    this.close();
    this.store.dispatch(new UpdateFormValue({
      value: {
        brandId: [brand.id],
        contains: ''
      },
      path: 'searchCars.searchForm'
    }));
  }

}
