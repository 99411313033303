<div class="u-position--relative">
    <div class="u-text-right">
      <button mat-icon-button class="modal__close" (click)="dialogRef.close()"><mat-icon>close</mat-icon></button>
    </div>
    <mat-dialog-content class="top-up-modal__content font-secondary u-text-center" >

          <img class="logo-modal" src="/assets/images/core/logo.png">

          <h1 class="font-secondary u-text-bold">View {{data.userType}} information</h1>

          <table class="u-width-100 modal-table">
            <tr>
              <th scope="row">Full name</th>
              <td>{{data.fullName}}</td>
            </tr>
            <tr>
              <th scope="row">Type</th>
              <td>{{data.type | userType:false}}</td>
            </tr>
            <tr *ngIf="data.imagePath">
              <th scope="row">Image</th>
              <td><img class="user-info__image" [src]="data.imagePath | url:domain" alt=""></td>
            </tr>
            <tr>
              <th scope="row">Rating</th>
              <td><app-rating [rating]="data.rating" [starCount]="5" [totalReviews]="0" [readOnly]="true" color="warn"></app-rating></td>
            </tr>
            <tr *ngIf="data.postcode">
              <th scope="row">Postcode</th>
              <td>{{data.postcode}}</td>
            </tr>
          </table>

          <app-phone-number
            [user]="data"
            [action]="data.callForAction"
            [userType]="data.userType"
            [targetId]="data.targetId"
          ></app-phone-number>

          <button mat-flat-button color="primary" class="u-width-100 car-advert-page__contact-button" (click)="startChat()">
            <mat-icon>textsms</mat-icon>
            Chat with {{data.userType}}
          </button>

          <button mat-flat-button color="primary" class="u-width-100  u-margin-top-24 car-advert-page__contact-button" (click)="openContactViaEmail()">
            <mat-icon>mail</mat-icon>
            Contact via email
          </button>

          <button *ngIf="data.userType === 'seller'" mat-stroked-button color="primary" class="u-width-100 u-margin-top-24 car-advert-page__contact-button" (click)="requestAppointment()">
            Request appointment
          </button>
    </mat-dialog-content>
  </div>

