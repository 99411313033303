<div class="payment-intent-card">
  <!--<form [formGroup]="typeForm">
    <app-button-toggle [formControl]="typeForm.controls.type" formControlName="type" [options]="paymentOptions"></app-button-toggle>
  </form>

  typeForm.value.type === 'New' ? newFormTemplate : savedFormTemplate-->

  <ng-container *ngTemplateOutlet="newFormTemplate"></ng-container>

  <ng-template #savedFormTemplate>
    <form [formGroup]="savedCardForm">
      <app-input *ngIf="!paymentIntent" formControlName="amount" label="Amount" type="number"></app-input>
      <!--<app-standard-select
        class="select--white"
        label="Cards"
        formControlName="card"
        [options]="cards$ | async"
        [multiple]="false"
      ></app-standard-select>-->
      <ng-container *ngTemplateOutlet="formButtons"></ng-container>
    </form>
  </ng-template>

  <ng-template #newFormTemplate>
    <ngx-stripe-card-group
    [stripe]="stripeInstance"
    [formGroup]="newCardForm"
    [elementsOptions]="elementsOptions"
    class="payment-intent-card u-position--relative">
      <!--<app-input *ngIf="!paymentIntent" formControlName="amount" label="Amount" type="number"></app-input>-->
      <ngx-stripe-card #stripeCard [stripe]="stripeInstance" (change)="error = $event?.error?.message;changed = true;" (error)="error = $event?.error?.message" class="payment-intent-card__number" [elementsOptions]="elementsOptions" [options]="cardOptions"></ngx-stripe-card>
      <app-input formControlName="name" label="Full name" appUppercaseInput [enableUppercase]="true"></app-input>

      <div *ngIf="error">
        <span class="u-color-danger">{{error}}</span>
      </div>

      <div *ngIf="checkbox" class="u-margin-y-16">
        <app-checkbox [label]="checkbox" formControlName="checkbox"></app-checkbox>
      </div>

      <ng-container *ngTemplateOutlet="formButtons"></ng-container>
    </ngx-stripe-card-group>
  </ng-template>


  <ng-template #formButtons>
    <div class="u-flex u-flex-align-center u-flex-justify-between">
      <button mat-button color="danger" type="reset" (click)="reset()">
        Reset
      </button>
      <button mat-button color="primary" type="submit" (click)="pay()" [disabled]="typeForm.value.type === 'New' ? (newCardForm.invalid || error || (checkbox && !newCardForm.controls.checkbox.value) || !changed) : savedCardForm.invalid">
        {{paymentButtonText}}
      </button>
    </div>
  </ng-template>

  <a class="u-margin-top-24 u-block" href="https://stripe.com" target="_blank" rel="nofollow"><img class="payment-intent-card__stripe" src="/assets/images/third-parties/powered-by-stripe.svg"></a>

</div>
