import {DOCUMENT} from '@angular/common';
import {ChangeDetectionStrategy, Component, Inject, NgZone, OnDestroy, OnInit} from '@angular/core';
import {OpacityEnterAndLeave} from '@animations/opacityEnterAndLeave';
import {eventOptions} from 'top-car-utils';
import {BehaviorSubject, fromEvent, Unsubscribable} from 'rxjs';
import {debounceTime, switchMap, take, throttleTime} from 'rxjs/operators';
import {CombineSubscriptions, DestroySubscribers} from 'top-car-decorators';

@Component({
  selector: 'app-scroll-top-button',
  templateUrl: './scroll-top-button.component.html',
  styleUrls: ['./scroll-top-button.component.scss'],
  animations: OpacityEnterAndLeave,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@DestroySubscribers()
export class ScrollTopButtonComponent implements OnInit, OnDestroy {
  @CombineSubscriptions()
  private subscriber!: Unsubscribable;

  public windowScrolled$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private ngZone: NgZone,
  ) { }

  shouldShowScrollToTopButton(): boolean {
    return !!(window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop > 100);
  }

  shouldHideScrollToTopButton(windowScrolled: boolean): boolean {
    return !!(windowScrolled && window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop <= 100);
  }

  onWindowScroll(windowScrolled: boolean): void {
    this.ngZone.run(() => {
      if (this.shouldShowScrollToTopButton()) {
        this.windowScrolled$.next(true);
      } else if (this.shouldHideScrollToTopButton(windowScrolled)) {
        this.windowScrolled$.next(false);
      }
    });
  }

  ngOnInit(): void {
    this.ngZone.runOutsideAngular(() => {
      this.subscriber = fromEvent(window, 'scroll', eventOptions).pipe(
        throttleTime(100),
        debounceTime(300),
        switchMap(() => this.windowScrolled$.pipe(take(1)))
      ).subscribe((windowScrolled) => this.onWindowScroll(windowScrolled));
    });
  }

  ngOnDestroy(): void {}

  scrollTop(): void {
    window.scrollTo(0, 0);
  }

}
