import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PasswordValidator} from 'ngx-auth';
import {TopCarApiService} from 'top-car-api';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent implements OnInit {

  formGroup = new FormGroup({
    oldPassword: new FormControl(null, [Validators.required, PasswordValidator.strong]),
    newPassword: new FormControl(null, [Validators.required, PasswordValidator.strong]),
  });

  loading = false;
  completed = false;
  error?: string;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: TopCarApiService
  ) { }

  ngOnInit(): void {}

  async sendRequest(): Promise<void> {
    this.loading = true;
    const value = this.formGroup.value;

    this.api.changePassword(value).subscribe(response => {
      this.completed = true;
      setTimeout(() => {
        this.loading = false;
        this.dialogRef.close();
      }, 3000 );
    }, error => {
      console.log(error);
      this.formGroup.controls.oldPassword.setErrors({
        incorrectPassword: true
      });
      this.loading = false;
    });

  }
}
