<mat-form-field class="u-width-100 input" appearance="outline">
  <mat-label>{{label}}</mat-label>
  <input [readonly]="readonly" [enablePostCode]="label === 'Postcode'" [enableUppercase]="label === 'Postcode'" [formControl]="control" [placeholder]="placeholder" [type]="type === 'number' ? 'text' : type"
         appPostCodeInput appUppercaseInput
         matInput appNumberOnly [enableNumbersOnly]="type === 'number' || type === 'tel'">

  <button type="button" *ngIf="suffixIcon" mat-icon-button matSuffix [matTooltip]="suffixTooltip" (click)="suffixClicked.emit(true)" [class.u-color-success]="suffixColor === 'success'">
    <mat-icon>{{suffixIcon}}</mat-icon>
  </button>

  <!--<ngx-mat-file-input *ngIf="type === 'file'" [control]="control" [placeholder]="placeholder" accept="image/*"></ngx-mat-file-input>-->

  <mat-error *ngIf="control.hasError('email') && !control.hasError('required')">
    Please enter a valid email address
  </mat-error>
  <mat-error *ngIf="control.hasError('required')">
    {{label}} is <strong>required</strong>
  </mat-error>
  <mat-error *ngIf="control.hasError('incorrectPassword')">
    Password is incorrect
  </mat-error>
  <mat-error *ngIf="control.hasError('incorrectData')">
    Information provided is incorrect
  </mat-error>
  <mat-error *ngIf="control.hasError('companyNumberIsIncorrect')">
    Company number is incorrect
  </mat-error>
  <mat-error *ngIf="control.hasError('dealerAlreadyRegistered')">
    Dealer is already registered
  </mat-error>
  <mat-error *ngIf="control.hasError('pattern')">
    {{label}} pattern is incorrect
  </mat-error>
  <mat-error *ngIf="control.hasError('error')">
    {{control.errors.error}}
  </mat-error>


</mat-form-field>
