<div class="avatar u-position--relative" [ngStyle]="{'background-color':  circleColor }">
  <img *ngIf="!showInitials" src="{{photoUrl}}" class="avatar__image" alt="Avatar">

  <div *ngIf="showInitials" class="avatar__initials">
      {{ initials }}
  </div>

  <span class="badge badge--unread badge--danger" *ngIf="unread > 0">{{unread}}</span>

  <span class="badge badge--chat" *ngIf="online !== undefined" [class.badge--danger]="!online" [class.badge--success]="online"></span>
</div>
