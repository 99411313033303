<div class="u-position--relative">
  <div class="u-text-right">
    <button mat-icon-button class="modal__close" (click)="dialogRef.close()"><mat-icon>close</mat-icon></button>
  </div>
  <mat-dialog-content class="top-up-modal__content font-secondary u-text-center" >

        <img class="logo-modal" src="/assets/images/core/logo.png">

        <h1 class="font-secondary u-text-bold">Change price</h1>


        <table class="u-width-100 modal-table">
          <tr>
            <th scope="row">Full name</th>
            <td>{{data.buyer.fullName}}</td>
          </tr>
          <tr>
            <th scope="row">Phone number</th>
            <td>{{data.buyer.phoneNumber}}</td>
          </tr>
          <tr>
            <th scope="row">Date of birth</th>
            <td>{{data.financeRequest.dateOfBirth | date:'medium'}}</td>
          </tr>
          <tr>
            <th scope="row">Customer's nationality</th>
            <td>{{data.financeRequest.nationality}}</td>
          </tr>
          <tr>
            <th scope="row">Customer's martial status</th>
            <td>{{data.financeRequest.martialStatus}}</td>
          </tr>
          
          <tr *ngFor="let address of data.financeRequest.addresses; let i = index">
            <th scope="row">Address {{i + 1}}</th>
            <td></td>
          </tr>
          <tr *ngFor="let employment of data.financeRequest.employments; let i = index">
            <th scope="row">Work {{i + 1}}</th>
            <td></td>
          </tr>
          <tr>
            <th scope="row">TopCar car price</th>
            <td class="big">{{data?.advert?.price | price}}</td>
          </tr>
          <tr>
            <th scope="row">Customer's price per month</th>
            <td>{{data.financeRequest.pricePerMonth | price}}</td>
          </tr>
          <tr>
            <th scope="row">Customer's deposit</th>
            <td>{{data.financeRequest.deposit | price}}</td>
          </tr>
          <tr>
            <th scope="row">Customer's duration</th>
            <td>{{data.financeRequest.lenghtOfContract}}</td>
          </tr>
          <tr>
            <th scope="row">Customer's annual mileage</th>
            <td>{{data.financeRequest.annualMilleage}}</td>
          </tr>

        </table>
  </mat-dialog-content>
</div>

