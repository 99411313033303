<div class="info-card">

  <div class="info-card__left">
    <mat-icon color="primary">
      info
    </mat-icon>
  </div>

  <div class="info-card__right">
    <span class="info-card__message">
      {{message}}
    </span>
  </div>

</div>