export namespace Legal {
  export class Initialize {
    static readonly type = '[Legal] Initialize';
  }
  export class ShowCookies {
    static readonly type = '[Legal] ShowCookies';
  }
  export class UpdateLegalAgreement {
    static readonly type = '[Legal] UpdateLegalAgreement';
    constructor(public hasCookiesBeenAccepted: boolean) {}
  }
}
