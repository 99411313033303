<div class="input-alternative u-flex u-flex-align-center u-flex-justify-between font-secondary"
  [class.input-alternative--value]="control.value"
  [class.input-alternative--disabled]="control.disabled"
  [class.input-alternative--invalid]="isTouched && formHelper.isInvalid(control, disable)"
>
  <input class="font-secondary" [placeholder]="label" [type]="type" [readonly]="readonly" [formControl]="control">

  <button type="button" *ngIf="suffixIcon" mat-icon-button matSuffix [matTooltip]="suffixTooltip" (click)="suffixClicked.emit(true)" [class.u-color-success]="suffixColor === 'success'">
    <mat-icon>{{suffixIcon}}</mat-icon>
  </button>
</div>

<ul *ngIf="isTouched && formHelper.isInvalid(control, disable) && !disableErrors" class="u-list-unstyled">
  <li *ngIf="control.hasError('required')">
    {{label}} is required
  </li>
  <li *ngIf="control.hasError('pattern')">
    {{label}} is incorrectly typed
  </li>
</ul>
