
<div class="u-position--relative">
  <div class="u-text-right">
    <button mat-icon-button class="modal__close" (click)="dialogRef.close()"><mat-icon>close</mat-icon></button>
  </div>
  <mat-dialog-content class="pay-for-vehicle-invoice-modal__content font-secondary u-text-center" >
    <div class="loading u-border-radius-12" *ngIf="loading">
      <mat-spinner class="loading__spinner"></mat-spinner>
    </div>
    
    <img class="pay-for-vehicle-invoice-modal__logo" src="/assets/images/core/logo.png">

    <h1 class="font-secondary u-text-bold">Invoice for {{data.advert.name}}</h1>

    <p class="u-width-75 u-margin-x-auto u-block">
      This vehicle costs {{(data.price + data.deliveryPrice) | price}}. To proceed with purchase you have to use card form below.
    </p>

    <app-payment-form [stripeInstance]="stripeInstance" [paymentIntent]="paymentIntent" paymentButtonText="Pay for car" (paymentProcessed)="viewReport()" (loading)="loading = $event"></app-payment-form>

  </mat-dialog-content>
</div>

