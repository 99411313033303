import {Injectable} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {CarAdvert} from 'top-car-interfaces';
import {UKPostcodePattern} from 'top-car-utils';

@Injectable({
  providedIn: 'root'
})
export class ReverseBuyCarSearchService {
  public formGroup = new FormGroup({
    longitude: new FormControl(),
    latitude: new FormControl(),
    contains: new FormControl(),
    brandId: new FormControl(),
    modelId: new FormControl({value: null, disabled: true}),
    modelVariantId: new FormControl({value: null, disabled: true}),
    bodyId: new FormControl({value: null, disabled: true}),
    trimId: new FormControl({value: null, disabled: true}),
    equipmentId: new FormControl({value: null, disabled: true}),
    colourId: new FormControl(),
    sort: new FormControl('Relevance'),
    price: new FormGroup({
      min: new FormControl(),
      max: new FormControl()
    }),
    mileage: new FormGroup({
      min: new FormControl(),
      max: new FormControl()
    }),
    postcode: new FormControl(null, [Validators.pattern(UKPostcodePattern)]),
    radiusMeters: new FormControl('National'),
    pageSize: new FormControl(20),
    pageNumber: new FormControl()
  });


  public options = {
    models: [],
    modelVariants: [],
    bodies: [],
    trims: [],
    equipments: []
  };

  public totalCarsFound?: number;
  public carsFound?: CarAdvert[];
  public carsFound$ = new BehaviorSubject(undefined);
  public totalPages?: number;

  public stopRedirect = false;

  constructor(
    private router: Router,
  ) {
  }

  async reset(redirectAfter = false): Promise<void> {
    if (redirectAfter) {
      this.stopRedirect = true;
    } else {
      this.router.navigate(['/reverse-buy', 'car', 'search', '1']);
    }

    this.formGroup.reset({
      pageSize: 20,
      pageNumber: 1,
      radiusMeters: 'National',
      sort: 'Relevance',
      paymentType: 'Price'
    });
    this.formGroup.controls.modelId.disable();
    this.formGroup.controls.modelVariantId.disable();
    this.formGroup.controls.bodyId.disable();
    this.formGroup.controls.trimId.disable();

    this.options.models = [];
    this.options.modelVariants = [];
    this.options.trims = [];
    this.options.bodies = [];
    this.options.equipments = [];

    // await this.search();
  }

}
