import {Pipe, PipeTransform} from '@angular/core';
import {OptionGroup, Option} from 'top-car-interfaces';

@Pipe({
  name: 'filterGroupSelectByChildren'
})
export class FilterGroupSelectChildrenPipe implements PipeTransform {

  transform(value: OptionGroup<Option>[], search: string): any {
    if (!search) {
      return value;
    }
    return value.filter(group => group.options.some(item => item.name.toUpperCase().startsWith(search.toUpperCase()) || (item.subModels && item.subModels.some(subModel => subModel.name.toUpperCase().startsWith(search.toUpperCase())))))
    ;
  }

}
