import {Pipe, PipeTransform} from '@angular/core';
import {ModelVariant} from 'top-car-interfaces';
import {OptionGroup} from 'top-car-interfaces';

@Pipe({
  name: 'filterModelVariantGroupByChildren'
})
export class FilterModelVariantGroupByChildrenPipe implements PipeTransform {

  transform(value: OptionGroup<ModelVariant>[], search: string): any {
    if (!search) {
      return value;
    }
    return value.filter(group => {
      return group.options.some(item => {
        return item.startYear.toString().toUpperCase().startsWith(search.toUpperCase())
        })
      })
    ;
  }
}
