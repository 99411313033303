<div class="u-text-right u-none">
  <button mat-icon-button class="modal__close" (click)="dialogRef.close()"><mat-icon>close</mat-icon></button>
</div>
<mat-dialog-content class="cookies-modal__content font-secondary">
  <div class="u-text-center">
    <h1 class="u-text-bold modal__title font-secondary">Cookie and Privacy Policy</h1>
  </div>
  <h2 class="cookies-modal__heading font-secondary">Disabling Cookies</h2>
  <p class="cookies-modal__text">You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to do this). Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in also disabling certain functionality and features of the this site. Therefore it is recommended that you do not disable cookies.</p>

  <h2 class="cookies-modal__heading font-secondary u-margin-top-24">The Cookies We Set</h2>
  <p class="cookies-modal__text">
    Account related cookies
    <br>
    If you create an account with us then we will use cookies for the management of the signup process and general administration. These cookies will usually be deleted when you log out however in some cases they may remain afterwards to remember your site preferences when logged out.
    <br>
    <br>
    Login related cookies
    We use cookies when you are logged in so that we can remember this fact. This prevents you from having to log in every single time you visit a new page. These cookies are typically removed or cleared when you log out to ensure that you can only access restricted features and areas when logged in.
  </p>

  <div class="u-text-center modal__submit-container">
    <button mat-flat-button color="primary" (click)="close(true)" class="cookies-modal__accept font-primary">Accept</button>
    <br>
    <button mat-button color="primary" (click)="showMore = true" *ngIf="!showMore">Learn more</button>
    <button mat-button color="primary" (click)="close(false)" *ngIf="showMore">Decline</button>
  </div>
</mat-dialog-content>
