import {Modal} from 'top-car-ngxs';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {VehiclePurchaseInterface} from 'top-car-interfaces';
import {Store} from '@ngxs/store';

@Component({
  selector: 'app-delivery-information-modal',
  templateUrl: './delivery-information-modal.component.html',
  styleUrls: ['./delivery-information-modal.component.scss']
})
export class DeliveryInformationModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: VehiclePurchaseInterface,
    public dialogRef: MatDialogRef<any>,
    private store: Store
  ) { }

  ngOnInit(): void {
  }

  changeDeliveryInformation(): void {
    this.store.dispatch(
      new Modal.OpenVehiclePurchaseEdit(this.data)
    );
  }


}
