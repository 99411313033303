<div class="u-position--relative">
    <div class="modal__loading u-flex u-flex-align-center u-flex-justify-center" *ngIf="loading">
      <mat-spinner *ngIf="!completed"></mat-spinner>
      <mat-icon class="modal__loading-completed" *ngIf="completed">check</mat-icon>
    </div>
    <div class="u-text-right">
      <button mat-icon-button class="modal__close" (click)="dialogRef.close()"><mat-icon>close</mat-icon></button>
    </div>
    <div class="u-text-center">
      <span class="u-text-bold modal__title">Contact via email</span>
    </div>
    <mat-dialog-content>
      <form [formGroup]="formGroup" (ngSubmit)="sendRequest()">
        <app-input-alternative class="u-margin-top-16 u-block" label="Email" formControlName="email"></app-input-alternative>
        <app-input-alternative class="u-margin-top-16 u-block" label="Full name" formControlName="fullName"></app-input-alternative>
        <app-textarea-alternative class="u-margin-top-16 u-block" label="Message" formControlName="message"></app-textarea-alternative>
        <div class="u-text-center modal__submit-container">
          <button mat-flat-button color="primary" [disabled]="formGroup.invalid">Send</button>
        </div>
      </form>
    </mat-dialog-content>
  </div>
