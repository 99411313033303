import {ChangeDetectionStrategy, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DOCUMENT} from '@angular/common';
import {CarAdvert, Chat, Message} from 'top-car-interfaces';
import {CombineSubscriptions, DestroySubscribers} from 'top-car-decorators';
import {interval, Observable, Unsubscribable} from 'rxjs';
import {ChatClass} from '@class/ChatClass';
import {Select, Store} from '@ngxs/store';
import {MessageHubState} from 'top-car-ngxs';
import {distinctUntilChanged, filter, map, take, tap} from 'rxjs/operators';
import {TopCarApiService} from 'top-car-api';
import {LoadingWrapper} from '@class/LoadingWrapper';
import {MessageHub} from 'top-car-ngxs';
import {NgxConnectivityService} from 'ngx-connectivity';
import {NgxAuthService} from 'ngx-auth';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@DestroySubscribers({
  destroyFunc: 'destroy'
})
export class ChatComponent implements OnInit, OnDestroy {
  @CombineSubscriptions() private subscriber!: Unsubscribable;
  @Select(MessageHubState.chats) chats$!: Observable<ChatClass[]>;
  @Select(MessageHubState.isOpenned) isOpenned$!: Observable<boolean>;
  @Select(MessageHubState.currentChat) currentChat$!: Observable<Chat>;
  @Select(MessageHubState.totalUnread) totalUnread$!: Observable<number>;
  public messages$: Observable<Message[]> = this.store.select(MessageHubState.messages).pipe(
    map((messages) => messages.map(message => ({...message, car: this.checkIfChatContainsCar(message.text)}))),
    tap(() => this.scrollToBottomChat())
  );
  public chatBuddyInfo$: Observable<any> = this.store.select(MessageHubState.chatBuddyInfo(this.auth.id));
  @ViewChild('chatListScroll', {static: false}) public chatListScroll!: ElementRef;
  @ViewChild('currentChatScroll', {static: false}) public currentChatScroll!: ElementRef;

  readonly formGroup = new FormGroup({
    message: new FormControl(null, [Validators.minLength(1)])
  });

  currentAuto$?: LoadingWrapper<CarAdvert>;

  public hovered = false;

  public readonly domain = environment.API;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public network: NgxConnectivityService,
    private store: Store,
    private api: TopCarApiService,
    private auth: NgxAuthService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new MessageHub.Connect());
    this.subscriber = this.formGroup.valueChanges.pipe(
      filter(value => !!value && !!value.message),
      distinctUntilChanged()
    ).subscribe(value => {
      const urlRegex = /(https?:\/\/[^ ]*)/;
      const links = (value.message as string).match(urlRegex);
      if (links) {
        links.forEach((item, i, arr) => {
          this.currentAuto$ = this.checkIfChatContainsCar(item);
        });
      } else {
        this.currentAuto$ = undefined;
      }
    });
    this.subscriber = interval(60000).subscribe(() => {
      this.store.dispatch(new MessageHub.GetMessages());
    });
  }

  private checkIfChatContainsCar(message: string): LoadingWrapper<CarAdvert> | undefined {
    const urlRegex = /(https?:\/\/[^ ]*)/;
    const urls = message.match(urlRegex);

    if (urls) {
      for (const url of urls) {
        if (url.includes('topcar.co.uk/car/')) {
          const split = message.split('/');

          const carId = split[split.length - 1];
          if (Number(carId)) {
            return new LoadingWrapper(this.api.getCar(Number(carId)));
          }
        }
      }
    }
  }

  mouseover(): void {
    this.hovered = true;
  }

  mouseleave(): void {
    this.hovered = false;
  }

  public getChatUser(chat: Chat): { name: string; id: string; online: boolean; } {
    if (chat.fuser === this.auth.id) {
      return {
        name: chat.secondUserName,
        id: chat.suser,
        online: !!chat.online
      };
    } else {
      return {
        name: chat.firstUserName,
        id: chat.fuser,
        online: !!chat.online
      };
    }
  }

  compareIfDifferentDay(date1: any, date2: any): boolean {
    const day1 = new Date(date1).getDate();
    const day2 = new Date(date2).getDate();

    if (day1 !== day2) {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(new MessageHub.CloseConnection())
  }

  destroy(): void {}

  goToChat(item: Chat): void {
    this.store.dispatch(new MessageHub.OpenChat(item.id as number));
  }

  toggleChat(): void {
    this.store.dispatch(new MessageHub.ToggleChat());
  }

  goToList(): void {
    this.store.dispatch(new MessageHub.CloseChat());
  }

  async sendMessage(): Promise<void> {
    this.currentChat$.pipe(take(1)).subscribe(currentChat => {
      this.currentAuto$ = undefined;
      const message = this.formGroup.value.message;
      if (message && message !== '' && message.replace(/\s/g, '').length > 0) {
        this.store.dispatch(new MessageHub.SendMessage(message, this.getChatUser(currentChat).id));
        this.formGroup.reset();
        this.scrollToBottomChat();
      }
    })
  }

  private scrollToBottomChat = () => {
    setTimeout(() => {
      const element = this.document.getElementById('CurrentChatScroll');
      if (element) {
        element.scrollTop = element.scrollHeight;
      }
    }, 100);
  }

  public track(index: number, item: any): number {
    return item?.id || index;
  }

}
