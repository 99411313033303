<mat-form-field class="u-width-100 datetime-input" appearance="outline" (click)="picker.open()">
  <mat-label>{{label}}</mat-label>
  <input [readonly]="true" [min]="min" [max]="max" [formControl]="control" [placeholder]="placeholder" matInput [matDatepicker]="picker">
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>

  <mat-error *ngIf="control.hasError('incorrectData')">
    Information provided is incorrect
  </mat-error>
  <mat-error *ngIf="control.hasError('required')">
    {{label}} is required
  </mat-error>
  <mat-error *ngIf="control.hasError('error')">
    {{control.errors.error}}
  </mat-error>
</mat-form-field>
