import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngxs/store';
import {VehiclePurchaseInterface} from 'top-car-interfaces';

@Component({
  selector: 'app-view-finance-modal',
  templateUrl: './view-finance-modal.component.html',
  styleUrls: ['./view-finance-modal.component.scss']
})
export class ViewFinanceModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: VehiclePurchaseInterface, private store: Store, public dialogRef: MatDialogRef<any>) { }

  ngOnInit(): void {
  }


}
