import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Option, PaymentIntentCustom} from 'top-car-interfaces';
import {Select, Store} from '@ngxs/store';
import {StripeCardElementOptions, StripeElementsOptions} from '@stripe/stripe-js';
import {StripeCardComponent, StripeInstance, StripeService} from 'ngx-stripe';
import {Observable, of} from 'rxjs';
import {switchMap, take, tap} from 'rxjs/operators';
import {Wallet} from 'top-car-ngxs';
import {AuthState} from 'top-car-ngxs';
import {WalletState} from 'top-car-ngxs';
import {TopCarApiService} from 'top-car-api';
import {NgxAuthService} from 'ngx-auth';

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentFormComponent implements OnInit {
  @Select(WalletState.cardsMapped) cards$: Observable<Option[]>;

  @Input() stripeInstance: StripeInstance;
  @Input() checkbox?: string;
  @Input() paymentButtonText = 'Pay';
  @Input() paymentIntent?: PaymentIntentCustom;
  @Input() amount?: number;

  @Output() paymentProcessed = new EventEmitter();
  @Output() loading = new EventEmitter();

  @ViewChild(StripeCardComponent) public card: StripeCardComponent;

  changed = false;

  readonly cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: 'black',
        color: '#31325F',
        fontWeight: '400',
        fontFamily: '"Roboto", Helvetica, sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: 'black',
        },
      },
    },
  };

  readonly elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  readonly paymentOptions = [
    {
      name: 'Saved card',
      value: 'Saved'
    },
    {
      name: 'New card',
      value: 'New'
    }
  ];

  readonly savedCardForm = new FormGroup({
    amount: new FormControl(),
    card: new FormControl(null, [Validators.required]),
  });

  readonly newCardForm = new FormGroup({
    amount: new FormControl(),
    name: new FormControl(null, [Validators.required]),
    checkbox: new FormControl()
  });

  readonly typeForm = new FormGroup({
    type: new FormControl('New')
  });

  public error: any;

  constructor(private store: Store, private stripeService: StripeService, private api: TopCarApiService, private auth: NgxAuthService) { }

  ngOnInit(): void {
    this.store.dispatch(new Wallet.Get());
    this.reset();
  }

  public reset(): void {
    this._resetSavedForm();
    this._resetNewForm();
  }

  private _resetSavedForm(): void {
    this.cards$.pipe(take(1)).subscribe(response => {
      this.savedCardForm.reset({
        amount: this.amount,
        card: response && response.length > 0 ? response[0].value : null
      });
    })
  }

  private _resetNewForm(): void {
    const {fullName} = this.auth.userData;
    this.newCardForm.reset({
      amount: this.amount,
      name: fullName
    });
  }

  public pay(): void {
    this.loading.emit(true);
    const type = this.typeForm.value.type;
    const amount = type === 'Saved' ? this.savedCardForm.get('amount').value : this.newCardForm.get('amount').value;
    let observable: Observable<PaymentIntentCustom>;
    if (!this.paymentIntent) {
      observable = this.api.addFunds(amount).pipe(
        tap((paymentIntent) => this.paymentIntent = paymentIntent)
      );
    } else {
      observable = of(this.paymentIntent).pipe(take(1));
    }

    observable.pipe(
      switchMap(pi => {
        return this.stripeService.confirmCardPayment(pi.client_secret, {
          payment_method: type === 'Saved' ? this.savedCardForm.get('card').value.id : {
            card: this.card.element,
            billing_details: type === 'Saved' ? {} : {
              name: this.newCardForm.get('name').value,
            },
          },
        });
      })
    ).subscribe((result) => {
      if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        // console.log(result.error.message);
        this.loading.emit(false);
        this.error = result.error.message;
      } else {
        // The payment has been processed!
        if (result.paymentIntent.status === 'succeeded') {
          // Show a success message to your customer
          this.paymentProcessed.emit(true);
        }
      }
    }, error => {
      this.error = error?.error?.message;
      this.loading.emit(false);
    });

  }

}
