import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
// import { MatSelectModule } from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';

import {CommonKitModule} from '../common-kit/common-kit.module';
import {SearchInputComponent} from './search-input/search-input.component';
import {FileInputComponent} from './file-input/file-input.component';
import {InputComponent} from './input/input.component';
import {CheckboxComponent} from './checkbox/checkbox.component';
import {TabButtonToggleComponent} from './tab-button-toggle/tab-button-toggle.component';
import {ButtonToggleComponent} from './button-toggle/button-toggle.component';
import {TogglerComponent} from './toggler/toggler.component';
import {UppercaseInputDirective} from './uppercase-input.directive';
import {PostCodeInputDirective} from './post-code-input.directive';
import {PasswordValidatorBlockComponent} from './password-validator-block/password-validator-block.component';
import {SendMessageFormComponent} from './send-message-form/send-message-form.component';
import {AvatarUploaderComponent} from './avatar-uploader/avatar-uploader.component';
import {RadioComponent} from './radio/radio.component';
import {DragAndDropDirective} from './drag-and-drop.directive';
import {ScrollToErrorDirective} from './scroll-to-error.directive';
import {NumberOnlyDirective} from './number-only.directive';
import {NgxStripeModule} from 'ngx-stripe';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {NgxMatNativeDateModule, NgxMatTimepickerModule} from '@angular-material-components/datetime-picker';
import {DragDropModule} from '@angular/cdk/drag-drop';
// import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {NgxsFormPluginModule} from '@ngxs/form-plugin';
import {FloatingSearchOptionsComponent} from './floating-search-options/floating-search-options.component';
import {FilterGroupSelectChildrenPipe} from './filter-group-select-children.pipe';
import {FilterModelVariantGroupByChildrenPipe} from './filter-model-variant-group-by-children.pipe';
import {FilterByStartYearPipe} from './filter-by-start-year.pipe';
import {DoubleSelectAlternativeComponent} from '@kit/forms-kit/double-select-alternative/double-select-alternative.component';
import {SelectOptionsListComponent} from '@kit/forms-kit/select-options-list/select-options-list.component';
import {SelectAlternativeComponent} from '@kit/forms-kit/select-alternative/select-alternative.component';
import {InputAlternativeComponent} from '@kit/forms-kit/input-alternative/input-alternative.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CheckboxAlternativeComponent} from '@kit/forms-kit/checkbox-alternative/checkbox-alternative.component';
import {CalendarComponent} from '@kit/forms-kit/calendar/calendar.component';
import {NgxCalendarModule} from 'ngx-calendar';
import {TextareaAlternativeComponent} from '@kit/forms-kit/textarea-alternative/textarea-alternative.component';
import {DatetimeInputComponent} from '@kit/forms-kit/datetime-input/datetime-input.component';
import {SharedModule} from '../../modules/shared/shared.module';

@NgModule({
  providers: [
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMatNativeDateModule,
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
  ],
  declarations: [
    SearchInputComponent,
    InputComponent,
    CheckboxComponent,
    // TextareaComponent,
    FileInputComponent,
    TabButtonToggleComponent,
    ButtonToggleComponent,
    TogglerComponent,
    // DoubleSelectComponent,
    UppercaseInputDirective,
    PostCodeInputDirective,
    PasswordValidatorBlockComponent,
    SendMessageFormComponent,
    AvatarUploaderComponent,
    RadioComponent,
    DragAndDropDirective,
    ScrollToErrorDirective,
    NumberOnlyDirective,
    DatetimeInputComponent,
    // TimeInputComponent,
    FloatingSearchOptionsComponent,
    // GroupSelectComponent,
    // StandardSelectComponent,
    // ModelVariantSelectComponent,
    FilterGroupSelectChildrenPipe,
    FilterModelVariantGroupByChildrenPipe,
    FilterByStartYearPipe,
    DoubleSelectAlternativeComponent,
    SelectOptionsListComponent,
    SelectAlternativeComponent,
    // ModelVariantSelectAlternativeComponent,
    InputAlternativeComponent,
    CheckboxAlternativeComponent,
    CalendarComponent,
    TextareaAlternativeComponent
   ],
  imports: [
    CommonModule,
    CommonKitModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,

    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    // MatSelectModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatRadioModule,
    DragDropModule,
    NgxStripeModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    // NgxMatSelectSearchModule,
    NgxsFormPluginModule,
    ScrollingModule,
    NgxCalendarModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,

    SearchInputComponent,
    InputComponent,
    CheckboxComponent,
    // TextareaComponent,
    FileInputComponent,
    TabButtonToggleComponent,
    ButtonToggleComponent,
    TogglerComponent,
    // DoubleSelectComponent,
    PasswordValidatorBlockComponent,
    SendMessageFormComponent,
    AvatarUploaderComponent,
    PostCodeInputDirective,
    UppercaseInputDirective,
    RadioComponent,
    ScrollToErrorDirective,
    NumberOnlyDirective,
    DatetimeInputComponent,
    // TimeInputComponent,
    NgxsFormPluginModule,
    // StandardSelectComponent,
    // GroupSelectComponent,
    // ModelVariantSelectComponent,
    DoubleSelectAlternativeComponent,
    SelectOptionsListComponent,
    SelectAlternativeComponent,
    // ModelVariantSelectAlternativeComponent,
    InputAlternativeComponent,
    CheckboxAlternativeComponent,
    CalendarComponent,
    TextareaAlternativeComponent
  ],
})
export class FormsKitModule { }
