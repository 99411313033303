import {Observable, Subject, throwError} from 'rxjs';
import {catchError, shareReplay} from 'rxjs/operators';

export class LoadingWrapper<T> {
  private _errorLoading$ = new Subject<boolean>();
  errorLoading$: Observable<boolean> = this._errorLoading$.pipe(
    shareReplay(1)
  );
  data$: Observable<T>;

  constructor(data: Observable<T>) {
    this.data$ = data.pipe(
      shareReplay(1),
      catchError(error => {
        console.log(error);
        this._errorLoading$.next(true);
        return throwError(error);
      })
    );
  }

}
