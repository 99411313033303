import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {Action, NgxsOnInit, Selector, State, StateContext, Store} from '@ngxs/store';
import {tap} from 'rxjs/operators';
import {Legal} from './legal.actions';
import {Modal, TopCarNgxsConfig} from 'top-car-ngxs';
import {LegalStateModel} from './legal.model';
import {Persistence, StateRepository} from '@ngxs-labs/data/decorators';
import {NgxsDataRepository} from '@ngxs-labs/data/repositories';

export const detectRobot = (userAgent: string): boolean => {
  const robots = new RegExp(([
    /bot/,/spider/,/crawl/,                               // GENERAL TERMS
    /APIs-Google/,/AdsBot/,/Googlebot/,/Google/,          // GOOGLE ROBOTS
    /mediapartners/,/Google Favicon/,
    /FeedFetcher/,/Google-Read-Aloud/,
    /DuplexWeb-Google/,/googleweblight/,
    /bing/,/yandex/,/baidu/,/duckduck/,/yahoo/,           // OTHER ENGINES
    /ecosia/,/ia_archiver/,
    /facebook/,/instagram/,/pinterest/,/reddit/,          // SOCIAL MEDIA
    /slack/,/twitter/,/whatsapp/,/youtube/,
    /semrush/,                                            // OTHER
  ] as RegExp[]).map((r) => r.source).join("|"),"i");     // BUILD REGEXP + "i" FLAG

  return robots.test(userAgent);
};

@Persistence()
@StateRepository()
@State<LegalStateModel>({
  name: 'legal',
  defaults: new LegalStateModel()
})
@Injectable()
export class LegalState extends NgxsDataRepository<LegalStateModel> implements NgxsOnInit {
  @Selector()
  static hasCookiesBeenAccepted(state: LegalStateModel): boolean {
    return state.hasCookiesBeenAccepted;
  }

  @Selector()
  static hasCookiesPolicyBeenShown(state: LegalStateModel): boolean {
    return state.hasCookiesPolicyBeenShown;
  }

  constructor(@Inject(TopCarNgxsConfig) private _config: TopCarNgxsConfig, @Inject(DOCUMENT) private document: Document, private store: Store) {
    super();
  }

  ngxsOnInit(): void {
    console.log('init legal');
    if (!detectRobot(navigator.userAgent)) {
      this.dispatch(Legal.Initialize);
    }
  }

  @Action(Legal.Initialize)
  initialize({dispatch, getState}: StateContext<LegalStateModel>) {
    const {hasCookiesBeenAccepted, hasCookiesPolicyBeenShown} = getState();
    if (!hasCookiesPolicyBeenShown) {
      this.patchState({hasCookiesPolicyBeenShown: true});
      return dispatch(new Legal.ShowCookies());
    } else if (hasCookiesBeenAccepted) {
      return this._enableAnalytics();
    }
  }

  @Action(Legal.ShowCookies)
  showCookies() {
    return this.store.dispatch(new Modal.OpenCookies()).pipe(tap(agreed => {
      if (agreed) {
        this._enableAnalytics();
      }
    }));
  }

  @Action(Legal.UpdateLegalAgreement)
  updateLegalAgreement({patchState}: StateContext<LegalStateModel>, {hasCookiesBeenAccepted}: Legal.UpdateLegalAgreement) {
    if (hasCookiesBeenAccepted) {
      this._enableAnalytics();
    }
    return patchState({hasCookiesBeenAccepted});
  }

  private _enableAnalytics(trackingID: string = this._config.googleAnalytics): void {
    const gaScript = this.document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${trackingID}`);

    const gaScript2 = this.document.createElement('script');
    gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${trackingID}\');`;

    if (this.document.documentElement && this.document.documentElement.firstChild) {
      this.document.documentElement.firstChild.appendChild(gaScript);
      this.document.documentElement.firstChild.appendChild(gaScript2);
    }
  }
}
