import {CloseScrollStrategy, ConnectedPosition, FlexibleConnectedPositionStrategy, Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal, ComponentType, PortalInjector} from '@angular/cdk/portal';
import {ComponentRef, ElementRef, Injectable, InjectionToken, Injector, ViewContainerRef} from '@angular/core';

export type PositionStategy = 'flexible';
export type ScrollStategy = 'close';
export enum WidthStrategy {
  FULL_WIDTH,
  FREE
}
export interface OverlayParams {
  disposeOnNavigation?: boolean;
  panelClass?: string;
  backdropClass?: string;
  elementRef?: ElementRef;
  saveKey?: string;
  type: WidthStrategy;
}

export const CONTAINER_DATA = new InjectionToken<{}>('CONTAINER_DATA');

@Injectable({
  providedIn: 'root'
})
export class MaterialOverlayService {

  savedOverlays: any = {};

  private defaultFlexiblePositions: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
    }, {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
    },
  ];

  constructor(private overlay: Overlay, private injector: Injector) { }

  public getOverlay(key: string): OverlayRef {
    return this.savedOverlays[key];
  }

  public createOverlay(positionStategy: PositionStategy, scrollStrategy: ScrollStategy, params: OverlayParams) {
    const overlayRef = this.overlay.create({
      positionStrategy: this.positionStategyDecider(positionStategy, params),
      scrollStrategy: this.scrollStategyDecider(scrollStrategy),
      backdropClass: params.backdropClass,
      panelClass: params.panelClass,
      disposeOnNavigation: params.disposeOnNavigation,
      minWidth: params.elementRef.nativeElement.clientWidth,
      width: params.type === WidthStrategy.FULL_WIDTH ? '100%' : null,
      maxWidth: params.type === WidthStrategy.FULL_WIDTH ? params.elementRef.nativeElement.clientWidth : null,
    });

    if (params.saveKey) {
      this.savedOverlays[params.saveKey] = overlayRef;
    }

    return overlayRef;
  }

  public attachOverlay<T>(key: string, portal: ComponentPortal<T>): any {
    const overlayRef = this.getOverlay(key);
    if (overlayRef && !overlayRef.hasAttached()) {
      return overlayRef.attach(portal);
    }
  }

  public detachOverlay(key: string): void {
    const overlayRef = this.getOverlay(key);
    if (overlayRef && overlayRef.hasAttached()) {
      overlayRef.detach();
    }
  }

  public destroyOverlay(key: string): void {
    if (key && this.savedOverlays[key]) {
      this.savedOverlays[key].dispose();
      this.savedOverlays[key] = undefined;
    }
  }

  public createInjector(dataToPass: any): Injector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(CONTAINER_DATA, dataToPass);
    return new PortalInjector(this.injector, injectorTokens);
  }

  public createComponentPortal<T>(
    elementRef: ComponentType<T>,
    viewContainerRef?: ViewContainerRef,
    injectionParams?: any
  ): ComponentPortal<T> {
    return new ComponentPortal(
      elementRef,
      null,
      this.createInjector(injectionParams)
    );
  }

  private scrollStategyDecider = (scrollStategy: ScrollStategy) => {
    switch (scrollStategy) {
      case 'close': return this.closeScrollStrategy();
    }
  }

  private closeScrollStrategy = (): CloseScrollStrategy => {
    return this.overlay.scrollStrategies.close()
  }

  private positionStategyDecider = (positionStategy: PositionStategy, params: OverlayParams) => {
    switch(positionStategy) {
      case 'flexible': return this.flexiblePositionStategy(params.elementRef);
    }
  }

  private flexiblePositionStategy = (elementRef: ElementRef): FlexibleConnectedPositionStrategy => {
    const positionStrategy = this.overlay.position().flexibleConnectedTo(elementRef);
    positionStrategy.withFlexibleDimensions(false)
    .withPositions(this.defaultFlexiblePositions);
    return positionStrategy;
  }

  public toggleOverlay<T>(
    params: OverlayParams,
    componentParams: any,
    componentElement: ComponentType<T>,
    onDestroy?: () => any
  ) {
    if (!params.type) {
      params.type = WidthStrategy.FULL_WIDTH;
    }
    if (this.savedOverlays[params.saveKey]) {
      this.closeOverlay(params);
    } else {
      this.openOverlay(params, componentParams, componentElement, onDestroy);
    }
  }

  openOverlay<T>(
    params: OverlayParams,
    componentParams: any,
    componentElement: ComponentType<T>,
    onDestroy?: () => any
  ) {
    this.createOverlay('flexible', 'close', params);
    const portal = this.createComponentPortal(componentElement, null, componentParams);
    const ref: ComponentRef<T> = this.attachOverlay(params.saveKey, portal);

    ref.onDestroy(() => {
      this.closeOverlay(params);
      if (onDestroy) {
        onDestroy()
      }
    });
  }

  closeOverlay(params: OverlayParams) {
    this.detachOverlay(params.saveKey);
    this.savedOverlays[params.saveKey] = false;
  }
}
