<ngx-stripe-card-group
  [formGroup]="formGroup"
  [elementsOptions]="elementsOptions"
  class="payment-intent-card u-position--relative"
>

  <app-button-toggle formControlName="type" [options]="typeOptions"></app-button-toggle>


  <div class="modal__loading u-flex u-flex-align-center u-flex-justify-center" *ngIf="loading">
    <mat-spinner *ngIf="!completed"></mat-spinner>
    <mat-icon class="modal__loading-completed" *ngIf="completed">check</mat-icon>
  </div>
  <ng-container *ngIf="formGroup.controls.type.value === 'New'">
    <ngx-stripe-card #stripeCard (change)="error = $event.error" (error)="error = $event" class="payment-intent-card__number" [elementsOptions]="elementsOptions" [options]="cardOptions" [class.u-margin-bottom-5]="error"></ngx-stripe-card>
    <span class="u-color-danger" *ngIf="error">{{error.message}}</span>
    <app-input formControlName="name" label="Full name" appUppercaseInput [enableUppercase]="true"></app-input>
  </ng-container>

  <app-input *ngIf="type === 'Topup'" formControlName="amount" label="Amount" type="number"></app-input>

  <ng-container *ngIf="formGroup.controls.type.value === 'Saved'">
    <!--<app-standard-select class="select--white" label="Cards" formControlName="card" [options]="cards"></app-standard-select>-->
    <span class="u-color-danger" *ngIf="error">{{error.message}}</span>
  </ng-container>

  <app-checkbox [class.u-margin-top-16]="formGroup.controls.type.value === 'Saved'" [class.u-block]="formGroup.controls.type.value === 'Saved'" *ngIf="showTotalCheckbox" label="Do you agree with <a href='https://totalcarcheck.co.uk/TermsAndConditions' target='_blank'>TotalCarCheck's</a> and <a href='/terms' target='_blank'>TopCar's</a> policies?" formControlName="agree"></app-checkbox>

  <div class="u-flex u-flex-align-center u-flex-justify-between" [class.u-margin-top-16]="formGroup.controls.type.value === 'Saved' || showTotalCheckbox">
    <button mat-button color="danger" type="reset" (click)="reset()">
      Reset
    </button>
    <button mat-button color="primary" type="submit" (click)="pay()" [disabled]="formGroup.invalid || (formGroup.controls.type.value === 'New' && error)">
      {{paymentButtonText}}
    </button>
  </div>

  <a class="u-margin-top-24 u-block" href="https://stripe.com" target="_blank" rel="nofollow"><img class="payment-intent-card__stripe" src="/assets/images/third-parties/powered-by-stripe.svg"></a>


</ngx-stripe-card-group>

