import {ChangeDetectionStrategy, Component, forwardRef, Input} from '@angular/core';
import {AbstractControl, ControlContainer, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {AdvertImage} from 'top-car-interfaces';
import {moveItemInArray} from '@angular/cdk/drag-drop';
import {Modal} from 'top-car-ngxs';
import {Store} from '@ngxs/store';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileInputComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileInputComponent implements ControlValueAccessor {
  @Input() label?: string;
  @Input()
  formControl: FormControl;
  @Input()
  formControlName: string;

  @Input()
  uploadedImages: AdvertImage[];

  files: any = [];

  domain = environment.API;

  constructor(private controlContainer: ControlContainer, private store: Store) {}

  get control(): AbstractControl {
    return this.controlContainer.control.get(this.formControlName);
  }

  uploadFile(event): void {
    for (const element of event) {
      this.files.push(element);
    }
    this.control.patchValue(this.files);
  }

  deleteAttachment(index): void {
    this.files.splice(index, 1);
    this.control.patchValue(this.files);
  }

  deleteUpploadedAttachment(index): unknown {
    if (this.uploadedImages.length === 3) {
      return this.store.dispatch(new Modal.OpenWarning('You cannot delete last three uploaded images. Upload some first and then try again'));
    }
    this.uploadedImages.splice(index, 1);
    // this.control.patchValue(this.files);
  }

  clearInput(): void {
    this.control.setValue('');
  }

  registerOnTouched(fn: any): void {
    // this.formControlDirective.valueAccessor.registerOnTouched(fn);
  }

  registerOnChange(fn: any): void {
    // this.formControlDirective.valueAccessor.registerOnChange(fn);
  }

  writeValue(obj: any): void {
    // this.formControlDirective.valueAccessor.writeValue(obj);
  }

  setDisabledState(isDisabled: boolean): void {
    // this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
  }

  drop(event: any, type: string): void {
    moveItemInArray(type === 'uploaded' ? this.uploadedImages : this.files, event.previousIndex, event.currentIndex);
  }
}
