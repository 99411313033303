import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit} from '@angular/core';
import {LocalStorage} from 'ngx-storage';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent implements OnInit, OnChanges {

  @Input()
  public photoUrl: string;

  @Input()
  public name: string;

  @Input()
  public online: boolean;

  @Input()
  public unread: number;

  public showInitials = true;
  public initials: string;
  public circleColor: string = this.storage.getItem('@topcar.profile.avatar');

  private colors = [
      '#EB7181', // red
      '#468547', // green
      '#FFD558', // yellow
      '#3670B2', // blue
  ];

  constructor(private storage: LocalStorage) {
  }

  ngOnInit(): void {
    this.update();
  }

  ngOnChanges(): void {
    this.update();
  }

  update(): void {
    if (!this.photoUrl && this.name) {
      this.showInitials = true;
      this.createInitials();

      if (!this.circleColor) {
        const randomIndex = Math.floor(Math.random() * Math.floor(this.colors.length));
        this.circleColor = this.colors[randomIndex];
        this.storage.setItem('@topcar.profile.avatar', this.circleColor);
      }
    } else {
      this.showInitials = null;
    }
  }

  private createInitials(): void {
      let initials = '';

      for (let i = 0; i < this.name.length; i++) {
          if (this.name.charAt(i) === ' ') {
              continue;
          }

          if (this.name.charAt(i - 1) === ' ' || i === 0) {
              initials += this.name.charAt(i).toUpperCase();

              if (initials.length === 2) {
                  break;
              }
          }
      }

      this.initials = initials;
  }
}
