import {ViewportScroller} from '@angular/common';
import {Component, forwardRef, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {DEFAULT_PAGINATION_NAME} from 'top-car-utils';
import {CombineSubscriptions, DestroySubscribers} from 'top-car-decorators';
import {Unsubscribable} from 'rxjs';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PaginationComponent),
      multi: true
    }
  ],
})
@DestroySubscribers()
export class PaginationComponent implements OnInit, OnChanges, ControlValueAccessor, OnDestroy {
  @Input() totalPages: number | string;
  @Input()
  formControlName: string;
  @Input()
  page = '..';
  @Input()
  fragment?: string;

  @Input() navigationOff = false;

  @CombineSubscriptions() subscriber!: Unsubscribable;


  public pageOptions = [];

  constructor(private controlContainer: ControlContainer, private route: ActivatedRoute, private viewportScroller: ViewportScroller,
              private router: Router) { }

  ngOnInit(): void {
    if (Number(this.totalPages)) {
      if (this.totalPages <= 1) {
        this.pageOptions = [];
      } else {
        this.pageOptions = Array(Number(this.totalPages) + 1).fill(null).map((x, i) => i).filter(e => e !== 0);
      }
    }

    const routeToListen = this.route.firstChild?.firstChild || this.route.firstChild || this.route;

    this.subscriber = routeToListen.params.subscribe(params => {
      console.log(params);
      const page = Number(params[DEFAULT_PAGINATION_NAME]);
      this.changePage(page, false);
    });
  }

  ngOnChanges(): void {
    if (Number(this.totalPages)) {
      if (Number(this.totalPages) <= 1) {
        this.pageOptions = [];
      } else {
        this.pageOptions = Array(Number(this.totalPages) + 1).fill(null).map((x, i) => i).filter(e => e !== 0);
      }
    }
  }

  ngOnDestroy(): void {}

  async changePage(page: number, manual = true): Promise<void> {
    if (this.control.value === page) {
      return;
    }
    this.control.patchValue(page);
    if (this.fragment && manual) {
      if (!this.navigationOff) {
        await this.router.navigate([this.page, page]);
      }
      setTimeout(() => { this.viewportScroller.scrollToAnchor(this.fragment); });
    } else {
      if (!this.navigationOff) {
        await this.router.navigate([this.page, page]);
      }
    }
  }

  displayTextProvider(pageNumber: number): string | number {
    return (
      (this.control.value - 2) === pageNumber ||
      (this.control.value + 2) === pageNumber
    ) &&
      pageNumber < this.pageOptions.length ? '...' : pageNumber;
  }

  get shouldDisplayBackButton(): boolean {
    return this.control.value > 1;
  }

  get shouldDisplayFirstPageButton(): boolean {
    return this.control.value > 2;
  }

  get slicePageOption(): number {
    return (this.control.value === 1 || this.control.value === 2 ? 0 : (this.control.value - 3));
  }

  get control(): AbstractControl {
    return this.controlContainer.control.get(this.formControlName);
  }

  onChange: any = () => { };
  onTouched: any = () => {
    this.control.markAsTouched();
  }

  clearInput(): void {
    // this.control.setValue('');
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  writeValue(obj: any): void {
    // this.formControlDirective.valueAccessor.writeValue(obj);
  }

  setDisabledState(isDisabled: boolean): void {
    // this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
  }
}
