<div class="u-position--relative">
  <div class="u-text-right">
    <button mat-icon-button class="modal__close" (click)="dialogRef.close()"><mat-icon>close</mat-icon></button>
  </div>
  <mat-dialog-content class="top-up-modal__content font-secondary u-text-center" >

        <img class="logo-modal" src="/assets/images/core/logo.png">

        <h1 class="font-secondary u-text-bold">Part exchange information</h1>

        <table class="u-width-100 modal-table">
          <tr>
            <th scope="row">Full name</th>
            <td>{{data.buyer.fullName}}</td>
          </tr>
          <tr>
            <th scope="row">Brand</th>
            <td>{{data.partExchange.brand.name}}</td>
          </tr>
          <tr>
            <th scope="row">Model</th>
            <td>{{data.partExchange.model.name}}</td>
          </tr>
          <tr>
            <th scope="row">Model variant</th>
            <td>{{data.partExchange.modelVariant.startYear + ' - ' + (data.partExchange.modelVariant.endYear || 'Current')}}</td>
          </tr>
          <tr>
            <th scope="row">Body</th>
            <td>{{data.partExchange.body.name}}</td>
          </tr>
          <tr>
            <th scope="row">Trim</th>
            <td>{{data.partExchange.trim.name}}</td>
          </tr>
          <tr>
            <th scope="row">Condition</th>
            <td>{{data.partExchange.condition}}</td>
          </tr>
          <tr>
            <th scope="row">Odometor reading</th>
            <td>{{data.partExchange.mileage | number}}</td>
          </tr>
          <tr>
            <th scope="row">TopCar evaluated car price</th>
            <td class="big">N/A<!--{{data?.advert?.price | price}}--></td>
          </tr>
        </table>
  </mat-dialog-content>
</div>

