import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CONTAINER_DATA, MaterialOverlayService} from 'top-car-formly';
import {CombineSubscriptions, DestroySubscribers} from 'top-car-decorators';
import {Option} from 'top-car-interfaces';
import {BehaviorSubject, Unsubscribable} from 'rxjs';
import {EventHandlerService} from 'top-car-formly';
import {Store} from '@ngxs/store';
import {UpdateFormValue} from '@ngxs/form-plugin';
import {debounceTime} from 'rxjs/operators';
import {FormHelperService} from 'top-car-formly';
import {SelectOptionsType} from '@kit/forms-kit/select-options-list/select-options-list.enum';
import {SelectOptionsListParams} from '@kit/forms-kit/select-options-list/select-options-list.interface';
import {CarSearchService} from '@services/car/car-search.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-select-options-list',
  templateUrl: './select-options-list.component.html',
  styleUrls: ['./select-options-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@DestroySubscribers({
  destroyFunc: 'destroy'
})
export class SelectOptionsListComponent implements OnInit, OnDestroy, AfterViewInit {
  @CombineSubscriptions()
  private subscriber: Unsubscribable;

  selectedItems = [];
  opennedChildren = [];

  hovered = false;

  selectOptionsType = SelectOptionsType;

  searchValue$ = new BehaviorSubject(undefined);

  domain = environment.API;

  @ViewChild('optionsScrollContainer', {static: true}) private optionsScrollContainer: ElementRef;

  constructor(
    @Inject(CONTAINER_DATA) public params: SelectOptionsListParams,
    private store: Store,
    private overlay: MaterialOverlayService,
    private eventHandler: EventHandlerService,
    private ngZone: NgZone,
    private formHelper: FormHelperService,
    private carSearch: CarSearchService
  ) { }

  trackBy(index: number, item: any): number {
    return item?.id || index;
  }

  ngOnInit() {
    if (this.carSearch.opennedChildren[this.params.savedKey]) {
      this.opennedChildren = this.carSearch.opennedChildren[this.params.savedKey];
    }
    if (this.params.searchControl) {
      this.searchValue$.next(this.params.searchControl.value);
      this.subscriber = this.params.searchControl.valueChanges.pipe(
        debounceTime(300)
      ).subscribe(value => {
        this.searchValue$.next(value);
      })
    }
    this.selectedItems = Array.isArray(this.params.formControl.value) ? [...this.params.formControl.value] : this.params.formControl.value ? [this.params.formControl.value] : [];
    this.ngZone.runOutsideAngular(() => {
      this.subscriber = this.formHelper.keyDown(() => this.close());
    });
  }

  ngAfterViewInit() {
    if (this.carSearch.scroll[this.params.savedKey]) {
      this.optionsScrollContainer.nativeElement.scrollTop = this.carSearch.scroll[this.params.savedKey];
    }
  }

  ngOnDestroy(): void {
    this.carSearch.scroll[this.params.savedKey] = this.optionsScrollContainer.nativeElement.scrollTop;
    this.carSearch.opennedChildren[this.params.savedKey] = this.opennedChildren;
    this.destroy();
  }

  destroy(): void {}

  public close(): void {
    this.ngZone.run(() => {
      this.overlay.detachOverlay(this.params.savedKey);
    });
  }

  public unselect(item: Option): void {
    const newValue = this.params.multiple ? this.selectedItems.filter(selected => selected !== item.value) : undefined;
    this.selectedItems = this.params.multiple ? newValue : [newValue];

    this.patchValue(newValue);
  }

  public select(item: Option): void {
    const {optionsToSearch, subModels} = this.formHelper.getOptionAndSubModels(this.params.options, this.params.groups);

    const {allSubModelsSelected, selectedOptions} = this.formHelper.selectedOptions(optionsToSearch, this.params.formControl.value);

    if (
      this.params.multiple && (
        allSubModelsSelected.some(option => option.id === item.parentId) || selectedOptions.some(option => option.id === item.parentId)
      )
    ) {

      const parentSubModels = subModels.filter(option => option.parentId === item.parentId && option.id !== item.id);
      const newValue = [...selectedOptions.filter(option => option.id !== item.parentId), ...parentSubModels].map(option => option.id);
      this.selectedItems = newValue;
      return this.patchValue(newValue);
    }

    // const selectedSubModels = this.formHelper.selectedSubModels(subModels, allSubModelsSelected, this.params.formControl.value);

    if (this.selectedItems === item.value || this.selectedItems.some(selected => selected === item.value)) {
      return this.unselect(item);
    }

    const newValue = this.params.multiple ? [...this.selectedItems, item.value] : item.value;
    this.selectedItems = this.params.multiple ? newValue : [newValue];

    this.patchValue(newValue);

    if (!this.params.multiple) {
      this.close();
    }
  }

  private patchValue(newValue: any) {

    if (this.params.ngxsFormPath && this.params.ngxsFormPropertyPath) {
      this.store.dispatch(new UpdateFormValue({
        value: newValue,
        propertyPath: this.params.ngxsFormPropertyPath,
        path: this.params.ngxsFormPath
      }));
    } else if (this.params.formControl) {

      this.params.formControl.patchValue(this.params.key ? {
        ...this.params.formControl.value,
        [this.params.key]: newValue
      } : newValue);
    } else {

      console.log('No form control and ngxsForm provided');
    }
  }

  public toggleSubmodel(option: Option) {
    if (this.opennedChildren.includes(option.id)) {
      this.opennedChildren = this.opennedChildren.filter(e => e !== option.id);
    } else {
      this.opennedChildren.push(option.id);
    }
  }

  reset() {
    this.selectedItems = [];
    this.opennedChildren = [];
    this.params.formControl.patchValue(this.params.multiple ? [] : null);
    this.close();
  }

  isChecked(option: Option) {
    if (option.parentId) {
      return this.selectedItems.includes(option.parentId) || this.selectedItems.includes(option.id);
    } else if (option.hasChildren) {
      const subModels = option.subModels.map(e => e.id);
      const subModelFiltered = subModels.filter(e => this.selectedItems.includes(e));
      return subModels.length === subModelFiltered.length || this.selectedItems.includes(option.id);
    } else {
      return this.selectedItems.includes(option.id);
    }
  }
}
