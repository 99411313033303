import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngxs/store';
import {Legal} from '../../../ngxs/legal/legal.actions';

@Component({
  selector: 'app-cookies-modal',
  templateUrl: './cookies-modal.component.html',
  styleUrls: ['./cookies-modal.component.scss']
})
export class CookiesModalComponent implements OnInit {
  showMore = false;

  constructor(
    public dialogRef: MatDialogRef<any>,
    private store: Store
  ) { }

  ngOnInit(): void {
  }

  close(agreed = false): void {
    this.store.dispatch(new Legal.UpdateLegalAgreement(agreed)).subscribe(() => {
      this.dialogRef.close(agreed);
    });
  }

}
