import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Store} from '@ngxs/store';
import {StripeService} from 'ngx-stripe';
import {VehiclePurchase} from 'top-car-ngxs';
import {PaymentIntentCustom, VehiclePurchaseInterface} from 'top-car-interfaces';
import {environment} from 'src/environments/environment';
import {VehiclePurchaseStatuses} from 'top-car-interfaces';
import {TopCarApiService} from 'top-car-api';

@Component({
  selector: 'app-pay-for-vehicle-invoice-modal',
  templateUrl: './pay-for-vehicle-invoice-modal.component.html',
  styleUrls: ['./pay-for-vehicle-invoice-modal.component.scss']
})
export class PayForVehicleInvoiceModalComponent implements OnInit, OnDestroy {
  interval: any;
  loading = false;

  public prices = {
    'Topup': null,
    'Silver car report': 3.99,
    'Gold car report': 12.99
  };

  public stripeInstance = this.stripeService.stripe;
  public paymentIntent!: PaymentIntentCustom;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: VehiclePurchaseInterface,
    public router: Router,
    private api: TopCarApiService,
    private stripeService: StripeService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.api.invoiceVehiclePayment(this.data.id).subscribe(response => {
      this.paymentIntent = response;
      this.stripeInstance = this.stripeService.changeKey(environment.stripeAPI.key, {
        stripeAccount: response.metadata.ConnectedAccountId
      });
    })
  }

  ngOnDestroy(): void {
    // clearInterval(this.interval);
  }

  viewReport(): void {
    this.interval = setInterval(() => {
      this.api.getIndividualVehiclePayment(this.data.id).subscribe(purchase => {
        if (purchase && purchase.status === VehiclePurchaseStatuses.COMPLETE) {
          this.dialogRef.close();
          this.store.dispatch(new VehiclePurchase.Get());
          clearInterval(this.interval);
        }
      });
    }, 5000 );
  }

}
