<div class="font-secondary">
  <button mat-icon-button *ngFor="let ratingId of ratingArr;index as i" (click)="readOnly ? null : onClick(i+1)" [matTooltip]="readOnly ? null : (ratingId+1)" matTooltipPosition="above" class="rating__star" [class.active]="i + 1 <= rating">
    <mat-icon class="rating__star-icon">
      {{showIcon(i)}}
    </mat-icon>
  </button>

  <span class="u-color-black-50 rating__text" *ngIf="totalReviews >= 0">
    ({{totalReviews}} reviews)
  </span>
</div>
<!--
<mat-error *ngIf="starCount == null || starCount == 0">
  Star count is <strong>required</strong> and cannot be zero
</mat-error>
-->
