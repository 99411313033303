import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ViewportScroller} from '@angular/common';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {NgxAuthService} from 'ngx-auth';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {
  isAuthenticated$: Observable<boolean> = this.auth.isAuthenticated$.asObservable();

  constructor(
    private viewportScroller: ViewportScroller,
    private router: Router,
    private auth: NgxAuthService
  ) { }

  ngOnInit(): void {
  }

  checkScroll(): void {
    if (this.router.url === '/#Blog') {
      this.viewportScroller.setOffset([0, 200]);
      this.viewportScroller.scrollToAnchor('Blog');
      this.viewportScroller.setOffset([0, 0]);
    }
  }

}
