
<div class="u-position--relative">
  <div class="u-text-right">
    <button mat-icon-button class="modal__close" (click)="dialogRef.close()"><mat-icon>close</mat-icon></button>
  </div>
  <mat-dialog-content class="pay-for-car-report-modal__content font-secondary u-text-center" >
    <div class="loading u-border-radius-12" *ngIf="loading">
      <mat-spinner class="loading__spinner"></mat-spinner>
    </div>

    <img class="pay-for-car-report-modal__logo" src="/assets/images/core/logo.png">

    <h1 class="font-secondary u-text-bold">{{data.type == 1 ? 'Silver' : 'Gold'}} report agreement</h1>

    <p class="u-width-75 u-margin-x-auto u-block">
      {{data.type == 1 ? 'Silver' : 'Gold'}} report costs {{(data.type == 1 ? 3.99 : 12.99) | price}}. To get a report you have to use card form below.
    </p>

    <p>
      <b>Reports are powered by</b>
    </p>

    <a href="https://totalcarcheck.co.uk/" target="_blank" rel="nofollow">
      <img class="u-border-radius-12 pay-for-car-report-modal__total-car-check" src="/assets/images/third-parties/total-car-check.jpg">
    </a>


    <p *ngIf="data.type === 2">
      Gold report's finance data is powered by <a href="https://www.experian.co.uk/business/automotive" target="_blank" rel="nofollow">Experian</a>
    </p>

    <app-payment-form [stripeInstance]="stripeInstance" [paymentIntent]="paymentIntent" paymentButtonText="Pay for report" checkbox="I accept <a href='https://totalcarcheck.co.uk/TermsAndConditions' target='_blank'>TotalCarCheck's terms & conditions</a>" (paymentProcessed)="viewReport()" (loading)="loading = $event"></app-payment-form>
  </mat-dialog-content>
</div>

