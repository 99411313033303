<app-modal-wrapper [loading]="loading" [completed]="completed" [dialogRef]="dialogRef" [title]="(data.id ? 'Edit' : 'Create') + ' appointment'">
  <form [formGroup]="formGroup" (ngSubmit)="sendRequest()">
    <app-input-alternative label="Address" [readonly]="true" formControlName="address"></app-input-alternative>

    <app-calendar class="u-block u-margin-top-16" formControlName="date"></app-calendar>
    <!--
    <app-datetime-input label="Date" formControlName="date"></app-datetime-input>
    <app-time-input label="Time" formControlName="time"></app-time-input>
    -->
    <app-checkbox label="Include test drive" formControlName="testDrive" class="u-text-center u-block u-margin-top-16"></app-checkbox>

    <div class="u-text-center modal__submit-container">
      <button mat-flat-button color="primary" [disabled]="formGroup.invalid">Send appointment request</button>
    </div>
  </form>
</app-modal-wrapper>
