<mat-card class="card">
  <mat-card-header>
    <mat-card-title>
      Review by {{review.reviewerName}}
    </mat-card-title>
    <app-rating color="warn" [starCount]="5" [rating]="review.rating" [readOnly]="true" [totalReviews]="0"></app-rating>
  </mat-card-header>
  <mat-card-content>
    <p class="review__text">{{review?.content}}</p>
  </mat-card-content>
</mat-card>
