<div #select class="search-input u-flex u-flex-align-center u-flex-justify-between font-secondary {{label | replaceString}}"
     [class.search-input--disabled]="control.disabled"
     [class.search-input--invalid]="formHelper.isInvalid(control)"
     [class.search-input--white]="isWhite"
>
  <button type="button" *ngIf="prefixIcon" mat-icon-button matPrefix [matTooltip]="prefixTooltip" (click)="prefixClicked.emit(true)" [class.u-color-success]="prefixColor === 'success'">
    <mat-icon>{{prefixIcon}}</mat-icon>
  </button>

  <input class="font-secondary" [placeholder]="label" [type]="type" [readonly]="readonly" [formControl]="control" (click)="openOverlay()">

</div>
