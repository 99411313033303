<mat-card class="u-width-100 mat-elevation-z0" (outsideClick)="close()" [exclude]="params.label" [ref]="{nativeElement: scrollContainerElement?.nativeElement}" [enableStopScroller]="hovered" appStopScrollPropogation (mouseenter)="hovered = true" (mouseleave)="hovered = false">

  <mat-card-content>

    <div class="scroll-content" #scrollContainer>
      <mat-list *ngIf="params.options | filterByName:(value$ | async):true as brands">
        <mat-list-item *ngFor="let brand of brands; trackBy: trackBy" (click)="select(brand)">
          <img class="image" [src]="brand.imagePath | url:domain"> <span>{{brand.name}}</span>
        </mat-list-item>
        <mat-list-item *ngIf="!brands || brands.length === 0">
          <span>No brands found</span>
        </mat-list-item>
      </mat-list>
    </div>

  </mat-card-content>

</mat-card>
