<a class="link-unstyled u-color-black compare-card" routerLink="/compare/cars">

  <mat-card class="mat-elevation-z0 u-border-radius-12 compare-card__card font-secondary">
      <mat-card-content class="u-flex compare-card__content">
        <div class="compare-card__left u-position--relative">
          <mat-icon>playlist_add_check</mat-icon>
          <span class="badge badge--danger" *ngIf="totalCompared$ | async as totalCompared">{{totalCompared}}</span>
        </div>
        <div class="compare-card__right">
          <span class="compare-card__title font-secondary">Compared {{totalCompared$ | async}} cars</span>
          <br>
          <span class="compare-card__link font-primary">View</span>
          <button appStopPropagation mat-button class="compare-card__link compare-card__link--danger" (click)="reset()">Reset</button>
        </div>
      </mat-card-content>

  </mat-card>
</a>
