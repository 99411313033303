import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';

import {CardComponent} from './card/card.component';
import {RouterModule} from '@angular/router';
import {LoaderComponent} from './loader/loader.component';
import {PaginationComponent} from './pagination/pagination.component';
import {RatingComponent} from './rating/rating.component';
import {PriceIndicatorComponent} from './price-indicator/price-indicator.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {ExtraPageLayoutComponent} from './extra-page-layout/extra-page-layout.component';
import {AvatarComponent} from './avatar/avatar.component';
import {BreadcrumbsComponent} from './breadcrumbs/breadcrumbs.component';
import {ReviewComponent} from './review/review.component';
import {InfoCardComponent} from '@kit/common-kit/info-card/info-card.component';
import {PhoneNumberComponent} from './phone-number/phone-number.component';
import {CountdownTimerComponent} from '@kit/common-kit/countdown-timer/countdown-timer.component';
import {ApplicationStatusComponent} from '@kit/common-kit/application-status/application-status.component';
import {ScrollContentComponent} from './scroll-content/scroll-content.component';
import {SharedModule} from '../../modules/shared/shared.module';

@NgModule({
  declarations: [
    CardComponent,
    LoaderComponent,
    PaginationComponent,
    RatingComponent,
    PriceIndicatorComponent,
    ExtraPageLayoutComponent,
    AvatarComponent,
    BreadcrumbsComponent,
    ReviewComponent,
    InfoCardComponent,
    PhoneNumberComponent,
    CountdownTimerComponent,
    ApplicationStatusComponent,
    ScrollContentComponent
  ],
  imports: [
    CommonModule,
    RouterModule,

    SharedModule,

    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatListModule,
    MatDividerModule
  ],
  exports: [
    RouterModule,

    CardComponent,
    LoaderComponent,
    PaginationComponent,
    RatingComponent,
    PriceIndicatorComponent,
    ExtraPageLayoutComponent,
    AvatarComponent,
    BreadcrumbsComponent,
    ReviewComponent,
    InfoCardComponent,
    PhoneNumberComponent,
    CountdownTimerComponent,
    ApplicationStatusComponent,
    ScrollContentComponent,

    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatListModule,
    MatDividerModule,
  ]
})
export class CommonKitModule { }
