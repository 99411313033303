import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppointmentModalComponent} from './appointment-modal/appointment-modal.component';
import {CookiesModalComponent} from './cookies-modal/cookies-modal.component';
import {MatDialogModule} from '@angular/material/dialog';
import {FormsKitModule} from '../forms-kit/forms-kit.module';
import {CommonKitModule} from '../common-kit/common-kit.module';
import {ConfirmActionModalComponent} from './confirm-action-modal/confirm-action-modal.component';
import {LoadingModalComponent} from './loading-modal/loading-modal.component';
import {ChangePasswordModalComponent} from './change-password-modal/change-password-modal.component';
import {ContactViaEmailModalComponent} from './contact-via-email-modal/contact-via-email-modal.component';
import {SuccessModalComponent} from './success-modal/success-modal.component';
import {TransactionModalComponent} from './transaction-modal/transaction-modal.component';
import {PayForCarReportModalComponent} from './pay-for-car-report-modal/pay-for-car-report-modal.component';
import {StripeKitModule} from '../stripe-kit/stripe-kit.module';
import {WarningModalComponent} from './warning-modal/warning-modal.component';
import {PayForVehicleInvoiceModalComponent} from './pay-for-vehicle-invoice-modal/pay-for-vehicle-invoice-modal.component';
import {ViewFinanceModalComponent} from './view-finance-modal/view-finance-modal.component';
import {ViewPartExchangeModalComponent} from './view-part-exchange-modal/view-part-exchange-modal.component';
import {UserInfoModalComponent} from './user-info-modal/user-info-modal.component';
import {DeliveryInformationModalComponent} from './delivery-information-modal/delivery-information-modal.component';
import {EditVehiclePaymentModalComponent} from './edit-vehicle-payment-modal/edit-vehicle-payment-modal.component';
import {ModalWrapperComponent} from './kit/modal-wrapper/modal-wrapper.component';
import {SharedModule} from '../../modules/shared/shared.module';
import {NgxAuthModule} from 'ngx-auth';


@NgModule({
  declarations: [
    AppointmentModalComponent,
    CookiesModalComponent,
    ConfirmActionModalComponent,
    LoadingModalComponent,
    ChangePasswordModalComponent,
    ContactViaEmailModalComponent,
    SuccessModalComponent,
    TransactionModalComponent,
    PayForCarReportModalComponent,
    WarningModalComponent,
    PayForVehicleInvoiceModalComponent,
    ViewFinanceModalComponent,
    ViewPartExchangeModalComponent,
    UserInfoModalComponent,
    DeliveryInformationModalComponent,
    EditVehiclePaymentModalComponent,
    ModalWrapperComponent
  ],
  imports: [
    CommonModule,
    FormsKitModule,
    SharedModule,
    CommonKitModule,
    MatDialogModule,
    StripeKitModule,
    NgxAuthModule
  ],
})
export class ModalKitModule { }

