import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {FullScreenLayoutComponent} from './layout/full-screen-layout.component';
import {RouterModule} from '@angular/router';


@NgModule({
  declarations: [FullScreenLayoutComponent],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    FullScreenLayoutComponent
  ]
})
export class FullScreenLayoutModule { }
