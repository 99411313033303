import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-checkbox-alternative',
  templateUrl: './checkbox-alternative.component.html',
  styleUrls: ['./checkbox-alternative.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxAlternativeComponent),
      multi: true
    }
  ]
})
export class CheckboxAlternativeComponent implements ControlValueAccessor {
  @Input() ngModel: string;
  @Input() color: string;

  @Output() ngModelChange = new EventEmitter();

  readonly url = environment.API;

  constructor() { }

  selectionChanged(event: any): void {
    // this.selectionChange.emit(new MatSelectChange(this.matSelect, event.value));
    // this.valueChange.emit(event.value);
    this.onChange(event.value);
    this.onTouched();
  }

  // ControlValueAccessor Implementation
  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    // this.value = value;
  }

  setDisabledState(isDisabled: boolean): void {
    // this.disabled = isDisabled;
  }
}
