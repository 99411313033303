import {Component, Input, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-modal-wrapper',
  templateUrl: './modal-wrapper.component.html',
  styleUrls: ['./modal-wrapper.component.scss']
})
export class ModalWrapperComponent implements OnInit {
  @Input() loading?: boolean;
  @Input() error?: string;
  @Input() completed?: boolean;
  @Input() title?: string;
  @Input() dialogRef: MatDialogRef<any>;

  constructor() { }

  ngOnInit(): void {
  }

}
