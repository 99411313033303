import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { RefundModel } from 'top-car-interfaces';
import {PaymentIntentDatum} from 'top-car-interfaces';
import {CommonUtilService} from 'top-car-utils';

@Component({
  selector: 'app-transaction-modal',
  templateUrl: './transaction-modal.component.html',
  styleUrls: ['./transaction-modal.component.scss']
})
export class TransactionModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: PaymentIntentDatum | RefundModel,
    public utils: CommonUtilService
  ) { }

  ngOnInit(): void {
  }

}
