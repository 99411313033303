import {MapsAPILoader} from '@agm/core';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {from, Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {MultiplePostcodeResponse} from 'top-car-interfaces';

declare var google: any;

interface LatLng {
  lat: number;
  lng: number;
}

@Injectable({
  providedIn: 'root'
})
export class GeocoderService {
  private geocoder: any;

  savedPostcodes: any[] = [];
  rejectedPostcodes: any[] = [];

  constructor(
    private http: HttpClient,
    private mapLoader: MapsAPILoader
  ) {
  }

  private initGeocoder(): void {
    console.log('Init geocoder!');
    this.geocoder = new google.maps.Geocoder();
  }

  private waitForMapsToLoad(): Observable<boolean> {
    if (!this.geocoder) {
      return from(this.mapLoader.load())
      .pipe(
        tap(() => this.initGeocoder()),
        map(() => true)
      );
    }
    return of(true);
  }


  public addressToLatAndLng(address: string): Promise<LatLng> {
    return new Promise(async (resolve, reject) => {
      if (!address) {
        return reject('No address');
      }
      if (this.savedPostcodes.some(e => e.address === address)) {
        return resolve(this.savedPostcodes.find(e => e.address === address));
      }
      if (this.rejectedPostcodes.some(e => e === address)) {
        return reject('Invalid postcode');
      }
      this.waitForMapsToLoad().subscribe(() => {
        this.geocoder.geocode({address: address + ', UK'}, (results: any, status: number) => {
          if (status == google.maps.GeocoderStatus.OK) {
            console.log('Geocoding complete!');
            this.savedPostcodes = [
              ...this.savedPostcodes,
              {
                address, lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng()
              }
            ];
            resolve({
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng()
            });
          } else {
              console.log('Error - ', results, ' & Status - ', status);
              this.rejectedPostcodes = [
                ...this.rejectedPostcodes,
                address
              ];
              reject(status);
          }
        });
      });
    });
  }

  public getCoordinatesFromPostcodes(postcodes: string[]): Promise<MultiplePostcodeResponse> {
    return new Promise(async (resolve) => {
      let i: number;
      let j: number;
      let postcodesChunk: any[];
      const results: MultiplePostcodeResponse = {
          status: 200,
          result: []
      };
      const chunk = 100;
      for (i = 0, j = postcodes.length; i < j; i += chunk) {
        postcodesChunk = postcodes.slice(i, i + chunk);
        const response = await this.http.post<MultiplePostcodeResponse>('https://api.postcodes.io/postcodes', {
          postcodes: postcodesChunk
        }).toPromise();
        results.result.push(...response.result);
      }

      resolve(results);
    });
  }

}
