import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-password-validator-block',
  templateUrl: './password-validator-block.component.html',
  styleUrls: ['./password-validator-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordValidatorBlockComponent implements OnInit {
  @Input() password: string;

  constructor() { }

  ngOnInit(): void {
  }

  get containsLowerCase(): boolean {
    if (!this.password) { return false; }
    return /[a-z]/.test(this.password);
  }

  get containsUpperCase(): boolean {
    if (!this.password) { return false; }
    return /[A-Z]/.test(this.password);
  }

  get containsSpecialCharacter(): boolean {
    if (!this.password) { return false; }
    return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(this.password);
  }

  get containsNumber(): boolean {
    return new RegExp('[0-9]').test(this.password);
  }

  get isLongerThan8(): boolean {
    if (!this.password) { return false; }
    return this.password.length >= 8;
  }

}
