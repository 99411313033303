<div class="textarea-alternative u-flex u-flex-align-center u-flex-justify-between font-secondary"
  [class.textarea-alternative--value]="control.value"
  [class.textarea-alternative--disabled]="control.disabled || disable"
  [class.textarea-alternative--invalid]="isTouched && formHelper.isInvalid(control, disable)"
>
  <textarea class="font-secondary u-width-100" [placeholder]="label" [readonly]="readonly" [formControl]="control"></textarea>
</div>

<ul *ngIf="isTouched && formHelper.isInvalid(control, disable) && !disableErrors" class="u-list-unstyled">
  <li *ngIf="control.hasError('required')">
    {{label}} is required
  </li>
</ul>
