<div class="double-select-alternative u-flex u-flex-align-center u-flex-justify-between" [class.disabled]="formGroupParam.disabled">
  <div
    #minSide
    class="double-select-alternative__left u-flex u-flex-align-center u-flex-justify-between"
    [class.hasValue]="(selectedValues.min$ | async) !== (label + ' min')"
    [class.double-select-alternative--invalid]="formHelper.isInvalid(formGroupParam.get('min'))"
  >
    <button
      type="button"
      class="select-trigger font-secondary u-text-overflow-0-container outsideClick--exclude {{(label | replaceString) + 'min'}}"
      (click)="toggleOverlay('min')"
      [class.focused]="focused.min$ | async"
      [disabled]="formGroupParam.get('min').disabled"
    >
      <span class="u-text-overflow-0 u-block">{{selectedValues.min$ | async}}</span>
    </button>
    <button type="button" class="u-margin-auto double-select-alternative__toggle outsideClick--exclude toggle {{(label | replaceString) + 'min'}}" (click)="toggleOverlay('min')" [class.focused]="focused.min$ | async" [disabled]="formGroupParam.get('min').disabled" [class.toggle--opened]="opened.min$ | async">
      <mat-icon>
        expand_more
      </mat-icon>
    </button>
  </div>
  <div
    #maxSide
    class="double-select-alternative__right u-flex u-flex-align-center u-flex-justify-between"
    [class.hasValue]="(selectedValues.max$ | async) !== 'Max'"
    [class.double-select-alternative--invalid]="formHelper.isInvalid(formGroupParam.get('max'))"
  >
    <button
      type="button"
      class="select-trigger font-secondary u-text-overflow-0-container {{(label | replaceString) + 'max'}}"
      (click)="toggleOverlay('max')"
      [class.focused]="focused.max$ | async"
      [disabled]="formGroupParam.get('max').disabled"
    >
      <span class="u-text-overflow-0 u-block">{{selectedValues.max$ | async}}</span>
    </button>
    <button type="button" class="u-margin-auto double-select-alternative__toggle toggle {{(label | replaceString) + 'max'}}" (click)="toggleOverlay('max')" [class.focused]="focused.max$ | async" [disabled]="formGroupParam.get('max').disabled" [class.toggle--opened]="opened.max$ | async">
      <mat-icon>
        expand_more
      </mat-icon>
    </button>
  </div>
</div>
