<header>
  <mat-toolbar class="header">
    <mat-toolbar-row class="wrapper u-padding-x-0 u-flex u-flex-align-center u-flex-justify-between header__toolbar">
      <a routerLink="/cars/home" (click)="logoClicked();" class="font-tertiary link-unstyled u-block u-color-black u-text-italic u-text-extra-bold header__logo">
        <img src="/assets/images/core/logo.png" alt="TopCar logo" class="header__logo-image">
      </a>

      <nav class="font-secondary header__nav">
        <a class="u-color-black-50 u-margin-x-16 link-unstyled header__link u-cursor--pointer" routerLink="/cars/search" routerLinkActive="header__link--active">Cars</a>

        <!--[matMenuTriggerFor]="menu"-->
        <!--<a class="u-color-black-50 u-margin-x-16 link-unstyled header__link" routerLink="/comming-soon">Motorcycles</a>
        <a class="u-color-black-50 u-margin-x-16 link-unstyled header__link" routerLink="/comming-soon">Tyres</a>
        <a class="u-color-black-50 u-margin-x-16 link-unstyled header__link" routerLink="/comming-soon">Disks</a>-->
      </nav>
      <div class="header__right font-secondary">

        <ng-container [ngSwitch]="isAuthenticated$ | async">
          <ng-container *ngSwitchCase="true">
            <a class="u-color-black-50 u-margin-x-16 link-unstyled u-flex u-flex-align-center header__link header__link--no-after" routerLink="/profile/saved-cars">
              <mat-icon class="header__heart">
                favorite_border
              </mat-icon>
              Liked {{totalSavedCars$ | async}} cars
            </a>
            <a class="u-color-black-50 u-margin-x-16 link-unstyled header__link u-flex u-flex-align-center u-flex-justify-between header__link--no-after" [routerLink]="['/profile', (userData$ | async)?.type === 2 ? 'wallet' : 'settings']">
              <app-avatar class="header__avatar" [photoUrl]="(userData$ | async)?.imagePath | url:domain" [name]="(userData$ | async)?.fullName" [unread]="totalNotifications$ | async"></app-avatar>
              My account
            </a>
          </ng-container>
          <ng-container *ngSwitchCase="false">
            <a class="u-color-black-50 u-margin-x-16 link-unstyled header__link" routerLink="/auth/login">Login</a>
            <a class="u-color-black-50 u-margin-x-16 link-unstyled header__link" routerLink="/auth/registration/user">Register</a>
          </ng-container>
        </ng-container>
        <button mat-flat-button color="primary" class="font-primary u-margin-x-16 header__button" (click)="openRightButton()">+ {{type === 'reverse-buy' ? 'Request' : 'Sell your'}} car</button>

      </div>

      <button mat-icon-button (click)="hideMobile = !hideMobile" class="header__mobile-menu">
        <mat-icon>
          menu
        </mat-icon>
      </button>
    </mat-toolbar-row>
    <mat-toolbar-row class="header__mobile font-secondary" *ngIf="!hideMobile">
        <a class="u-color-black-50 u-margin-x-16 link-unstyled header__link u-cursor--pointer" [matMenuTriggerFor]="menu">Cars</a>

        <!--
        <a class="u-color-black-50 u-margin-x-16 link-unstyled header__link" routerLink="/comming-soon">Motorcycles</a>
        <a class="u-color-black-50 u-margin-x-16 link-unstyled header__link" routerLink="/comming-soon">Disks</a>
        <a class="u-color-black-50 u-margin-x-16 link-unstyled header__link" routerLink="/comming-soon">Tyres</a>
        -->

        <ng-container [ngSwitch]="(isAuthenticated$ | async)">
          <ng-container *ngSwitchCase="true">
            <a class="u-color-black-50 u-margin-x-16 link-unstyled header__link" routerLink="/profile/saved-cars">Liked cars</a>
            <a class="u-color-black-50 u-margin-x-16 link-unstyled header__link" routerLink="/profile">Profile</a>

          </ng-container>
          <ng-container *ngSwitchCase="false">
            <a class="u-color-black-50 u-margin-x-16 link-unstyled header__link" routerLink="/auth/login">Login</a>
            <a class="u-color-black-50 u-margin-x-16 link-unstyled header__link" routerLink="/auth/registration/user">Register</a>
            </ng-container>
        </ng-container>

        <button mat-flat-button color="primary" class="font-primary u-margin-x-16 header__button" [routerLink]="rightButtonRoute">+ {{type === 'reverse-buy' ? 'Reverse buy' : 'Sell your'}} car</button>

        <!--<button mat-flat-button color="primary" class="font-primary u-margin-x-16 header__button" routerLink="/reverse-buy/car/add/1">+ Reverse buy a car</button>-->

    </mat-toolbar-row>
  </mat-toolbar>


</header>
<mat-menu #menu="matMenu">
  <button mat-menu-item routerLink="/car/search/1" (click)="carService.reset()">Buy</button>
  <button mat-menu-item routerLink="/reverse-buy/car/search/1" (click)="reverseService.reset()">Request</button>
</mat-menu>
