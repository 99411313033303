import {animate, style, transition, trigger} from '@angular/animations';

export const OpacityEnterAndLeave = [
  trigger(
    'opacityEnterAndLeave',
    [
      transition(
        ':enter',
        [
          style({ opacity: 0 }),
          animate('0.28s ease-out',
                  style({ opacity: 1 }))
        ]
      ),
      transition(
        ':leave',
        [
          style({ opacity: 1 }),
          animate('0.28s ease-in',
                  style({ opacity: 0 }))
        ]
      )
    ]
  )
];
