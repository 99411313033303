import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {Compare} from 'top-car-ngxs';
import {CompareState} from 'top-car-ngxs';

@Component({
  selector: 'app-compare-card',
  templateUrl: './compare-card.component.html',
  styleUrls: ['./compare-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompareCardComponent implements OnInit, OnDestroy {
  @Select(CompareState.totalComparedCars) totalCompared$!: Observable<number>;

  constructor(private store: Store) { }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  reset = () => {
    this.store.dispatch(new Compare.ResetCars());
  }

}
