import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {MainLayoutComponent} from './layout/main-layout.component';
import {SharedModule} from '../../modules/shared/shared.module';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import {RouterModule} from '@angular/router';
import {CommonKitModule} from '@kit/common-kit/common-kit.module';
import {ChatComponent} from './kit/chat/chat.component';
import {CompareCardComponent} from './kit/compare-card/compare-card.component';
import {FooterComponent} from './kit/footer/footer.component';
import {HeaderComponent} from './kit/header/header.component';
import {ScrollTopButtonComponent} from './kit/scroll-top-button/scroll-top-button.component';
import {FormsKitModule} from '@kit/forms-kit/forms-kit.module';


@NgModule({
  declarations: [
    MainLayoutComponent,
    ChatComponent,
    CompareCardComponent,
    FooterComponent,
    HeaderComponent,
    ScrollTopButtonComponent
  ],
  imports: [
    CommonModule,
    CommonKitModule,
    SharedModule,
    RouterModule,
    MatToolbarModule,
    MatMenuModule,
    FormsKitModule
  ],
  exports: [
    MainLayoutComponent,
  ]
})
export class MainLayoutModule { }
