<ng-container *ngIf="{
  isOpenned: isOpenned$ | async,
  currentChat: currentChat$ | async,
  totalUnread: totalUnread$ | async,
  chats: chats$ | async,
  messages: (messages$ | async) || [],
  chatBuddyInfo: chatBuddyInfo$ | async,
  currentAuto: currentAuto$?.data$ | async
} as data">

  <mat-card appStopScrollPropogation [ref]="currentChatScroll || chatListScroll" [enableStopScroller]="hovered" (mouseover)="mouseover()" (mouseleave)="mouseleave()" class="chat u-padding-0" [class.chat--openned]="data.isOpenned" [class.chat--closed]="!data.isOpenned">
    <mat-card-header class="chat__header u-flex u-flex-align-center u-flex-justify-between" (click)="toggleChat()">
      <div class="chat__header-left u-flex u-flex-align-center">
        <mat-icon class="chat__status" *ngIf="data.currentChat" [class.u-color-success]="data.currentChat.online"
          [class.u-color-danger]="!data.currentChat.online">fiber_manual_record</mat-icon>
        <span class="u-margin-bottom-0 chat__title font-secondary u-text-bold u-color-white"
          [class.chat__title--chat-openned]="data.currentChat">
          {{!data.chatBuddyInfo ? 'All chats' : data.chatBuddyInfo?.name}}
        </span>
        <span class="chat__unread u-color-white"
          *ngIf="!data.currentChat ? data.totalUnread > 0 : data.currentChat.unread > 0">{{!data.currentChat ? data.totalUnread : data.currentChat.unread}}</span>
      </div>

      <div class="chat__header-right">
        <button appStopPropagation mat-icon-button class="u-color-white" *ngIf="data.currentChat" (click)="goToList()">
          <mat-icon>arrow_left</mat-icon>
        </button>
        <button mat-icon-button class="u-color-white">
          <mat-icon>{{data.isOpenned ? 'remove' : 'add'}}</mat-icon>
        </button>
      </div>
    </mat-card-header>
    <mat-card-content class="chat__content font-secondary u-position--relative">

      <ng-container *ngTemplateOutlet="data.currentChat ? chatOpennedView : chatListView"></ng-container>

      <ng-container *ngTemplateOutlet="!(network.offline$ | async) && data.currentChat && data.currentChat.id !== 'New' && data.messages.length === 0 ? reconnecting : null">
      </ng-container>

      <ng-container *ngTemplateOutlet="!(network.offline$ | async) ? null : offline">
      </ng-container>

    </mat-card-content>
  </mat-card>

  <ng-template #chatOpennedView>
    <div class="chat__current" [class.chat__current--with-preview]="data.currentAuto" id="CurrentChatScroll" #currentChatScroll>
      <ng-container *ngFor="let message of data.messages; let i = index; trackBy: track">
        <span *ngIf="i === 0 || compareIfDifferentDay(data.messages[i - 1]?.dateTime, message.dateTime)"
          class="font-secondary chat__current-date">{{message?.dateTime | date:'mediumDate'}}</span>
          <ng-container
             [ngTemplateOutlet]="message.car ? car : null"
             [ngTemplateOutletContext]="{car: message.car?.data$ | async, error: message.car?.errorLoading$ | async, message: message}">
          </ng-container>

        <div class="chat__current_item" [class.chat__current_item--self]="message.fromUserId !== getChatUser(data.currentChat).id"
          [class.chat__current_item--other]="message.toUserId !== getChatUser(data.currentChat).id">
          <span>{{message.text}}</span>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="data.currentAuto">
      <a class="u-flex chat__preview link-unstyled" routerLink="/car/{{data.currentAuto.id}}">
        <div class="chat__preview--left">
          <img [src]="data.currentAuto.images[0].imagePathSmall | url:domain" class="u-border-radius-12">
        </div>
        <div class="chat__preview--other">
          <p class="u-margin-bottom-0 u-text-bold">{{data.currentAuto.name}}</p>
          <span>{{data.currentAuto.price | price}}</span>
        </div>
      </a>
    </ng-container>

    <form autocomplete="off" class="chat__form" [formGroup]="formGroup" (ngSubmit)="sendMessage()">

      <app-send-message-form placeholder="Type a message..." formControlName="message"></app-send-message-form>
    </form>
  </ng-template>

  <ng-template #chatListView>
    <div *ngIf="data.chats.length === 0"
    class="u-height-100 u-weight-100 u-flex u-flex-align-center u-flex-justify-center u-text-center">
      <span class="u-color-muted">No chats here. To start a chat view some adverts and click "Send message"</span>
    </div>
    <div class="chat__list-scroller" #chatListScroll>
      <mat-list class="chat__list" *ngIf="data.chats.length > 0">
        <mat-list-item class="chat__list_item" *ngFor="let chat of data.chats; trackBy: track" (click)="goToChat(chat)">
          <app-avatar matListAvatar [photoUrl]="null" [name]="getChatUser(chat)?.name" [online]="chat.online"></app-avatar>
          <h4 mat-line class="font-secondary chat__list_title u-text-bold">{{getChatUser(chat)?.name}}</h4>
          <p mat-line class="chat__list_last-message"> {{chat?.lastMessage?.text}} </p>
          <div class="u-text-right chat__list_right">
            <span class="chat__list_unread u-color-white" *ngIf="chat?.unread">{{chat.unread}}</span>
            <span class="chat__list_time">{{chat?.lastMessage?.dateTime | date}}</span>
          </div>
          <mat-divider [inset]="false"></mat-divider>
        </mat-list-item>
      </mat-list>
    </div>

  </ng-template>

  <ng-template #car let-car="car" let-error="error" let-message="message">
    <a class="u-flex chat__message--preview link-unstyled" routerLink="/car/{{car?.id}}">
      <div class="chat__current_item u-flex chat__current_item--car" [class.chat__current_item--self]="message.fromUserId !== getChatUser(data.currentChat).id"
        [class.chat__current_item--other]="message.toUserId !== getChatUser(data.currentChat).id">
        <ng-container
          [ngTemplateOutlet]="car ? carContent : carLoading"
          [ngTemplateOutletContext]="{car: car, error: error}">
        </ng-container>
      </div>
    </a>
  </ng-template>
</ng-container>

<ng-template #reconnecting>
  <div class="chat__reconnecting">
    <div>
      <mat-spinner></mat-spinner>
      <p>Loading...</p>
    </div>
  </div>
</ng-template>

<ng-template #offline>
  <div class="chat__disconnected">
    <div>
      <p>Your internet connection is unstable or network is down...</p>
    </div>
  </div>
</ng-template>

<ng-template #carContent let-car="car">
  <div class="chat__preview--left">
    <img [src]="car?.images ? (car?.images[0]?.imagePathSmall | url:domain) : undefined" class="u-border-radius-12">
  </div>
  <div class="chat__preview--other">
    <p class="u-margin-bottom-0 u-text-bold">{{car?.name}}</p>
    <span>{{car?.price | price}}</span>
  </div>
</ng-template>

<ng-template #carLoading let-error="error">
  <div class="u-flex u-flex-align-center u-flex-justify-center u-width-100 u-height-100 u-text-center">
    <mat-spinner *ngIf="!error" color="accent" diameter="48"></mat-spinner>
    <span class="u-color-white-50" *ngIf="error">Cannot load car. Either it was deleted or it does not exist</span>
  </div>
</ng-template>
