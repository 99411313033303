import {ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ControlContainer, ControlValueAccessor, FormControl, FormControlDirective, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FormHelperService} from 'top-car-formly';

@Component({
  selector: 'app-input-alternative',
  templateUrl: './input-alternative.component.html',
  styleUrls: ['./input-alternative.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputAlternativeComponent),
      multi: true
    },
  ]
})
export class InputAlternativeComponent implements OnInit, ControlValueAccessor {
  @ViewChild(FormControlDirective, {static: true})
  formControlDirective: FormControlDirective;
  @Input() public label?: string;
  @Input() formControlName?: string;
  @Input() type = 'text';
  @Input() readonly = false;
  @Input() disable = false;
  @Input() suffixIcon?: string;
  @Input() suffixTooltip?: string;
  @Input() suffixColor?: string;
  @Input() disableErrors?: boolean;
  @Input() submitted?: boolean;

  @Output() suffixClicked = new EventEmitter();

  constructor(
    private controlContainer: ControlContainer,
    public formHelper: FormHelperService
  ) { }

  get control(): FormControl {
    return this.controlContainer.control.get(this.formControlName) as FormControl;
  }

  public get isTouched() {
    return this.control.touched || this.control.dirty || this.submitted;
  }

  ngOnInit() {
  }

  // ControlValueAccessor Implementation
  onChange: any = () => { };
  onTouched: any = () => {
    this.control.markAsTouched();
  }

  registerOnChange(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnTouched(fn);
  }

  registerOnTouched(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnChange(fn);
  }

  writeValue(obj: any): void {
    this.formControlDirective.valueAccessor.writeValue(obj);
  }

  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
  }
}
