<div class="u-position--relative">
    <div class="u-text-right">
      <button mat-icon-button class="modal__close" (click)="dialogRef.close()"><mat-icon>close</mat-icon></button>
    </div>
    <mat-dialog-content class="top-up-modal__content font-secondary u-text-center" >
      <div class="loading u-border-radius-12" *ngIf="loading">
        <mat-spinner class="loading__spinner"></mat-spinner>
      </div>

      <img class="logo-modal" src="/assets/images/core/logo.png">

      <h1 class="font-secondary u-text-bold">Check vehicle request's information</h1>

      <form [formGroup]="formGroup" (ngSubmit)="edit()">
        <app-input-alternative
          type="number"
          label="Trade-in car price"
          formControlName="newPrice"
          *ngIf="userId !== data.buyerId"
          class="u-margin-top-16 u-block"
        ></app-input-alternative>
        <app-textarea-alternative
          *ngIf="
            (data.deliveryAddress && data.buyerId !== userId) ||
            data.buyerId === userId
          "
          [readonly]="data.buyerId !== userId"
          label="Address"
          formControlName="newDeliveryAddress"
          class="u-margin-top-16 u-block"
        ></app-textarea-alternative>

        <app-calendar
          class="u-margin-top-16 u-block"
          *ngIf="(data.deliveryAddress && data.buyerId !== userId) || data.buyerId === userId"
          label="Date"
          formControlName="newDate"
        ></app-calendar>

        <app-input-alternative
          class="u-margin-top-16 u-block"
          type="number"
          label="Delivery price"
          formControlName="newDeliveryPrice"
          *ngIf="data.deliveryAddress && userId !== data.buyerId"
        ></app-input-alternative>

        <button mat-flat-button color="primary" type="submit" [disabled]="formGroup.invalid">Edit</button>
      </form>

    </mat-dialog-content>
  </div>

