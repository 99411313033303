<div class="uploadfilecontainer u-position--relative" (click)="fileInput.click()" appDragAndDrop (onFileDropped)="uploadFile($event)">

  <div class="uploadfilecontainer__inner">
    <mat-icon>get_app</mat-icon>
    <br>
    <span class="uploadfilecontainer__big">Add photo</span>
    <br>
    <span class="uploadfilecontainer__small">Upload images, click or drag it to this area</span>

  </div>
  <input hidden multiple type="file" #fileInput (change)="uploadFile(fileInput.files)">
</div>
<h3 *ngIf="files && files.length > 0">Images to upload</h3>
<div cdkDropList [cdkDropListData]="files" class="files-list" (cdkDropListDropped)="drop($event,'new')">
  <div class="files-list__item u-position--relative" *ngFor="let file of files;let i= index" (click)="deleteAttachment(i)" cdkDrag>
    <mat-icon>close</mat-icon>
    <img class="image-fluid files-list__item-image" [src]="file | fileRead | async" alt="">
  </div>
</div>
<h3 *ngIf="uploadedImages && uploadedImages.length > 0">Uploaded images</h3>
<div cdkDropList [cdkDropListData]="uploadedImages" class="files-list" (cdkDropListDropped)="drop($event, 'uploaded')">
  <div class="files-list__item u-position--relative" *ngFor="let file of uploadedImages;let i= index" (click)="deleteUpploadedAttachment(i)" cdkDrag>
    <mat-icon>close</mat-icon>
    <img class="image-fluid files-list__item-image" [src]="file.imagePathSmall | url:domain" alt="">
  </div>
</div>
