<mat-card class="card mat-elevation-z0 u-padding-24" [style.boder-radius]="borderRadius">

  <mat-card-header class="card__header u-flex u-flex-align-center u-flex-justify-between" *ngIf="link || title">
    <mat-card-title *ngIf="title" class="u-text-bold font-secondary u-text-uppercase u-margin-bottom-0 card__title">{{title}}</mat-card-title>
    <a *ngIf="link" class="link--primary link-unstyled card__header-link u-flex u-flex-align-center u-margin-x-16" [routerLink]="link">
      <span>
        {{linkText}}
      </span>
      <mat-icon>
        arrow_right
      </mat-icon>
    </a>
  </mat-card-header>

  <mat-card-content class="u-flex card__content" [class.u-margin-x-16]="!noMarginForContent" [class.u-margin-x-0]="noMarginForContent">
    <ng-content></ng-content>
  </mat-card-content>
</mat-card>
