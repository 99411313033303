<ng-container *ngTemplateOutlet="(userData$ | async)?.id === user?.id ? null : (userData$ | async)?.role === 'admin' ? admin : standart"></ng-container>

<ng-template #standart>
  <div class="phone-number" [class.invert]="invert" *ngIf="phoneNumber$ | async as phoneNumber">
    <span class="phone-number__number">{{phoneNumber}}</span>
    <br>
    <small class="phone-number__separator">Use extension number:</small>
    <br>
    <div class="u-flex u-flex-align-items-center u-flex-justify-content-between">
      <div class="phone-number__extension-col" *ngIf="user?.phoneNumber">
        <a *ngIf="targetId | extensionNumber:action:secondaryAction(userType === 'branch' ? 3 : 1) as phoneNumberExtension" class="phone-number__extension u-link-unstyled" [href]="'tel:' + phoneNumber + '#' + phoneNumberExtension">
          {{user?.phoneNumber}}:
          <br>
          <b>{{phoneNumberExtension}}</b>
        </a>
      </div>
      <div class="phone-number__extension-col" *ngIf="user?.secondaryPhoneNumber && userType !== 'branch'">
        <a *ngIf="targetId | extensionNumber:action:secondaryAction(2) as phoneNumberExtension" class="phone-number__extension u-link-unstyled" [href]="'tel:' + phoneNumber + '#' + phoneNumberExtension">
          {{user?.secondaryPhoneNumber}}:
          <br>
          <b>{{phoneNumberExtension}}</b>
        </a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #admin>
  <div class="phone-number" [class.invert]="invert">
    <span class="phone-number__number">{{user.phoneNumber}}</span>
    <ng-container *ngIf="user.secondaryPhoneNumber">
      <br>
      <span class="phone-number__number">{{user.secondaryPhoneNumber}}</span>
    </ng-container>
  </div>
</ng-template>

