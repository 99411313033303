import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardComponent implements OnInit {
  @Input() title?: string;
  @Input() link?: any;
  @Input() linkText?: string;
  @Input() borderRadius = '12px';
  @Input() noMarginForContent = false;
  @Input() filterPadding = false;

  constructor() { }

  ngOnInit(): void {
  }

}
