import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {Appointments} from 'top-car-ngxs';
import {GeocoderService} from 'src/app/services/geocoder.service';
import * as moment from 'moment';

@Component({
  selector: 'app-appointment-modal',
  templateUrl: './appointment-modal.component.html',
  styleUrls: ['./appointment-modal.component.scss']
})
export class AppointmentModalComponent implements OnInit {

  formGroup = new FormGroup({
    address: new FormControl(null, [Validators.required]),
    date: new FormControl(new Date(), [Validators.required]),
    time: new FormControl(new Date(), [Validators.required]),
    testDrive: new FormControl(false)
  });

  loading = false;
  completed = false;
  error?: string;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private geocoder: GeocoderService,
    private store: Store
  ) { }

  ngOnInit(): void {
    if (this.data) {
      // this.formGroup.controls.address.disable();
      this.formGroup.controls.address.patchValue(this.data.address);
      if (this.data.id) {
        this.formGroup.controls.address.patchValue(this.data.address);
        const datetime = moment(this.data.datetime);

        this.formGroup.controls.date.patchValue(datetime);
        this.formGroup.controls.time.patchValue(datetime);
        this.formGroup.controls.testDrive.patchValue(this.data.testDrive);
      }
    }
  }

  async sendRequest(): Promise<void> {
    this.loading = true;
    const value = this.formGroup.value;
    const {lat, lng} = await this.geocoder.addressToLatAndLng(this.formGroup.controls.address.value);
    const datetime = value.date.toJSON();

    let observable: Observable<any>;

    if (this.data.id) {
      observable = this.store.dispatch(new Appointments.Edit(
        this.data.id,
        this.data.advertId,
        value.address,
        lat,
        lng,
        datetime,
        value.testDrive
      ));
    } else {
      observable = this.store.dispatch(new Appointments.New(
        value.address,
        this.data.advertId,
        datetime,
        value.testDrive,
        lat,
        lng,
      ));
    }

    observable.subscribe(response => {
      this.completed = true;
      setTimeout(() => {
        this.loading = false;
        this.dialogRef.close();
      }, 3000 );
    }, error => {
      console.log(error);
      this.error = 'Error encountered. Please, try again later';
      setTimeout(() => {
        this.loading = false;
        this.error = undefined;
      }, 3000 );
    });
  }

}
