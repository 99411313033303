import {Modal} from 'top-car-ngxs';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MessageHub} from 'top-car-ngxs';
import {Store} from '@ngxs/store';
import {Appointments} from 'top-car-ngxs';
import {UserInfoWithType} from 'top-car-interfaces';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-user-info-modal',
  templateUrl: './user-info-modal.component.html',
  styleUrls: ['./user-info-modal.component.scss']
})
export class UserInfoModalComponent implements OnInit {
  public readonly domain = environment.API;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UserInfoWithType,
    private store: Store,
    public dialogRef: MatDialogRef<any>,
  ) { }

  ngOnInit(): void {
  }

  startChat(): void {
    this.dialogRef.close();
    this.store.dispatch(new MessageHub.NewChat(this.data.id, this.data.fullName));
  }

  requestAppointment(): void {
    this.store.dispatch(new Appointments.OpenModal('new', this.data.car.id, this.data.car.address));
  }

  openContactViaEmail(): void {
    console.log(this.data);
    this.store.dispatch(new Modal.OpenContactViaEmail({userId: this.data.id}));
  }

}
