import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PaymentIntentCardComponent} from './payment-intent-card/payment-intent-card.component';
import {NgxStripeModule} from 'ngx-stripe';
import {FormsKitModule} from '../forms-kit/forms-kit.module';
import {GetCardTokenComponent} from './get-card-token/get-card-token.component';
import {CommonKitModule} from '../common-kit/common-kit.module';
import {PaymentFormComponent} from './payment-form/payment-form.component';
import {PaymentMethodComponent} from '@kit/stripe-kit/payment-method/payment-method.component';


@NgModule({
  declarations: [PaymentIntentCardComponent, GetCardTokenComponent, PaymentFormComponent, PaymentMethodComponent],
  imports: [
    CommonModule,
    FormsKitModule,
    NgxStripeModule,
    CommonKitModule
  ],
  exports: [PaymentIntentCardComponent, GetCardTokenComponent, PaymentFormComponent, NgxStripeModule, PaymentMethodComponent]
})
export class StripeKitModule { }
