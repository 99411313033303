import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filterByStartYear'
})
export class FilterByStartYearPipe implements PipeTransform {

  transform(data: any[], value: string, startsWith = false): any[] {
    if (!value) {
      return data;
    }
    if (startsWith) {
      return data.filter(item => item.startYear.toString().toUpperCase().startsWith(value.toUpperCase()))
    }
    return data.filter(e => `${e.name} ${e.startYear} ${e.endYear || 'Present'}`.toUpperCase().includes(value.toUpperCase()));

  }

}
