import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BuyCarReportParameters, PaymentMethod, SelectStandardOption} from 'top-car-interfaces';
import {CombineSubscriptions, DestroySubscribers} from 'top-car-decorators';
import {StripeCardElementOptions, StripeElementsOptions} from '@stripe/stripe-js';
import {StripeCardComponent, StripeService} from 'ngx-stripe';
import {Observable, Unsubscribable} from 'rxjs';
import {distinctUntilChanged, switchMap} from 'rxjs/operators';
import {TopCarApiService} from 'top-car-api';

@Component({
  selector: 'app-payment-intent-card',
  templateUrl: './payment-intent-card.component.html',
  styleUrls: ['./payment-intent-card.component.scss']
})
@DestroySubscribers()
export class PaymentIntentCardComponent implements OnInit, OnDestroy {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  @Input() type: string;
  @Input() options: BuyCarReportParameters;
  @Input() paymentButtonText = 'Pay';
  @Input() showTotalCheckbox = false;
  @Output() paymentProcessed = new EventEmitter();

  public prices = {
    'Topup': null,
    'Silver car report': 3.99,
    'Gold car report': 12.99
  };

  readonly typeOptions = [
    {
      name: 'Saved card',
      value: 'Saved'
    },
    {
      name: 'New card',
      value: 'New'
    }
  ];

  readonly cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: 'black',
        color: '#31325F',
        fontWeight: '400',
        fontFamily: '"Roboto", Helvetica, sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: 'black',
        },
      },
    },
  };

  readonly elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  readonly formGroup = new FormGroup({
    type: new FormControl('New'),
    name: new FormControl(null, [Validators.required]),
    card: new FormControl(null, [Validators.required]),
    amount: new FormControl(),
    remember: new FormControl(),
    agree: new FormControl()
  });

  public loading = false;
  public completed = false;
  public error: any;

  public cards: SelectStandardOption[];

  @CombineSubscriptions()
  private subscriber: Unsubscribable;

  constructor(
    private api: TopCarApiService,
    private stripeService: StripeService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.showTotalCheckbox) {
      this.formGroup.controls.agree.setValidators([Validators.requiredTrue]);
    }
    this.subscriber = this.api.getCards().subscribe(cards => {
      this.cards = cards.data.map<SelectStandardOption>(card => ({
        name: '**** **** **** ' + card.card.last4,
        value: card
      }));

      this.reset();
    });

    this.subscriber = this.formGroup.controls.type.valueChanges.pipe(
      distinctUntilChanged()
    ).subscribe(value => {
      this.reset(true, value);

      if (value === 'Saved') {
        this.formGroup.controls.card.setValidators([Validators.required]);
      } else if (value === 'New') {
        this.formGroup.controls.name.setValidators([Validators.required]);
      }

      this.formGroup.updateValueAndValidity();
      this.error = null;
      this.changeDetector.detectChanges();
    });
  }

  ngOnDestroy(): void {}

  reset(noTypeChanges = false, type?: string): void {
    this.formGroup.reset();
    if (this.card && this.card.element) {
      this.card.element.clear();
    }
    this.formGroup.controls.amount.clearValidators();
    this.formGroup.controls.card.clearValidators();
    this.formGroup.controls.name.clearValidators();

    if (this.type === 'Topup') {
      this.formGroup.controls.amount.setValidators([Validators.required, Validators.min(1)]);
    }
    if (this.cards.length > 0) {
      this.formGroup.controls.card.setValidators([Validators.required]);
      if (!noTypeChanges) {
        this.formGroup.controls.type.patchValue('Saved', {emitEvent: false});
      }
      this.formGroup.controls.card.patchValue(this.cards[0].value, {emitEvent: false});

    } else {
      if (!noTypeChanges) {
        this.formGroup.controls.type.patchValue('New', {emitEvent: false});
      }
      this.formGroup.controls.name.setValidators([Validators.required]);
    }

    if (type) {
      this.formGroup.controls.type.patchValue(type, {emitEvent: false});
    }

    this.formGroup.updateValueAndValidity();
    this.error = null;
  }

  pay(): void {
    this.loading = true;

    let observable: Observable<any>;

    if (this.type === 'topup') {
      observable = this.api.addFunds(this.formGroup.get('amount').value);
    } else if (this.type === 'Silver car report' || this.type === 'Gold car report') {
      observable = this.api.buyCarReport({
        amount: this.prices[this.type],
        ...this.options
      });
    }

    const card = this.formGroup.get('card').value as PaymentMethod;
    const type = this.formGroup.get('type').value as 'New' | 'Saved';
    const remember = this.formGroup.get('remember').value as boolean;

    observable.pipe(
      switchMap((pi) =>
        this.stripeService.confirmCardPayment(pi.client_secret, {
          payment_method: type === 'Saved' ? card.id : {
            card: this.card.element,
            billing_details: card ? {} : {
              name: this.formGroup.get('name').value,
            },
          },
          setup_future_usage: type === 'New' && remember ? 'on_session' : null
        })
      )
    ).subscribe((result) => {
      if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        console.log(result.error.message);
        this.loading = false;
        this.error = result.error.message;
      } else {
        // The payment has been processed!
        if (result.paymentIntent.status === 'succeeded') {
          // Show a success message to your customer
          this.completed = true;
          this.paymentProcessed.emit(true);
        }
      }
    });
  }
}
