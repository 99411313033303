<label for="imagePath" class="avatar-uploader" style="cursor: pointer;">
  <img class="image-fluid avatar-uploader__image" [src]="path$ | async | url:domain" alt="">
  <input #fileInput type="file" name="imagePath" id="imagePath" hidden accept="image/*" (change)="uploadFile(fileInput.files)">
</label>

<span class="u-color-primary avatar-uploader__notice" *ngIf="!control.value && !imagePath">
  To change avatar click on image above
</span>

<button type="button" mat-button color="danger" *ngIf="!deleteClicked && (control.value || imagePath)" (click)="deleteAvatar()">Delete avatar</button>

<br>
<span class="u-color-black-50 avatar-uploader__notice">

  Do not forget to save changes
</span>
