import {ChangeDetectionStrategy, Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {ControlContainer, ControlValueAccessor, FormControl, FormControlDirective, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FormHelperService} from 'top-car-formly';

@Component({
  selector: 'app-textarea-alternative',
  templateUrl: './textarea-alternative.component.html',
  styleUrls: ['./textarea-alternative.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaAlternativeComponent),
      multi: true
    },
  ]
})
export class TextareaAlternativeComponent implements OnInit, ControlValueAccessor {
  @ViewChild(FormControlDirective, {static: true})
  formControlDirective: FormControlDirective;
  @Input() public label?: string;
  @Input() formControlName?: string;
  @Input() readonly = false;
  @Input() disable = false;
  @Input() submitted = false;
  @Input() disableErrors = false;

  constructor(
    private controlContainer: ControlContainer,
    public formHelper: FormHelperService
  ) { }

  get control(): FormControl {
    return this.controlContainer.control.get(this.formControlName) as FormControl;
  }

  ngOnInit() {
  }

  public get isTouched() {
    return this.control.touched || this.control.dirty || this.submitted;
  }

  // ControlValueAccessor Implementation
  onChange: any = () => { };
  onTouched: any = () => {
    this.control.markAsTouched();
  }

  registerOnChange(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnTouched(fn);
  }

  registerOnTouched(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnChange(fn);
  }

  writeValue(obj: any): void {
    this.formControlDirective.valueAccessor.writeValue(obj);
  }

  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
  }
}
