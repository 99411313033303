import {Injectable} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs/internal/Observable';
import {debounceTime, filter, map, startWith, switchMap, tap} from 'rxjs/operators';
import {FormlyFieldConfig, FormlyTemplateOptions} from '@ngx-formly/core';
import {capitalize} from '@angular-ru/common/string';
import {Store} from '@ngxs/store';
import {TopCarApiService} from 'top-car-api';

@Injectable({
  providedIn: 'root'
})
export class ListHelperService {

  constructor(private api: TopCarApiService, private store: Store) { }

  public readonly generateHook = (controlName: string, apiName: string, hookName = 'onInit') => {
    return {
      [hookName]: (field?: FormlyFieldConfig) => {
        field.templateOptions.options = this.handleChange(field.form, controlName, 'api', apiName);
      }
    }
  };

  public readonly generateHookCustom = (callback: (field?: FormlyFieldConfig) => any, hookName = 'onInit') => {
    return {
      [hookName]: (field?: FormlyFieldConfig) => {
        callback(field);
      }
    }
  };

  public handleChange(
    form: FormGroup,
    key: string,
    type: 'api' | 'ngxs',
    functionName: any,
    callback?: () => any,
    dataCallback?: (options: any) => any,
    ngxsData?: any
  ): Observable<any[]> {
    let oldValue: any;
    // if (!form.value[key]) return [];
    return form.valueChanges.pipe(
      startWith(form.value),
      map(value => {
        const response = [oldValue, value[key]];
        oldValue = value[key];
        return response;
      }),
      filter(([oldId, newId]) => !!newId && oldId !== newId),
      debounceTime(200),
      switchMap(([oldId, newId]) => {
        if (oldId) callback && callback();

        if (type === 'api') {
          return this.api[functionName](undefined, undefined, undefined, newId).pipe(
            tap((value) => {
              dataCallback && dataCallback(value)
            })
          ) as Observable<any[]>;
        } else {
          return this.store.dispatch(new functionName()).pipe(
            switchMap(async () => this.store.selectSnapshot(ngxsData)),
            tap((value) => {
              dataCallback && dataCallback(value);
            })
          ) as Observable<any[]>
        }

      }),
      map(val => {
          return val.map(e => {
            return {
              ...e,
              options: e.options.map(option => ({
                ...option,
                model: {checkbox: false},
                form: new FormGroup({checkbox: new FormControl(false)}),
                fields: [
                  {
                    key: 'checkbox',
                    type: 'checkbox',
                  }
                ]
              }))
            }
          })
        })
    )
  }

  public generateFieldMinMaxSelect = (
    key: string,
    options: any,
    templateOptions: FormlyTemplateOptions,
    expressionProperties?: any
  ) => {
    return {
      className: 'u-flex-2',
      key: key,
      type: 'min-max-select-topcar',
      defaultValue: {min: null, max: null},
      wrappers: ['default'],
      templateOptions: {
        label: capitalize(key),
        enableSearch: false,
        selectType: 'options',
        resetText: 'Any',
        ...templateOptions
      },
      expressionProperties,
      ...options,
    }
  }

  public generateFieldSelect = (key: string, options: any, templateOptions: FormlyTemplateOptions, expressionProperties?: any) => {
    return {
      key: key + 'Id',
      type: 'select-topcar',
      wrappers: ['default'],
      templateOptions: {
        label: capitalize(key),
        placeholder: 'Select ' + key,
        ...templateOptions
      },
      expressionProperties,
      ...options,
    }
  }

  public generateFieldSearchInput = (key: string, options: any, templateOptions: FormlyTemplateOptions, expressionProperties?: any) => {
    return {
      key,
      type: 'search-input-topcar',
      wrappers: ['default'],
      templateOptions: {
        label: capitalize(key),
        placeholder: 'Enter ' + key,
        ...templateOptions
      },
      expressionProperties,
      ...options,
    }
  }

  public generateFieldTabs = (key: string, options: any, templateOptions: FormlyTemplateOptions, expressionProperties?: any) => {
    return {
      key,
      type: 'tabs-topcar',
      wrappers: ['default'],
      templateOptions: {
        label: capitalize(key),
        placeholder: 'Enter ' + key,
        ...templateOptions
      },
      expressionProperties,
      ...options,
    }
  }

  public generateFieldButtons = (key: string, options: any, templateOptions: FormlyTemplateOptions, expressionProperties?: any) => {
    return {
      key,
      type: 'buttons-topcar',
      wrappers: ['default'],
      templateOptions: {
        label: capitalize(key),
        placeholder: 'Enter ' + key,
        ...templateOptions
      },
      expressionProperties,
      ...options,
    }
  }

  public generateFieldInput = (key: string, options: any, templateOptions: FormlyTemplateOptions, expressionProperties?: any) => {
    return {
      key,
      type: 'input-topcar',
      wrappers: ['default'],
      templateOptions: {
        label: capitalize(key),
        placeholder: 'Enter ' + key,
        ...templateOptions
      },
      expressionProperties,
      ...options,
    }
  }
}
