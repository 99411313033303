import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngxs/store';
import {TopCarApiService} from 'top-car-api';
import {NgxAuthService} from 'ngx-auth';

@Component({
  selector: 'app-contact-via-email-modal',
  templateUrl: './contact-via-email-modal.component.html',
  styleUrls: ['./contact-via-email-modal.component.scss']
})
export class ContactViaEmailModalComponent implements OnInit {

  formGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
    fullName: new FormControl(null, [Validators.required]),
    message: new FormControl(null, [Validators.required]),
  });

  loading = false;
  completed = false;
  error?: string;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: {userId: string},
    private api: TopCarApiService,
    private store: Store,
    private auth: NgxAuthService
  ) { }

  ngOnInit(): void {
    if (this.auth.isAuthenticated) {
      const {email, fullName} = this.auth.userData;
      this.formGroup.controls.email.patchValue(email);
      this.formGroup.controls.fullName.patchValue(fullName);
    }
  }

  async sendRequest(): Promise<void> {
    this.loading = true;
    const value = this.formGroup.value;

    console.log(this.data);

    this.api.sendEmail(this.data.userId, value.email, value.fullName, value.message).subscribe(response => {
      console.log(response);
      this.completed = true;
      setTimeout(() => {
        this.loading = false;
        this.dialogRef.close();
      }, 3000 );
    }, error => {
      console.log(error);
      this.error = 'Error encountered. Please, try again later';
      setTimeout(() => {
        this.loading = false;
        this.error = undefined;
      }, 3000 );
    });

  }

}
