import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TopCarFormlyModule} from 'top-car-formly';
import {TopCarDirectivesModule} from 'top-car-directives';
import {TopCarPipesModule} from 'top-car-pipes';
import {TopCarUtilsModule} from 'top-car-utils';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TopCarFormlyModule,
    TopCarDirectivesModule,
    TopCarPipesModule,
    TopCarUtilsModule
  ],
  exports: [
    TopCarFormlyModule,
    TopCarDirectivesModule,
    TopCarPipesModule,
    TopCarUtilsModule,
  ]
})
export class SharedModule { }
