import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-price-indicator',
  templateUrl: './price-indicator.component.html',
  styleUrls: ['./price-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceIndicatorComponent implements OnInit {
  @Input() priceRating: 0 | 1 | 2 | 3 | 4 | 5;

  constructor() { }

  ngOnInit(): void {

  }

}
