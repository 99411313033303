<div
  #select
  class="select-alternative u-flex u-flex-align-center u-flex-justify-between"
  [class.select-alternative--value]="focused$ | async"
  [class.select-alternative--disabled]="disable"
  [class.select-alternative--invalid]="isTouched && formHelper.isInvalid(control, disable)"
>

  <button type="button" class="select-trigger font-secondary u-text-overflow-0-container outsideClick--exclude {{label | replaceString}}" [class.focused]="focused$ | async" (click)="toggleOverlay()" [disabled]="disable" *ngIf="showSearch$ | async">
    <span class="u-text-overflow-0 u-block">{{selectedValue$ | async}}</span>
  </button>

  <input #searchInput [placeholder]="selectedValue$ | async" type="search" *ngIf="!(showSearch$ | async)" [formControl]="searchControl" class="outsideClick--exclude select-search {{label | replaceString}}">

  <button type="button" class="u-margin-auto select-alternative__toggle outsideClick--exclude toggle {{label | replaceString}}" *ngIf="showSearch$ | async" (click)="toggleOverlay()" [disabled]="disable" [class.toggle--opened]="opened$ | async">
    <mat-icon>
      expand_more
    </mat-icon>
  </button>

</div>

<ul *ngIf="isTouched && formHelper.isInvalid(control, disable) && !disableErrors" class="u-list-unstyled">
  <li *ngIf="control.hasError('required')">
    {{label}} is required
  </li>
</ul>
