import {ComponentType} from '@angular/cdk/portal';
import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {AppointmentModalComponent} from '@kit/modal-kit/appointment-modal/appointment-modal.component';
import {ChangePasswordModalComponent} from '@kit/modal-kit/change-password-modal/change-password-modal.component';
import {ConfirmActionModalComponent} from '@kit/modal-kit/confirm-action-modal/confirm-action-modal.component';
import {ContactViaEmailModalComponent} from '@kit/modal-kit/contact-via-email-modal/contact-via-email-modal.component';
import {CookiesModalComponent} from '@kit/modal-kit/cookies-modal/cookies-modal.component';
import {DeliveryInformationModalComponent} from '@kit/modal-kit/delivery-information-modal/delivery-information-modal.component';
import {EditVehiclePaymentModalComponent} from '@kit/modal-kit/edit-vehicle-payment-modal/edit-vehicle-payment-modal.component';
import {LoadingModalComponent} from '@kit/modal-kit/loading-modal/loading-modal.component';
import {PayForCarReportModalComponent} from '@kit/modal-kit/pay-for-car-report-modal/pay-for-car-report-modal.component';
import {PayForVehicleInvoiceModalComponent} from '@kit/modal-kit/pay-for-vehicle-invoice-modal/pay-for-vehicle-invoice-modal.component';
import {SuccessModalComponent} from '@kit/modal-kit/success-modal/success-modal.component';
import {TransactionModalComponent} from '@kit/modal-kit/transaction-modal/transaction-modal.component';
import {UserInfoModalComponent} from '@kit/modal-kit/user-info-modal/user-info-modal.component';
import {ViewFinanceModalComponent} from '@kit/modal-kit/view-finance-modal/view-finance-modal.component';
import {ViewPartExchangeModalComponent} from '@kit/modal-kit/view-part-exchange-modal/view-part-exchange-modal.component';
import {WarningModalComponent} from '@kit/modal-kit/warning-modal/warning-modal.component';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {Observable, of} from 'rxjs';
import {switchMap, take, tap} from 'rxjs/operators';
import {Modal, ModalStateModel} from 'top-car-ngxs';
import {UserInfoWithType} from 'top-car-interfaces';
import {NgxAuthService} from 'ngx-auth';
import {ConfirmStripeOnboardingTermsModalComponent} from '../../pages/account/wallet/kit/confirm-stripe-onboarding-terms-modal/confirm-stripe-onboarding-terms-modal.component';

@State<ModalStateModel>({
    name: 'modal',
    defaults: new ModalStateModel()
})
@Injectable()
export class ModalState {
    private modal?: MatDialogRef<any>;

    @Selector()
    static initialized(state: ModalStateModel): boolean {
        return state.initialized;
    }

    constructor(
        private dialog: MatDialog,
        private store: Store,
        private auth: NgxAuthService
    ) {}

    @Action(Modal.OpenWarning)
    openWarning(
      {patchState}: StateContext<ModalStateModel>,
      {message}: Modal.OpenWarning
    ): Observable<MatDialogRef<WarningModalComponent>> {
        return this.openDialog(WarningModalComponent, {
            data: {message},
            autoFocus: false,
            minWidth: 320
        });
    }

    @Action(Modal.OpenVehiclePurchaseEdit)
    openUpdateDeliveryInformation(
      {patchState}: StateContext<ModalStateModel>,
      {purchase}: Modal.OpenVehiclePurchaseEdit
    ): Observable<MatDialogRef<EditVehiclePaymentModalComponent>> {
        return this.openDialog(EditVehiclePaymentModalComponent, {
            data: purchase,
            autoFocus: false,
            minWidth: 320
        });
    }

    @Action(Modal.OpenAppointment)
    openAppointment(
      {patchState}: StateContext<ModalStateModel>,
      {
        id,
        advertId,
        address,
        lat,
        lng,
        datetime,
        testDrive
      }: Modal.OpenAppointment
    ): Observable<MatDialogRef<AppointmentModalComponent>> {
      console.log(id);
      return this.openDialog(AppointmentModalComponent, {
          data: {
              id,
              advertId,
              address,
              lat,
              lng,
              datetime,
              testDrive
          },
          autoFocus: false,
          minWidth: 320
      });
    }

    @Action(Modal.OpenCookies)
    openCookies(
      {patchState}: StateContext<ModalStateModel>,
    ): Observable<MatDialogRef<CookiesModalComponent>> {
        return this.openDialog(CookiesModalComponent, {
            maxWidth: 784,
            height: '90vh',
            autoFocus: false,
            disableClose: true,
            minWidth: 320
        });
    }

    @Action(Modal.OpenCofirmStripeOnboardingTerms)
    openConfirmOnboarding(
      {patchState}: StateContext<ModalStateModel>,
    ): Observable<MatDialogRef<LoadingModalComponent>> {
        return this.openDialog(ConfirmStripeOnboardingTermsModalComponent, {
            disableClose: true,
            autoFocus: false,
            minWidth: 320
        });
    }

    @Action(Modal.OpenLoading)
    openLoading(
      {patchState}: StateContext<ModalStateModel>,
    ): Observable<MatDialogRef<LoadingModalComponent>> {
        return this.openDialog(LoadingModalComponent, {
            disableClose: true,
            autoFocus: false,
        });
    }

    @Action(Modal.OpenSuccess)
    openSuccess(
      {patchState}: StateContext<ModalStateModel>,
    ): Observable<MatDialogRef<SuccessModalComponent>> {
        return this.openDialog(SuccessModalComponent, {
            disableClose: true,
            autoFocus: false,
        });
    }

    @Action(Modal.OpenConfirm)
    openConfirm(
      {dispatch}: StateContext<ModalStateModel>,
      {action, title, callback}: Modal.OpenConfirm
    ): Observable<any> {
        return this.openDialog(ConfirmActionModalComponent, {
            autoFocus: false,
            minWidth: 320,
          data: {
              title
          }
        }).pipe(
            switchMap((response) => {
                if (response && action) {
                    return dispatch(action);
                } else {
                    callback && callback(response);
                    return of(response).pipe(take(1));
                }
            })
        );
    }

    @Action(Modal.OpenChangePassword)
    openChangePassword(
      {patchState}: StateContext<ModalStateModel>,
    ): Observable<MatDialogRef<ChangePasswordModalComponent>> {
        return this.openDialog(ChangePasswordModalComponent, {
            autoFocus: false,
            minWidth: 320
        });
    }

    @Action(Modal.OpenContactViaEmail)
    openContactViaEmail(
      {patchState}: StateContext<ModalStateModel>,
      {car}: Modal.OpenContactViaEmail
    ): Observable<MatDialogRef<ContactViaEmailModalComponent>> {
        return this.openDialog(ContactViaEmailModalComponent, {
            data: car,
            autoFocus: false,
            minWidth: 320
        });
    }

    @Action(Modal.OpenTransaction)
    openTransaction(
      {patchState}: StateContext<ModalStateModel>,
      {transaction}: Modal.OpenTransaction
    ): Observable<MatDialogRef<TransactionModalComponent>> {
        return this.openDialog(TransactionModalComponent, {
            data: transaction,
            autoFocus: false,
            minWidth: 320
        });
    }

    @Action(Modal.OpenPayForCarReport)
    openPayForCarReport(
      {patchState}: StateContext<ModalStateModel>,
      {parameters}: Modal.OpenPayForCarReport
    ): Observable<MatDialogRef<PayForCarReportModalComponent>> {
        return this.openDialog(PayForCarReportModalComponent, {
            data: parameters,
            autoFocus: false,
            minWidth: 320
        });
    }

    @Action(Modal.OpenFinanceInformation)
    openFinanceInformation(
      {patchState}: StateContext<ModalStateModel>,
      {purchase}: Modal.OpenFinanceInformation
    ): Observable<MatDialogRef<ViewFinanceModalComponent>> {
        return this.openDialog(ViewFinanceModalComponent, {
            data: purchase,
            autoFocus: false,
            minWidth: 320
        });
    }

    @Action(Modal.OpenPartExchangeInformation)
    openPartExchangeInformation(
      {patchState}: StateContext<ModalStateModel>,
      {purchase}: Modal.OpenPartExchangeInformation
    ): Observable<MatDialogRef<ViewPartExchangeModalComponent>> {
        return this.openDialog(ViewPartExchangeModalComponent, {
            data: purchase,
            autoFocus: false,
            minWidth: 320
        });
    }

    @Action(Modal.OpenPayForVehiclePurchase)
    openPayForVehicleInvoice(
      {patchState}: StateContext<ModalStateModel>,
      {purchase}: Modal.OpenPayForVehiclePurchase
    ): Observable<MatDialogRef<PayForVehicleInvoiceModalComponent>> {
        return this.openDialog(PayForVehicleInvoiceModalComponent, {
            data: purchase,
            autoFocus: false,
            minWidth: 320
        });
    }

    @Action(Modal.OpenDeliveryInformation)
    openDeliveryInformation(
      {patchState}: StateContext<ModalStateModel>,
      {purchase}: Modal.OpenDeliveryInformation
    ): Observable<MatDialogRef<DeliveryInformationModalComponent>> {
        return this.openDialog(DeliveryInformationModalComponent, {
            data: purchase,
            autoFocus: false,
            minWidth: 320
        });
    }

    @Action(Modal.OpenUserInformation)
    openUserInfo(
      {dispatch}: StateContext<ModalStateModel>,
      {advert, buyer, seller, targetId, callForAction, branch}: Modal.OpenUserInformation
    ): Observable<MatDialogRef<UserInfoModalComponent> | void> {
        let options: any;
        const userId = this.auth.id;
        if (buyer && buyer.id !== userId) {
            options = {
              ...buyer,
              userType: 'buyer',
              car: advert,
              targetId,
              callForAction
            };
          } else if (seller && seller.id !== userId) {
            options = {
              ...seller,
              fullName: branch?.name || seller.fullName,
              phoneNumber: branch?.phoneNumber || seller.phoneNumber,
              secondaryPhoneNumber: seller.secondaryPhoneNumber,
              userType: branch ? 'branch' : 'seller',
              car: advert,
              targetId,
              callForAction
            };
          } else {
            return dispatch([new Modal.OpenWarning('No user provided')]);
          }
        return this.openDialog(UserInfoModalComponent, {
            data: options,
            autoFocus: false,
            minWidth: 320
        });
    }

    @Action(Modal.CloseModal)
    closeModal(
      {patchState}: StateContext<ModalStateModel>,
    ): void {
      if (this.modal) {
        this.modal.disableClose = false;
        this.modal.close();
        this.modal = undefined;
      }
    }

    private openDialog(
        component: ComponentType<any>,
        options?: MatDialogConfig
    ): Observable<any> {
      let initiator;
      if (event && event.currentTarget && (<any> event.currentTarget).blur) {
        initiator = event.currentTarget;
      }

      if (this.modal && this.modal.componentInstance instanceof component) {
        return of(this.modal).pipe(take(1));
      }
      return this.store.dispatch(new Modal.CloseModal()).pipe(
        switchMap(() => {
          this.modal = this.dialog.open(component, options);
          if (this.modal) { return this.modal.afterClosed().pipe(
            tap(() => {
              if (initiator) {
                setTimeout(() => {
                  try { initiator.blur(); } catch {}
                }, 0);
              }
            })
          ); } else { return of().pipe(take(1)); }
        })
      );
    }

}
