import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {TopCarApiModule} from 'top-car-api';
import {NgxStorageModule} from 'ngx-storage';
import {NgxCompanyHouseModule} from 'ngx-company-house';
import {NgxServiceWorkerConfig, NgxServiceWorkerModule} from 'ngx-service-worker';
import {NgxSignalrSocketModule} from 'ngx-signalr-socket';
import {NgxConnectivityModule} from 'ngx-connectivity';
import {TopCarFormlyModule} from 'top-car-formly';
import {NgxsModule} from '@ngxs/store';
import { NgxAuthModule } from 'ngx-auth';
import {TopCarUtilsModule} from 'top-car-utils';
import {AgmCoreModule} from '@agm/core';
import {NgxStripeModule} from 'ngx-stripe';
import {
  TopCarNgxsModule
} from 'top-car-ngxs';
import {ModalKitModule} from '@kit/modal-kit/modal-kit.module';
import {NgxEchartsModule} from 'ngx-echarts';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    TopCarApiModule.forRoot({
      url: environment.API,
      lockRoute: ['/error'],
      production: environment.production
    }),
    NgxStorageModule.forRoot(),
    NgxCompanyHouseModule.forRoot({
      url: environment.API
    }),
    NgxAuthModule.forRoot({
      production: environment.production,
      loginRoute: ['/auth', 'login'],
      loginAPI: environment.authenticationAPI.loginWithEmail,
      refreshAPI: environment.authenticationAPI.refreshToken,
      registerAPI: environment.authenticationAPI.registerWithEmail,
      forgotPasswordAPI: environment.authenticationAPI.forgotPassword,
      resetPasswordAPI: environment.authenticationAPI.resetPassword,
      registerRequestAPI: environment.authenticationAPI.registerRequest,
      userAPI: environment.accountAPI.account,
      domain: 'topcar',
      topLevelDomain: 'co.uk',
      showPasswordImageUrl: '/assets/icons/eye.svg'
    }),
    NgxServiceWorkerModule.forRoot({
      ...new NgxServiceWorkerConfig(),
      enabled: environment.production
    }),
    NgxSignalrSocketModule.forRoot({
      url: environment.API
    }),
    NgxConnectivityModule,
    TopCarFormlyModule.forRoot({
      url: environment.API
    }),
    NgxsModule.forRoot(),
    TopCarUtilsModule.forRoot({
      url: environment.API
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsKey
    }),
    NgxStripeModule.forRoot(environment.stripeAPI.key),
    TopCarNgxsModule.forRoot({
      url: environment.API,
      googleAnalytics: environment.googleAnalytics,
      production: environment.production
    }),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    ModalKitModule
  ],
  exports: [
    ModalKitModule
  ]
})
export class CoreModule { }
