import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {CombineSubscriptions, DestroySubscribers} from 'top-car-decorators';
import {BehaviorSubject, Unsubscribable} from 'rxjs';
import {ControlContainer, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MaterialOverlayService, OverlayParams, WidthStrategy} from 'top-car-formly';
import {SelectOptionsListComponent,} from '@kit/forms-kit/select-options-list/select-options-list.component';
import {OptionGroup, Option} from 'top-car-interfaces';
import {FormHelperService} from 'top-car-formly';
import {SelectOptionsType} from '@kit/forms-kit/select-options-list/select-options-list.enum';
import {SelectOptionsListParams} from '@kit/forms-kit/select-options-list/select-options-list.interface';
import {filter} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-select-alternative',
  templateUrl: './select-alternative.component.html',
  styleUrls: ['./select-alternative.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectAlternativeComponent),
      multi: true
    },
  ]
})
@DestroySubscribers({
  destroyFunc: 'destroy'
})
export class SelectAlternativeComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {
  @CombineSubscriptions() subscriber: Unsubscribable;
  @ViewChild('searchInput', {static: false}) searchInput: ElementRef;
  @ViewChild('select', {static: true}) select: ElementRef;

  @Input() public label?: string;

  @Input() multiple: boolean = false;
  @Input() ngxsFormPropertyPath?: string;
  @Input() ngxsFormPath?: string;
  @Input() formControlName?: string;
  @Input() options?: Option[];
  @Input() groups?: OptionGroup<Option>[];
  @Input() type: SelectOptionsType = SelectOptionsType.STANDARD;
  @Input() disable?: boolean;
  @Input() widthStrategy?: WidthStrategy;
  @Input() enableSearch: boolean = false;
  @Input() resetText?: string = 'Any';
  @Input() disableErrors?: boolean;
  @Input() submitted?: boolean;

  searchControl = new FormControl();

  opened = false;
  opened$ = new BehaviorSubject(false);

  focused = false;
  focused$ = new BehaviorSubject(false);

  selectedValue: string;
  selectedValue$ = new BehaviorSubject(undefined);

  showSearch: boolean = false;
  showSearch$ = new BehaviorSubject(false);

  private valueSub: Subscription;

  constructor(
    private elementRef: ElementRef,
    public overlay: MaterialOverlayService,
    private controlContainer: ControlContainer,
    public formHelper: FormHelperService,
    private router: Router
  ) { }

  get control(): FormControl {
    return this.controlContainer.control.get(this.formControlName) as FormControl;
  }

  get params(): OverlayParams {
    return {
      elementRef: this.select,
      // backdropClass: 'search-floating-overlay',
      // panelClass: 'search-floating',
      disposeOnNavigation: true,
      saveKey: 'select' + this.label.split(' ').join( '_'),
      type: this.widthStrategy || this.formHelper.typeForOverlay(this.type)
    }
  }

  get paramsPortal(): SelectOptionsListParams {
    return {
      savedKey: this.params.saveKey,
      multiple: this.multiple,
      ngxsFormPropertyPath: this.ngxsFormPropertyPath,
      ngxsFormPath: this.ngxsFormPath,
      formControl: this.control,
      searchControl: this.searchControl,
      options: this.options,
      groups: this.groups,
      type: this.type,
      resetText: this.resetText,
      label: this.label.replace(' ', '_')
    }
  }

  setSearch() {
    this.showSearch = !this.enableSearch || (!this.opened && this.enableSearch);
    this.showSearch$.next(this.showSearch);
  }

  ngOnInit(): void {
    this.setValue();
    this.setFocused();
    this.setSearch();

    this.valueSub = this.control.valueChanges.subscribe(() => {
      this.setValue();
      this.setFocused();
    });

    this.subscriber = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe((value) => {
      this.valueSub && this.valueSub.unsubscribe();

      this.valueSub = this.control.valueChanges.subscribe(() => {
        this.setValue();
        this.setFocused();
      });
    });


  }

  private setValue() {
    this.selectedValue = this.formHelper.showSelectValue(
      this.groups,
      this.options,
      this.control.value,
      this.label,
      this.type
    );

    this.selectedValue$.next(this.selectedValue);
  }

  private setFocused() {
    this.focused = this.selectedValue !== this.label;
    this.focused$.next(this.focused);
  }

  public get isTouched() {
    return this.control.touched || this.control.dirty || this.submitted;
  }

  ngOnChanges(e: SimpleChanges): void {
    if (this.opened) {
      this.overlay.detachOverlay(this.params.saveKey);
      this.toggleOverlay();
    }
    this.setValue();
    this.setFocused();
  }

  ngOnDestroy(): void {
    this.opened = false;
    this.opened$.next(false);
    this.overlay.closeOverlay(this.params);
    this.valueSub && this.valueSub.unsubscribe();
    this.destroy();
  }

  destroy(): void {}

  private setOpened(value) {
    this.opened = value;
    this.opened$.next(value);
    this.setSearch();
  }

  private focusInput() {
    if (this.opened && this.enableSearch) {
      setTimeout(() => {
        if (this.searchInput?.nativeElement) this.searchInput.nativeElement.focus();
      });
    }
  }

  toggleOverlay() {
    this.onTouched();
    this.setOpened(!this.opened);
    this.focusInput();

    this.overlay.toggleOverlay(this.params, this.paramsPortal, SelectOptionsListComponent, () => {
      this.setOpened(false);
      this.searchControl.reset();
    });
  }

  // ControlValueAccessor Implementation
  onChange: any = () => { };
  onTouched: any = () => {
    this.control.markAsTouched();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    // this.value = value;
  }

  setDisabledState(isDisabled: boolean): void {
    // this.disabled = isDisabled;
  }
}
