import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import {environment} from '../environments/environment';
import {MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS} from '@angular/material/progress-spinner';
import {AppRoutingModule} from './app-routing.module';

import {
  AppointmentsState,
  AuthState, CompareState,
  DataState,
  ErrorState,
  MessageHubState, MyCarsState, NotificationHubState, ReportsState, ReviewsState, SavedSearchesState, SearchCarsState,
  TwilioState,
  VehiclePurchaseState,
  WalletState
} from 'top-car-ngxs';

import {NgxsModule, NoopNgxsExecutionStrategy} from '@ngxs/store';
import {CoreModule} from './modules/core/core.module';
import {SharedModule} from './modules/shared/shared.module';
import { LayoutsModule } from './layouts/layouts.module';
import {NgxsDataPluginModule} from '@ngxs-labs/data';
import {NGXS_DATA_STORAGE_PLUGIN} from '@ngxs-labs/data/storage';
import {NgxsRouterPluginModule} from '@ngxs/router-plugin';
import {NgxsFormPluginModule} from '@ngxs/form-plugin';
import {NgxsActionsExecutingModule} from '@ngxs-labs/actions-executing';
import {LegalState} from './ngxs/legal/legal.state';
import {ModalState} from './ngxs/modal/modal.state';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    NoopAnimationsModule,

    NgxsDataPluginModule.forRoot(
      [
        NGXS_DATA_STORAGE_PLUGIN
      ]
    ),
    NgxsRouterPluginModule.forRoot(),
    NgxsFormPluginModule.forRoot(),
    NgxsActionsExecutingModule.forRoot(),
    NgxsModule.forRoot([
      AuthState,
      NotificationHubState,
      MessageHubState,
      CompareState,
      SearchCarsState,
      MyCarsState,
      WalletState,
      VehiclePurchaseState,
      ReviewsState,
      ReportsState,
      DataState,
      SavedSearchesState,
      AppointmentsState,
      ErrorState,
      TwilioState,
      LegalState,
      ModalState,
    ], {
      developmentMode: !environment.production,
      executionStrategy: NoopNgxsExecutionStrategy
    }),

    CoreModule,
    SharedModule,
    LayoutsModule,

    /*
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),*/
  ],
  providers: [
    {
      provide: MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS,
      useValue: {
          _forceAnimations: true
      }
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
