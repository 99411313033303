import {Wallet} from 'top-car-ngxs';
import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngxs/store';

@Component({
  selector: 'app-confirm-stripe-onboarding-terms-modal',
  templateUrl: './confirm-stripe-onboarding-terms-modal.component.html',
  styleUrls: ['./confirm-stripe-onboarding-terms-modal.component.scss']
})
export class ConfirmStripeOnboardingTermsModalComponent implements OnInit {
  public enableAccept = false;

  public readonly url = 'https://topcar.co.uk/assets/Terms_And_Conditions_Car_Dealers_for_Online_Payment.html';

  readonly formGroup = new FormGroup({
    check: new FormControl(false, [Validators.requiredTrue])
  });

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.formGroup.disable();
  }

  close(agreed = false): void {
    this.dialogRef.close(agreed);
  }

  handleRead() {
    this.enableAccept = true;
    this.formGroup.enable();
  }

  verifyForPayment(): void {
    this.store.dispatch(new Wallet.ProceedWithVerification());
  }

}
