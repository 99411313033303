import {VehiclePurchase} from 'top-car-ngxs';
import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {VehiclePurchaseInterface} from 'top-car-interfaces';
import {Select, Store} from '@ngxs/store';
import {AuthState} from 'top-car-ngxs';
import {Observable} from 'rxjs';
import {NgxAuthService} from 'ngx-auth';

@Component({
  selector: 'app-edit-vehicle-payment-modal',
  templateUrl: './edit-vehicle-payment-modal.component.html',
  styleUrls: ['./edit-vehicle-payment-modal.component.scss']
})
export class EditVehiclePaymentModalComponent implements OnInit {
  userId = this.auth.id;
  loading = false;

  formGroup = new FormGroup({
    newDeliveryPrice: new FormControl(null, [Validators.required]),
    newDeliveryAddress: new FormControl(null, [Validators.required]),
    newDate: new FormControl(new Date(), [Validators.required]),
    newPrice: new FormControl(null, [Validators.required])
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: VehiclePurchaseInterface,
    private store: Store,
    public dialogRef: MatDialogRef<any>,
    private auth: NgxAuthService
  ) { }

  ngOnInit(): void {
    if (this.data) {
      if (this.data.buyerId === this.userId) {
        this.formGroup.controls.newPrice.disable();
      } else {
        this.formGroup.controls.newDeliveryAddress.disable();
        if (!this.data.deliveryDateTime) {
          this.formGroup.controls.newDate.disable();
          this.formGroup.controls.newDeliveryPrice.disable();
        }
      }
      this.formGroup.patchValue({
        // newPrice: this.data.price,
        newDeliveryAddress: this.data.deliveryAddress,
        newDate: this.data.deliveryDateTime ? new Date(this.data.deliveryDateTime) : null,
        newDeliveryPrice: this.data.deliveryPrice
      })
    }
  }

  edit(): void {
    const {newDeliveryAddress, newDate, newPrice, newDeliveryPrice} = this.formGroup.value;
    if (this.data.price < Number(newPrice)) {
      this.formGroup.controls.newPrice.setErrors({
        error: 'Trade-in price should not be bigger than total car price'
      });
    }
    this.loading = true;
    this.store.dispatch(new VehiclePurchase.Edit(
      this.data,
      newDeliveryAddress,
      new Date(newDate).toJSON(),
      newDeliveryPrice,
      this.data.price - Number(newPrice)
    )).subscribe(() => {
      this.loading = false;
      this.dialogRef.close();
    }, error => {
      console.log(error);
      this.loading = false;
    });
  }

}
