<app-header type="car"></app-header>

<div class="router-wrapper">

  <router-outlet></router-outlet>

</div>

<app-footer></app-footer>

<app-compare-card></app-compare-card>

<app-scroll-top-button></app-scroll-top-button>

<app-chat *ngIf="isAuthenticated$ | async"></app-chat>
