<div class="u-position--relative">
  <div class="modal__loading u-flex u-flex-align-center u-flex-justify-center" *ngIf="loading">
    <mat-spinner *ngIf="!completed"></mat-spinner>
    <mat-icon class="modal__loading-completed" *ngIf="completed">check</mat-icon>
  </div>
  <div class="u-text-right">
    <button mat-icon-button class="modal__close" (click)="dialogRef.close()"><mat-icon>close</mat-icon></button>
  </div>
  <div class="u-text-center" *ngIf="title">
    <span class="u-text-bold modal__title">{{title}}</span>
  </div>
  <mat-dialog-content class="add-card-modal__content font-secondary u-margin-top-24 u-text-center">

    <ng-content></ng-content>

  </mat-dialog-content>

</div>
