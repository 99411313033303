<div class="u-flex u-flex-justify-between password-validator-block font-secondary">
  <div class="password-validator-block__left u-text-left">
    <ul class="u-padding-0 u-margin-0">
      <li class="u-flex u-flex-align-center password-validator-block__item"><mat-icon [class.u-color-danger]="!isLongerThan8" [class.u-color-success]="isLongerThan8" class="password-validator-block__item-icon">{{isLongerThan8 ? 'check' : 'clear'}}</mat-icon> <span class="password-validator-block__item-text">8 characters or more</span></li>
      <li class="u-flex u-flex-align-center password-validator-block__item"><mat-icon [class.u-color-danger]="!containsNumber" [class.u-color-success]="containsNumber" class="password-validator-block__item-icon">{{containsNumber ? 'check' : 'clear'}}</mat-icon> <span class="password-validator-block__item-text">At least one number</span></li>
      <li class="u-flex u-flex-align-center password-validator-block__item"><mat-icon [class.u-color-danger]="!containsSpecialCharacter" [class.u-color-success]="containsSpecialCharacter" class="password-validator-block__item-icon">{{containsSpecialCharacter ? 'check' : 'clear'}}</mat-icon> <span class="password-validator-block__item-text">Special character (e.g. ! $ % ?)</span></li>
      <li class="u-flex u-flex-align-center password-validator-block__item"><mat-icon [class.u-color-danger]="!containsUpperCase" [class.u-color-success]="containsUpperCase" class="password-validator-block__item-icon">{{containsUpperCase ? 'check' : 'clear'}}</mat-icon> <span class="password-validator-block__item-text">Uppercase letter</span></li>
      <li class="u-flex u-flex-align-center password-validator-block__item"><mat-icon [class.u-color-danger]="!containsLowerCase" [class.u-color-success]="containsLowerCase" class="password-validator-block__item-icon">{{containsLowerCase ? 'check' : 'clear'}}</mat-icon> <span class="password-validator-block__item-text">Lowercase letter</span></li>
    </ul>
  </div>
  <div class="password-validator-block__right">
    <span class="password-validator-block__strength" [class.u-color-success]="isLongerThan8 && containsLowerCase && containsUpperCase && containsSpecialCharacter" [class.u-color-danger]="!isLongerThan8 || !containsLowerCase || !containsUpperCase || !containsSpecialCharacter">
      Password strength
    </span>
  </div>
</div>
