<ngx-stripe-card-group
  [formGroup]="formGroup"
  [elementsOptions]="elementsOptions"
  class="get-card-token u-position--relative"
>

  <div class="modal__loading u-flex u-flex-align-center u-flex-justify-center" *ngIf="loading">
    <mat-spinner *ngIf="!completed"></mat-spinner>
    <mat-icon class="modal__loading-completed" *ngIf="completed">check</mat-icon>
  </div>

  <ngx-stripe-card #stripeCard (change)="error = $event.error" (error)="error = $event" class="get-card-token__number" [elementsOptions]="elementsOptions" [options]="cardOptions" [class.u-margin-bottom-5]="error"></ngx-stripe-card>
  <span class="u-color-danger" *ngIf="error">{{error.message}}</span>
  <app-input formControlName="name" label="Full name" appUppercaseInput [enableUppercase]="true"></app-input>

  <div class="u-flex u-flex-align-center u-flex-justify-between">
    <button mat-button color="danger" type="reset" (click)="reset()">
      Reset
    </button>
    <button mat-button color="primary" type="submit" (click)="createToken()" [disabled]="formGroup.invalid || error">
      {{buttonText}}
    </button>
  </div>

  <a class="u-margin-top-24 u-block" href="https://stripe.com" target="_blank" rel="nofollow"><img class="get-card-token__stripe" src="/assets/images/third-parties/powered-by-stripe.svg"></a>

</ngx-stripe-card-group>

