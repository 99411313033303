<footer class="footer">
  <div class="wrapper u-flex u-flex-justify-between footer__wrapper">
    <div class="footer__left">
      <span class="u-text-bold u-block u-text-italic font-tertiary footer__logo">TopCar</span>
      <p class="u-color-white-50 u-text-weight-600 font-secondary footer__copyright">
        Copyright © TopCar 2021.
        <br>
        TopCar is operated by Falcon Invest Group Ltd
        <br>
        <a href="mailto:info@topcar.co.uk" class="u-color-white-50">info@topcar.co.uk</a>
      </p>
    </div>
    <div class="footer__center u-flex u-text-weight-600 font-secondary">
        <ul class="u-list-unstyled footer__list">
          <li class="footer__item"><a class="u-color-white link-unstyled footer__link" routerLink="/cars/search" fragment="CarSearch">Buy car</a></li>
          <li class="footer__item"><a class="u-color-white link-unstyled footer__link" routerLink="/car/sell/1">Sell car</a></li>
          <li class="footer__item"><a class="u-color-white link-unstyled footer__link" routerLink="/auth/registration/dealer" *ngIf="!(isAuthenticated$ | async)">Dealer registration</a></li>
          <li class="footer__item"><a class="u-color-white link-unstyled footer__link" routerLink="/seller/dealers">All dealers</a></li>
          <!--
          <li class="footer__item"><a class="u-color-white link-unstyled footer__link" routerLink="/reverse-buy/car/search">Reverse buy search</a></li>
          <li class="footer__item"><a class="u-color-white link-unstyled footer__link" routerLink="/reverse-buy/car/add">Add reverse buy</a></li>
          -->
        </ul>
        <ul class="u-list-unstyled footer__list">
          <li class="footer__item"><a class="u-color-white link-unstyled footer__link" routerLink="/profile">Profile</a></li>
          <li class="footer__item"><a class="u-color-white link-unstyled footer__link" routerLink="/" fragment="Blog" (click)="checkScroll()">Blog</a></li>
          <li class="footer__item"><a class="u-color-white link-unstyled footer__link" routerLink="/support">Support</a></li>
          <li class="footer__item"><a class="u-color-white link-unstyled footer__link" routerLink="/privacy">Privacy</a></li>
          <li class="footer__item"><a class="u-color-white link-unstyled footer__link" routerLink="/terms">Terms and Conditions</a></li>

        </ul>
    </div>
    <div class="footer__right">
      <a target="_blank">
        <img loading="lazy" src="/assets/images/core/google-play.svg" alt="Get it on Google Play">

      </a>
      <br>
      <a target="_blank">
        <img loading="lazy" src="/assets/images/core/app-store.svg" alt="Get it on App Store">

      </a>
    </div>
  </div>
</footer>
