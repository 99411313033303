import {ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, ControlContainer, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CombineSubscriptions, DestroySubscribers} from 'top-car-decorators';
import {Option} from 'top-car-interfaces';
import {BehaviorSubject, Unsubscribable} from 'rxjs';

@Component({
  selector: 'app-tab-button-toggle',
  templateUrl: './tab-button-toggle.component.html',
  styleUrls: ['./tab-button-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TabButtonToggleComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@DestroySubscribers()
export class TabButtonToggleComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @CombineSubscriptions()
  private subscriber: Unsubscribable;

  @Input() options: Option[] = [];
  @Input()
  formControl: FormControl;
  @Input()
  formControlName: string;
  @Input() readonly = false;
  @Input() activeColor?: string;

  value$ = new BehaviorSubject(undefined);

  trackBy = (index: number, item: Option) => {
    return item?.id || index;
  }

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit() {
    this.value$.next(this.control.value);
    this.subscriber = this.control.valueChanges.subscribe(value => {
      this.value$.next(value);
    });
  }

  ngOnDestroy() {}

  handleClick(option: Option) {
    if (!this.readonly) {
      this.control.patchValue(option.value)
    }
  }

  isEqual(option: Option) {
    return option.value == this.control.value;
  }

  get control(): AbstractControl {
    return this.controlContainer.control.get(this.formControlName);
  }

  clearInput(): void {
    this.control.setValue('');
  }

  registerOnTouched(fn: any): void {
  }

  registerOnChange(fn: any): void {
  }

  writeValue(obj: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }
}
