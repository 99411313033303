import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TopCarApiService} from 'top-car-api';

@Component({
  selector: 'app-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss']
})
export class WarningModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: TopCarApiService
  ) { }

  ngOnInit(): void {
  }

  close(agreed = false): void {
    this.dialogRef.close(agreed);
  }

  verifyForPayment(): void {
    const windowReference: any = window.open("about:blank","_blank");
    this.api.requestLinkForOnlinePaymentVerification().subscribe(response => {
      windowReference.location = response.url;
      this.close();
    });
  }

}
