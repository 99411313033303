<div class="u-position--relative">
    <div class="u-text-right">
      <button mat-icon-button class="modal__close" (click)="dialogRef.close()"><mat-icon>close</mat-icon></button>
    </div>
    <mat-dialog-content class="top-up-modal__content font-secondary u-text-center" >
  
          <img class="logo-modal" src="/assets/images/core/logo.png">
  
          <h1 class="font-secondary u-text-bold">Delivery information</h1>
  
          <table class="u-width-100 modal-table">
            <tr>
                <th scope="row">Full name</th>
                <td>{{data.buyer.fullName}}</td>
            </tr>
            <tr>
                <th scope="row">Phone number</th>
                <td>{{data.buyer.phoneNumber}}</td>
            </tr>
            <tr>
              <th scope="row">Address</th>
              <td>{{data.deliveryAddress}}</td>
            </tr>
            <tr>
              <th scope="row">Date</th>
              <td>{{data.deliveryDateTime | date:'medium'}}</td>
            </tr>
          </table>

          <button *ngIf="data?.status === 0" appStopPropagation class="u-margin-x-auto u-margin-top-24" mat-flat-button color="primary" (click)="changeDeliveryInformation()">Change delivery</button>

    </mat-dialog-content>
  </div>
