import {Component, Inject, NgZone, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser, ViewportScroller} from '@angular/common';
import {NgxServiceWorkerService} from 'ngx-service-worker';
import {NgxConnectivityService} from 'ngx-connectivity';
import {AuthTokensResponse, NgxAuthService} from 'ngx-auth';
import {CombineSubscriptions, DestroySubscribers} from 'top-car-decorators';
import {Unsubscribable} from 'rxjs/internal/types';
import {distinctUntilChanged, filter, first, map, skipWhile, switchMap, tap} from 'rxjs/operators';
import {TopCarApiService} from 'top-car-api';
import {ListHelperService} from '@services/list-helper.service';
import {LayoutsEnum} from './layouts/layouts.enum';
import {ActivatedRoute, ActivationEnd, NavigationEnd, Router, Scroll} from '@angular/router';
import {Store} from '@ngxs/store';
import {MessageHub, Modal, NotificationHub} from 'top-car-ngxs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
@DestroySubscribers()
export class AppComponent implements OnInit, OnDestroy {
  Layouts = LayoutsEnum;
  layout: LayoutsEnum;

  private previousUrl?: string;

  @CombineSubscriptions()
  private subscriber!: Unsubscribable;

  constructor(
    @Inject(PLATFORM_ID) private platform: string,
    private connectivity: NgxConnectivityService,
    private serviceWorker: NgxServiceWorkerService,
    private auth: NgxAuthService,
    private api: TopCarApiService,
    private listHelper: ListHelperService,
    private router: Router,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    private store: Store
  ) {
    if (isPlatformBrowser(this.platform)) {
      this.connectivity.handleConnectivity();
      this.serviceWorker.handleSwUpdates();
      this._queryAuthParamsHandler();
      this._queryOnbordingHandler();
      this._queryEmailConfirmationHandler();
    }
  }

  ngOnInit(): void {
    this._authHandler();
    this.ngZone.runOutsideAngular(() => {
      this.previousUrl = this.router.url;
      this.subscriber = this.router.events.subscribe(this._routingHandler);
    });
  }

  private _authHandler() {
    if (!this.auth.userData) this.auth.getUserData().subscribe();
    this.subscriber = this.auth.isAuthenticated$.asObservable().pipe(
      distinctUntilChanged(),
    ).subscribe((value) => {
      if (value) {
        this.store.dispatch(new NotificationHub.Connect());
      } else {
        this.store.dispatch(new NotificationHub.CloseConnection());
      }
    });
  }

  private _routingHandler = (value: ActivationEnd | NavigationEnd | Scroll) => {

    if (value instanceof ActivationEnd) {
      this._layoutHandler(value);
    } else if (value instanceof  NavigationEnd) {

    } else if (value instanceof Scroll) {
      this.handleNavigationScroll(value);
    }

  }

  private handleNavigationScroll(value: any): void {
    const url = value.routerEvent.urlAfterRedirects.split('?')[0]
      if (url === this.previousUrl) return;

      setTimeout(() => {
        if (value.position) {
          // backward navigation
          this.viewportScroller.scrollToPosition(value.position);
        } else if (value.anchor) {
          // anchor navigation
          if (value.anchor === 'Blog') {
            this.viewportScroller.setOffset([0, 200]);
          }
          this.viewportScroller.scrollToAnchor(value.anchor);
          this.viewportScroller.setOffset([0, 0]);
        } else {
          // forward navigation

          if (
            ((this.previousUrl === '/cars/home' || this.previousUrl === '/') && url.startsWith('/cars/search')) ||
            (this.previousUrl && this.previousUrl.includes('/cars/search') && url.includes('/cars/search'))
          )
          {} else {
            this.viewportScroller.scrollToPosition([0, 0]);
          }
        }
        this.ngZone.run(() => {
          this.previousUrl = url;
        });
      }, 1 );
  }

  private _layoutHandler(value: ActivationEnd) {
    this.layout = value.snapshot.data.layout;
  }

  private _queryAuthParamsHandler(): void {
    this.subscriber = this.route.queryParams
      .pipe(
        skipWhile(value => !value.accessToken || !value.userId || !value.refreshToken || !value.expiration),
        map(value => ({
          accessToken: value.accessToken,
          userId: value.userId,
          refreshToken: value.refreshToken,
          expiration: value.expiration
        })),
        first(),
      )
      .subscribe((token: AuthTokensResponse) => {
        this.auth.setAuthData(token);
        this.router.navigate([], {relativeTo: this.route, queryParams: {
            accessToken: undefined,
            userId: undefined,
            refreshToken: undefined,
            expiration: undefined
          }})
      });
  }

  private _queryOnbordingHandler(): void {
    this.subscriber = this.route.queryParams
      .pipe(
        skipWhile(value => !value.onbording && value.onbording !== 'success'),
        map(value => value.onbording),
        first(),
      )
      .subscribe((onbording: string) => {
        this.api.completeOnlinePaymentVerification().subscribe(() => {
          this.store.dispatch(new Modal.OpenSuccess());
          this.auth.userData$.subscribe();
          setTimeout(() => {
            this.store.dispatch(new Modal.CloseModal());
          }, 3000 );
        }, error => {
          this.store.dispatch(new Modal.OpenWarning('Additional information required for verification'));
        });
      });
  }

  private _queryEmailConfirmationHandler(): void {
    this.subscriber = this.route.queryParams
      .pipe(
        skipWhile(value => !value.emailConfirmationRequested),
        map(value => value.emailConfirmationRequested == 'true'),
        first(),
      )
      .subscribe((value: boolean) => {
        this.store.dispatch(new Modal.OpenWarning('Confirmation link expired. New has been sent, please check email'))
      });
  }

  ngOnDestroy() {}
}
