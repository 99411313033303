import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject, Unsubscribable} from 'rxjs';
import {CombineSubscriptions, DestroySubscribers} from 'top-car-decorators';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-avatar-uploader',
  templateUrl: './avatar-uploader.component.html',
  styleUrls: ['./avatar-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AvatarUploaderComponent),
      multi: true
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@DestroySubscribers()
export class AvatarUploaderComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @ViewChild('fileInput') myInputVariable: ElementRef;
  @Input() formControlName: string;
  @Input() imagePath: string;

  deleteClicked = false;
  @Output() deleted = new EventEmitter();

  domain = environment.API;

  path$ = new BehaviorSubject<string>('/assets/images/core/not-found.png');

  @CombineSubscriptions()
  private subscriber: Unsubscribable;

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    if (this.imagePath) {
      this.path$.next(this.imagePath);
    }
    this.subscriber = this.control.valueChanges.subscribe(value => {
      if (value instanceof FileList) {
        // this.myInputVariable.nativeElement.value = value;
      } else {
        this.myInputVariable.nativeElement.value = '';
        if (!value || value === '') {
          this.path$.next('/assets/images/core/not-found.png');
          this.imagePath = null;
        } else {
          this.path$.next(value);
        }
      }
    });
  }

  ngOnDestroy(): void {}

  uploadFile(event): void {
    this.deleted.next(false);
    this.deleteClicked = false;
    this.control.patchValue(event);
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      // convert image file to base64 string
      this.path$.next(reader.result.toString());
    }, false);

    if (event[0]) {
      reader.readAsDataURL(event[0]);
    }
  }

  deleteAvatar(): void {
    this.control.reset(null);
    setTimeout(() => {
      this.deleteClicked = true;
      this.deleted.next(true);
    });
  }

  get control(): AbstractControl {
    return this.controlContainer.control.get(this.formControlName);
  }

  clearInput(): void {
    this.control.setValue('');
  }

  registerOnTouched(fn: any): void {
    // this.formControlDirective.valueAccessor.registerOnTouched(fn);
  }

  registerOnChange(fn: any): void {
    // this.formControlDirective.valueAccessor.registerOnChange(fn);
  }

  writeValue(obj: any): void {
    // this.formControlDirective.valueAccessor.writeValue(obj);
  }

  setDisabledState(isDisabled: boolean): void {
    // this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
  }
}
