import {Component, forwardRef, Input} from '@angular/core';
import {ControlContainer, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarComponent),
      multi: true
    }
  ]
})
export class CalendarComponent implements ControlValueAccessor {
  @Input() formControlName?: string;

  options = { "isWeek": true, "isWithTime": true,  fromHour: 8, toHour: 18, isFromNow: true };

  constructor(private controlContainer?: ControlContainer) { }

  get control(): FormControl {
    return this.controlContainer && this.controlContainer.control.get(this.formControlName) as FormControl;
  }

  changeDate($event: moment.Moment) {
    this.control.patchValue($event);
  }

  // ControlValueAccessor Implementation
  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    // this.value = value;
  }

  setDisabledState(isDisabled: boolean): void {
    // this.disabled = isDisabled;
  }
}
