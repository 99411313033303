import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ControlContainer, ControlValueAccessor, FormControl, FormControlDirective, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject, Unsubscribable} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter} from 'rxjs/operators';
import {MaterialOverlayService, OverlayParams} from 'projects/top-car-formly/src/lib/material-overlay.service';
import {CombineSubscriptions, DestroySubscribers} from 'top-car-decorators';
import {FloatingSearchOptionsComponent} from '../floating-search-options/floating-search-options.component';
import {FormHelperService} from 'top-car-formly';
import {Store} from '@ngxs/store';
import {DataState} from 'top-car-ngxs';
import {SelectOptionsType} from '@kit/forms-kit/select-options-list/select-options-list.enum';
import {SelectOptionsListParams} from '@kit/forms-kit/select-options-list/select-options-list.interface';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchInputComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@DestroySubscribers({
  destroyFunc: 'destroy'
})
export class SearchInputComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @ViewChild(FormControlDirective, {static: true})
  formControlDirective: FormControlDirective;
  @ViewChild('select', {static: true}) select: ElementRef;

  @Input() public label?: string;
  @Input() formControlName?: string;
  @Input() type = 'text';
  @Input() readonly = false;
  @Input() disable = false;
  @Input() prefixIcon?: string = 'search';
  @Input() prefixTooltip?: string;
  @Input() prefixColor?: string = '#fff';
  @Input() enableOverlay?: boolean;
  @Input() isWhite?: boolean = true;

  opened = false;
  opened$ = new BehaviorSubject(false);

  focused = false;
  focused$ = new BehaviorSubject(false);

  @CombineSubscriptions() subscriber: Unsubscribable;

  @Output() prefixClicked = new EventEmitter();


  constructor(
    private controlContainer: ControlContainer,
    private elementRef: ElementRef,
    private overlay: MaterialOverlayService,
    public formHelper: FormHelperService,
    private store: Store
  ) {}

  get params(): OverlayParams {
    return {
      elementRef: this.select,
      // backdropClass: 'search-floating-overlay',
      // panelClass: 'search-floating',
      disposeOnNavigation: true,
      saveKey: 'select' + this.label.replace(' ', '_'),
      type: this.formHelper.typeForOverlay(SelectOptionsType.MODEL_VARIANT)
    }
  }

  get paramsPortal(): SelectOptionsListParams {
    return {
      savedKey: this.params.saveKey,
      formControl: this.control,
      multiple: false,
      label: this.label.split(' ').join( '_'),
      type: SelectOptionsType.STANDARD,
      options: this.store.selectSnapshot(DataState.car).brands
    }
  }

  ngOnInit(): void {
    this.subscriber = this.control.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(100),
      filter(val => !!val && val !== '')
    ).subscribe((value) => {
      if (!this.opened) {
        this.toggleOverlay();
      }
    })
  }

  ngOnDestroy(): void {
    this.opened = false;
    this.opened$.next(false);
    this.overlay.closeOverlay(this.params);
    this.destroy();
  }

  destroy(): void {}

  private setFocused() {
    this.focused = !this.control.pristine;
    this.focused$.next(this.focused);
  }

  private setOpened(value) {
    this.opened = value;
    this.opened$.next(value);
  }


  toggleOverlay(): void {
    if (!this.enableOverlay) { return; }

    this.onTouched();
    this.setOpened(!this.opened);

    this.overlay.toggleOverlay(this.params, this.paramsPortal, FloatingSearchOptionsComponent, () => {
      this.setOpened(false);
      this.control.reset();
    });


  }

  get control(): FormControl {
    return this.controlContainer.control.get(this.formControlName) as FormControl;
  }

  clearInput(): void {
    this.control.setValue('');
  }

  openOverlay() {
    if (this.opened) return;
    this.setFocused();
    this.toggleOverlay();
  }

  onChange: any = () => { };
  onTouched: any = () => {
    this.control.markAsTouched();
  }

  registerOnTouched(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnTouched(fn);
  }

  registerOnChange(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnChange(fn);
  }

  writeValue(obj: any): void {
    this.formControlDirective.valueAccessor.writeValue(obj);
  }

  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
  }
}
