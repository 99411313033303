import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {StripeService} from 'ngx-stripe';
import {PaymentIntentCustom} from 'top-car-interfaces';
import {TopCarApiService} from 'top-car-api';
import {Modal} from 'top-car-ngxs';
import {Store} from '@ngxs/store';

@Component({
  selector: 'app-pay-for-car-report-modal',
  templateUrl: './pay-for-car-report-modal.component.html',
  styleUrls: ['./pay-for-car-report-modal.component.scss']
})
export class PayForCarReportModalComponent implements OnInit, OnDestroy {
  interval: any;
  loading = false;
  public prices: any = {
    'Topup': null,
    'Silver car report': 3.99,
    'Gold car report': 12.99
  };

  readonly stripeInstance = this.stripeService.stripe;
  public paymentIntent!: PaymentIntentCustom;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public router: Router,
    private api: TopCarApiService,
    private stripeService: StripeService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.api.buyCarReport({
      amount: this.prices[(this.data.type == 1 ? 'Silver' : 'Gold') + ' car report'] as number,
      advertId: this.data.id,
      type: this.data.type
    }).subscribe(response => {
      this.paymentIntent = response;
    }, error => {
      this.store.dispatch(new Modal.OpenWarning('Our service provider returned not valid response. Unfortunately, you cannot buy a report'));
    })
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  viewReport(): void {
    this.interval = setInterval(() => {
      this.api.getCar(this.data.id).subscribe(e => {
        const report = e.carReports.find(reportItem => reportItem.type === this.data.type);

        if (report) {
          this.router.navigate(['/report', 'car', report.id]);
          this.dialogRef.close(true);
          clearInterval(this.interval);
        }

      });
    }, 5000 );
  }
}
