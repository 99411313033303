<mat-card class="select-options-list u-padding-0 mat-elevation-z0" (outsideClick)="close()" [exclude]="params.label" [ref]="{nativeElement: optionsScrollContainer}" [enableStopScroller]="hovered" appStopScrollPropogation (mouseenter)="hovered = true" (mouseleave)="hovered = false">
  <mat-card-content>
    <div class="select-options-list__scroll" [class.select-options-list__scroll--model-variant]="selectOptionsType.MODEL_VARIANT_GROUP === params.type || selectOptionsType.MODEL_VARIANT === params.type" #optionsScrollContainer>
      <button
        class="u-width-100 select-options-list__reset u-flex u-flex-align-center"
        *ngIf="params.resetText"
        [class.select-options-list__reset--model-variant]="selectOptionsType.MODEL_VARIANT_GROUP === params.type || selectOptionsType.MODEL_VARIANT === params.type"
        [class.select-options-list__reset--standard]="selectOptionsType.STANDARD === params.type"
        (click)="reset()">
        <div class="select-options-list__reset_mat-icon-container">
          <mat-icon [inline]="true">close</mat-icon>
        </div>
        <div>
          <span>{{params.resetText}}</span>
        </div>
      </button>

      <ng-container
        *ngTemplateOutlet="
          selectOptionsType.STANDARD === params.type ? optionsTemplate :
          selectOptionsType.MODEL_VARIANT_GROUP === params.type ? modelVariantGroupTemplate :
          selectOptionsType.MODEL_VARIANT === params.type ? modelVariantTemplate :
          groupTemplate; context: {options: params.options}
        "
      ></ng-container>

    </div>

  </mat-card-content>
</mat-card>

<ng-template #modelVariantGroupTemplate>
  <div *ngFor="let group of params.groups; trackBy: trackBy; let last = last" class="select-options-list__group">
    <span class="select-options-list__group_title u-block">{{group.name}}</span>

    <ng-container *ngTemplateOutlet="modelVariantTemplate; context: {options: group.options}"></ng-container>

    <hr *ngIf="!last">

  </div>
</ng-template>

<ng-template #groupTemplate>
  <div *ngFor="let group of params.groups | filterGroupSelectByChildren:(searchValue$ | async); trackBy: trackBy; let last = last" class="select-options-list__group">
    <span class="select-options-list__group_title u-block">{{group.name}}</span>

    <ng-container *ngTemplateOutlet="optionsTemplate; context: {options: group.options}"></ng-container>

    <hr *ngIf="!last">

  </div>
</ng-template>

<ng-template #optionsTemplate let-options="options">
  <ng-container *ngFor="let option of options | filterByName:(searchValue$ | async):true; trackBy: trackBy">
    <div class="select-options-list__item">
      <button (click)="select(option)">
        <mat-icon [inline]="true" *ngIf="isChecked(option)">check</mat-icon>
        <img *ngIf="option.imagePath" [src]="option.imagePath | url:domain">
        <span class="select-options-list__name">
          {{option.name}}
        </span>
        <span *ngIf="option.totalAdverts" class="u-margin-left-auto">
          {{option.totalAdverts}}
        </span>
        <button appStopPropagation *ngIf="option?.hasChildren" (click)="toggleSubmodel(option)" class="u-margin-left-auto select-options-list__submodel-button">
          <span class="u-width-100">{{opennedChildren.includes(option.id) ? '-' : '+'}}</span>
        </button>
      </button>
    </div>

    <ng-container *ngTemplateOutlet="opennedChildren.includes(option.id) && subModelsTemplate; context: {options: option.subModels, parent: option}"></ng-container>

  </ng-container>

</ng-template>

<ng-template #subModelsTemplate let-options="options" let-parent="parent">
  <div *ngFor="let option of options | filterByName:(searchValue$ | async):true; trackBy: trackBy" class="select-options-list__item select-options-list__item--submodel">
    <button (click)="select(option)">
      <mat-icon [inline]="true" *ngIf="isChecked(option)">check</mat-icon>
      <span class="select-options-list__name">
        {{option.name}}
      </span>
    </button>
  </div>
</ng-template>

<ng-template #modelVariantTemplate let-options="options">
  <div class="u-flex u-flex-align-center u-flex-wrap">
    <div *ngFor="let option of options; trackBy: trackBy" class="select-options-list__item--model-variant" [style.background-image]="'url(' + (option.imagePath | url:domain) + ')'">
      <button (click)="select(option)">
        <app-checkbox-alternative *ngIf="params.multiple" color="primary" [ngModel]="isChecked(option)" (ngModelChange)="select(option)"></app-checkbox-alternative>
        <mat-icon [inline]="true" *ngIf="!params.multiple && selectedItems.includes(option.value)">check</mat-icon>
        <div class="u-flex u-flex-column">
          <span class="u-text-bold">
          {{option.startYear}} - {{option.endYear || 'Present'}}
        </span>
          <span *ngIf="option.name">{{option.name}}</span>
        </div>
      </button>
    </div>
  </div>
</ng-template>
