import {ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {ControlContainer, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() formControlName?: string;
  @Input() ngModel?: string;

  @Output() ngModelChange = new EventEmitter();

  readonly url = environment.API;

  constructor(private controlContainer?: ControlContainer) { }

  get control(): FormControl {
    return this.controlContainer && this.controlContainer.control.get(this.formControlName) as FormControl;
  }

  selectionChanged(event: any): void {
    // this.selectionChange.emit(new MatSelectChange(this.matSelect, event.value));
    // this.valueChange.emit(event.value);
    this.onChange(event.value);
    this.onTouched();
  }

  // ControlValueAccessor Implementation
  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    // this.value = value;
  }

  setDisabledState(isDisabled: boolean): void {
    // this.disabled = isDisabled;
  }
}
