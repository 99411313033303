<div class="u-text-right">
  <button mat-icon-button class="modal__close" (click)="dialogRef.close()"><mat-icon>close</mat-icon></button>
</div>
<mat-dialog-content>

  <div class="receipt">

    <h2 class="name"> TopCar </h2>

    <p class="greeting"> {{data.object === 'payment_intent' ? 'Payout received' : 'Refund sent'}} ! </p>

    <div class="order">

      <p> {{data.object === 'payment_intent' ? 'Order' : 'Refund'}} No : {{data.id}} </p>
      <p> Date : {{data.created * 1000 | date:'medium'}} </p>

    </div>

    <hr>

    <div class="details">

      <h3> Details </h3>

      <div class="product" *ngIf="data.object === 'payment_intent'">

        <div class="info">

          <p> <a [routerLink]="'/car/' + data.metadata.AdvertId">Car #{{data.metadata.AdvertId}}</a> </p>

        </div>

      </div>

      <p> {{data.amount / 100 | price}} </p>

      <p *ngIf="data.object === 'refund'"> Refund reason: {{data.reason}} </p>

    </div>

    <div class="totalprice" *ngIf="data.object === 'payment_intent'">

      <p class="sub"> Charged <span> {{data.amount / 100 | price}} </span></p>

      <p class="del"> Fee <span> {{data.application_fee_amount / 100 | price}} </span> </p>

      <p class="del"> Stripe fee <span> {{utils.calculateStripeFee(data) | price}} </span> </p>

      <hr>

      <p class="tot"> Received <span> {{ utils.calculateTotalReceivedAmount(data) | price }} </span> </p>

    </div>

    <footer *ngIf="data.object === 'payment_intent'"> <a class="link-unstyled" [href]="data.charges.data[0].receipt_url" target="_blank">View receipt for client.</a> </footer>

  </div>

</mat-dialog-content>
